import React, { forwardRef, useEffect, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import icons from '../../img/Icons';

export const SearchTypeahead = forwardRef(
    ({ searchQuery, setSearchQuery, resultsPage, setTypeaheadError, typeaheadError }, ref) => {
        const [typeaheadOptions, setTypeaheadOptions] = useState([]);
        const [prevQueries, setPrevQueries] = useState([]);

        useEffect(() => {
            (async () => {
                const terms = await app.user.getSearchTerms();
                setPrevQueries(terms);
            })();
        }, []);

        const handleInputChange = (e) => {
            setTypeaheadError(false);
            const value = e.target.value;
            setSearchQuery(value);
            const filteredOptions = prevQueries.filter((option) =>
                option.toLowerCase().startsWith(value.toLowerCase()),
            );
            setTypeaheadOptions(filteredOptions);
        };

        const handleOptionClick = (option) => {
            setSearchQuery(option);
            setTypeaheadOptions([]);
        };

        const selectWithKeys = (evt) => {
            if (
                searchQuery &&
                typeaheadOptions.length > 0 &&
                (evt.key === 'ArrowUp' || evt.key === 'ArrowDown')
            ) {
                const currentIndex = typeaheadOptions.indexOf(searchQuery);
                let newIndex;
                if (evt.key === 'ArrowUp') {
                    newIndex = currentIndex > 0 ? currentIndex - 1 : typeaheadOptions.length - 1;
                } else {
                    newIndex = currentIndex < typeaheadOptions.length - 1 ? currentIndex + 1 : 0;
                }
                setSearchQuery(typeaheadOptions[newIndex]);
            }
            if (
                evt.key === 'Enter' &&
                document.activeElement === evt.target &&
                typeaheadOptions.length > 0
            ) {
                evt.preventDefault();
                handleOptionClick(searchQuery);
                setTypeaheadOptions([]);
            }
        };

        const handleInputBlur = () => {
            setTypeaheadOptions([]);
        };

        return (
            <InputGroup className={`modal-search ${resultsPage ? 'results-page' : ''}`}>
                <Form.Control
                    type="text"
                    placeholder="Enter words or phrases"
                    value={searchQuery ? searchQuery : ''}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    onKeyDown={(evt) => {
                        selectWithKeys(evt);
                    }}
                    aria-label="Search query input"
                    name="searchQueryInput"
                    autoComplete="off"
                    ref={ref}
                    className="typeahead"
                    isInvalid={typeaheadError}
                    data-dd-action-name="Search Query Input"
                />
                <Form.Control.Feedback type="invalid">
                    <span className="above">{icons['above']()}</span>
                    Enter words or phrases you want to search for
                </Form.Control.Feedback>
                <span className="search-icon">{icons['search']()}</span>
                {typeaheadOptions.length > 0 && searchQuery.length > 0 && (
                    <ul>
                        {typeaheadOptions.map((option) => (
                            <li
                                key={option}
                                onMouseDown={() => handleOptionClick(option)}
                                className={option === searchQuery ? 'selected' : ''}
                            >
                                {option}
                            </li>
                        ))}
                    </ul>
                )}
            </InputGroup>
        );
    },
);

SearchTypeahead.propTypes = {
    searchQuery: PropTypes.string,
    setSearchQuery: PropTypes.func,
    resultsPage: PropTypes.bool,
    setTypeaheadError: PropTypes.func,
    typeaheadError: PropTypes.bool,
};
