import React from 'react';
import icons from '../../../img/Icons';
import { getFilterValue } from '../utils';
import { filter_types } from '../AdditionalFilters/SearchFilterTypes';
import PropTypes from 'prop-types';

export const FiltersModalPanel = ({ queryParams }) => {
    return (
        <div className="edit-saved-search-filters">
            <>
                <span className="filter-icon">{icons['filter']()}</span>
                {filter_types.map(({ type, label }) => {
                    const filterValue = getFilterValue(type, queryParams);
                    if (!filterValue) return null;
                    if (type === 'state' && !app.user.has_perm('content.view_all_document_states'))
                        return null;
                    return (
                        <div key={label} className="filter-item">
                            <div className="filter-item-value">{filterValue}</div>
                        </div>
                    );
                })}
            </>
        </div>
    );
};

FiltersModalPanel.propTypes = {
    queryParams: PropTypes.object.isRequired,
};
