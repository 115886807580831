import React, { useContext } from 'react';
import Table from 'react-bootstrap/Table';
import PropTypes from 'prop-types';

import TableRow from './TableRow';
import MoveableItem from '../FormattedRows/MoveableItem';
import { WithLoadingOverlay } from '../WithLoading';
import PaginationControl from './PaginationControl';
import Header, { headersPropTypes } from './Header';
import { useFetch } from '../../customHooks';
import { TableContext, TABLE_ACTION } from './TableContext';
import { SORT_OPTIONS } from '../../Favourites/FavouriteManage';
import ExpandableItem from '../FormattedRows/ExpandableItem';

const TableControl = ({
    headers,
    getTableColumns,
    doFetch,
    className,
    model,
    additionalButtons,
    doUpdate,
}) => {
    const { tableState, dispatchTableState, options } = useContext(TableContext);

    const setFetchedState = (result) => dispatchTableState({ type: TABLE_ACTION.FETCHED, result });

    const idAttribute = options.idPropertyName ?? 'id';

    const extendedFetch = async () => {
        let data = await doFetch();

        if (Array.isArray(data) && data.length > 0) {
            if (options.pageable) {
                console.error(
                    'Incorrectly formatted data - use Pager class with pagable table components',
                );
            }

            //Data gets formatted differently depending on whether it uses pager or not.
            //Make format consistent
            data = {
                results: data,
                count: data.length,
            };
        }

        if (typeof model == 'undefined' || model.length === 0) return data;

        // Assign models to fetched components
        const newResults = data.results.map((item) => {
            item.model = model.models.find(
                (model) => model.attributes[idAttribute] === item[idAttribute],
            );
            return item;
        });

        return { ...data, results: newResults };
    };

    const { data, loading } = useFetch({
        doFetch: extendedFetch,
        dirtyData: tableState.dirtyData,
        setFetchedState,
    });

    const dataList = options.orderable ? tableState?.orderedList : data?.results;
    let rows =
        dataList?.map((item) => {
            if (options.expandable) {
                return { id: item[idAttribute], ...getTableColumns(item) };
            }
            return {
                id: item[idAttribute],
                cols: getTableColumns(item),
            };
        }) ?? [];

    const tableClass = `admin-table data-table${options.pageable ? ' with-paging' : ''} ${
        className ?? ''
    }`;

    const hasFilters = Object.values(tableState.filter ?? {}).some((value) => value);

    const populateTable = () => {
        if (options.orderable) {
            return (
                <tbody>
                    {rows.map((row, index) => {
                        // const ordinal_index = rows.reverse().indexOf(row);
                        return (
                            <MoveableItem
                                key={row.id}
                                id={row.id}
                                row={row}
                                draggable={
                                    tableState.sortColumn == SORT_OPTIONS.ORDER ||
                                    tableState.sortColumn == ''
                                        ? true
                                        : false
                                }
                                {...{ moveItemHandler, index, doUpdate }}
                            />
                        );
                    })}
                </tbody>
            );
        }

        if (options.expandable) {
            return (
                <tbody>
                    {rows.map((row) => {
                        return <ExpandableItem key={row.id} row={row} />;
                    })}
                </tbody>
            );
        }

        return (
            <tbody>
                {rows.map((row) => (
                    <TableRow key={row.id} row={row} />
                ))}
            </tbody>
        );
    };

    // Drag and Drop region

    const moveItemHandler = (dragIndex, hoverIndex) => {
        const dragItem = dataList[dragIndex];
        if (dragItem) {
            const copiedList = [...dataList];

            if (dragIndex > hoverIndex) {
                // Moving dragItem up to new location
                copiedList.splice(hoverIndex, 0, dragItem);
                // Removing dragItem from old location, and shuffling everything else
                copiedList.splice(dragIndex + 1, 1);
            } else {
                // Moving dragItem down to new location
                copiedList.splice(hoverIndex + 1, 0, dragItem);
                // Removing dragItem from old location, and shuffling everything else
                copiedList.splice(dragIndex, 1);
            }

            // Set the new list, but as this changes while we're hovering/moving, don't refresh or send to db
            dispatchTableState({ type: TABLE_ACTION.ORDER, orderedList: copiedList });
        }
    };

    // regionend

    return (
        <div className={tableClass}>
            <WithLoadingOverlay loading={loading || tableState.loading === true}>
                <Table responsive>
                    <Header headers={headers} />
                    {rows.length === 0 && (
                        <tbody>
                            <tr>
                                <td className="no-results-msg" colSpan={headers.length + 1}>
                                    {hasFilters
                                        ? 'No matches found with selected Filters. Try clearing the filters to see results.'
                                        : '- No matches found -'}
                                </td>
                            </tr>
                        </tbody>
                    )}
                    {populateTable()}
                </Table>
            </WithLoadingOverlay>
            <div>
                {additionalButtons}
                {!loading && <PaginationControl />}
            </div>
        </div>
    );
};

export default TableControl;

TableControl.propTypes = {
    headers: PropTypes.arrayOf(PropTypes.shape(headersPropTypes)),
    getTableColumns: PropTypes.func.isRequired,
    doFetch: PropTypes.func.isRequired,
    className: PropTypes.string,
    model: PropTypes.shape({
        length: PropTypes.number,
        models: PropTypes.array.isRequired,
        attributes: PropTypes.object,
    }),
    additionalButtons: PropTypes.element,
    doUpdate: PropTypes.func,
};
