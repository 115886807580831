import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { PropTypes } from 'prop-types';
import icons from '../../img/Icons';
import { StepWrapper } from './StepWrapper';

const ModalBody = () => {
    return (
        <Modal.Body className="search-help-body">
            <p>
                Search has been redesigned to make it more intuitive — but don&apos;t worry,
                everything you could do before is still possible. We&apos;ve also added some
                exciting new features!
            </p>
            <StepWrapper>
                <p>Search is a simple, two-step process:</p>
                <p>
                    <span className="help-title-text">Step 1:</span> Enter the words or phrases
                    which you want to search for
                </p>

                <p>
                    <span className="help-title-text">Step 2:</span> Select the document(s) you want
                    to search across
                </p>
            </StepWrapper>
            <h3>What&apos;s new?</h3>
            <a
                href="https://vimeo.com/manage/videos/1056434960/ddaaf364a2"
                target="_blank"
                rel="noopener noreferrer"
            >
                Watch Search demo video
            </a>
            <StepWrapper title="Document Selection">
                <p>
                    You can search in the document you have open by clicking{' '}
                    <span className="help-title-text">Add current document</span>. You can then
                    choose to run your search or add other documents by using the following options:
                    <br />
                    <br />
                    Use <span className="help-title-text">Open Document</span> to either select from
                    your Recently Opened Documents or start typing the name of a document and select
                    from a drop-down list.
                    <br />
                    <br />
                    Use the <span className="help-title-text">Table of Documents</span> to select
                    whole collections eg Acts or click on the name of the collection to select
                    individual documents from that collection. To navigate to different collections
                    in the Table of Documents use the arrows at the top left of this box.
                    <br />
                    <br />
                    The list of documents/collections you&apos;ve selected will be listed in the
                    “Step 2” box. To remove a document from this list, simply click X next to the
                    document title.
                </p>
            </StepWrapper>
            <StepWrapper title="Saving a Search">
                <p>
                    Once you&apos;ve run a search, you can save it to re-run at a later date. Saved
                    search information includes the search terms and documents as well as any other
                    search parameters, and records the date on which the search was last run and the
                    number of documents found.
                    <br />
                    <br />
                    You can access your list of Saved Searches from the button in the search window.
                </p>
            </StepWrapper>
            <StepWrapper title="Tips for better results">
                <p>
                    Use the radio buttons under Step 2 (Search Extent) as well as the Time frame
                    options to better control your search results.
                </p>
                <p>
                    Once you&apos;ve run a search, you can change your search parameters by
                    selecting Amend Search.
                </p>
                <p>Too many results?</p>
                <ul>
                    <li>
                        Consider adding more search terms in Step 1 and then selecting All terms.
                    </li>
                    <li>
                        Use the Time frame options to restrict results to a specific date range.
                    </li>
                    <li>
                        Refine your search results. After your initial search, you can perform
                        another search which will be limited solely to those documents found in the
                        initial search.
                    </li>
                </ul>
                <p>Not enough results?</p>
                <ul>
                    <li>
                        In Step 1, add more search terms, or if you are already searching for
                        multiple terms, select “Any of the terms”.
                    </li>
                    <li>
                        If you&apos;ve restricted the time frame, consider switching back to “All
                        time”.
                    </li>
                </ul>
            </StepWrapper>
        </Modal.Body>
    );
};

export const HelpModal = ({ showHelpModal, setShowHelpModal }) => {
    return (
        <Modal
            show={showHelpModal}
            onHide={() => setShowHelpModal(false)}
            className="search-help-modal"
        >
            <Modal.Header closeButton>
                <div className="help-header-title">
                    <span>{icons['help']()}</span>
                    <Modal.Title>Search help</Modal.Title>
                </div>
            </Modal.Header>
            <ModalBody />
        </Modal>
    );
};

HelpModal.propTypes = {
    showHelpModal: PropTypes.bool,
    setShowHelpModal: PropTypes.func,
};
