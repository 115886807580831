import PropTypes from 'prop-types';

export const todFilterPropTypes = {
    title: PropTypes.string,
    doc_filter: PropTypes.string,
    index_level: PropTypes.string,
    ordinal: PropTypes.number,
    pk: PropTypes.number,
};

export const editableContentPropType = {
    title: PropTypes.string,
    filters: PropTypes.arrayOf(PropTypes.shape(todFilterPropTypes)),
    content: PropTypes.string,
    image: PropTypes.node,
    path: PropTypes.string,
    display_filter_options: PropTypes.bool,
};

export const todDocument = {
    display: PropTypes.string,
    doc_filter: PropTypes.string,
    level1_value: PropTypes.string,
    level2_value: PropTypes.string,
    level3_value: PropTypes.string,
};

export const parseDocInfo = (htmlString) => {
    const parser = new DOMParser();
    const domDoc = parser.parseFromString(htmlString, 'text/html');
    const anchor = domDoc.querySelector('a');
    const abbrevClass = domDoc.querySelector('.abbrev');
    const prefixClass = domDoc.querySelector('.prefix');
    const docTitle = anchor ? anchor.textContent : '';
    const abbrev = abbrevClass ? abbrevClass.textContent : '';
    const prefix = prefixClass ? prefixClass.textContent : '';
    return { docTitle, abbrev, prefix };
};

export const formatDoc = (doc, type) => {
    const { docTitle, abbrev, prefix } = parseDocInfo(doc.display);
    const modifiedAbbrev = abbrev.replace(/^\[|\]$/g, '');
    const document = {
        value: doc.doc_id ? doc.doc_id : docTitle,
        doc_abbrv: [modifiedAbbrev],
        doc_id: type === 'collection' ? '' : doc.doc_id,
        label: prefix ? `${prefix}: ${docTitle}` : docTitle,
        doc_title: prefix ? `${prefix}: ${docTitle}` : docTitle,
        [type]: true,
    };
    return document;
};

export const addDocsToList = (evt, documents, setDocs) => {
    if (evt.target && evt.target.checked) {
        setDocs((currSelectedDocs) => {
            const newDocs = documents.filter(
                (doc) =>
                    !currSelectedDocs.some((selectedDoc) => {
                        if (selectedDoc.doc_id === '') {
                            return (
                                selectedDoc.value === doc.value &&
                                selectedDoc.doc_filter === doc.doc_filter
                            );
                        } else {
                            return selectedDoc.doc_id === doc.doc_id;
                        }
                    }),
            );
            return [...currSelectedDocs, ...newDocs];
        });
    } else {
        setDocs((currSelectedDocs) =>
            currSelectedDocs.filter(
                (currDoc) =>
                    !documents.some((doc) => {
                        if (doc.doc_id === '') {
                            return currDoc.value === doc.value;
                        } else {
                            return currDoc.doc_id === doc.doc_id;
                        }
                    }),
            ),
        );
    }
};

export const linkCheckboxDocSelector = (selectedDocs, checkedItem, setCheckedItem) => {
    if (selectedDocs) {
        const updatedCheckedItem = { ...checkedItem };
        Object.keys(updatedCheckedItem).forEach((key) => {
            if (!selectedDocs.some((doc) => `${doc.value} ${doc.page}` === key)) {
                delete updatedCheckedItem[key];
            }
        });
        setCheckedItem(updatedCheckedItem);
    } else {
        setCheckedItem({});
    }
};
