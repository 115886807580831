import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TableRow from '../Table/TableRow';

const ExpandableItem = ({ row }) => {
    const [showPanel, setShowPanel] = useState(false);

    const handlePanel = () => {
        setShowPanel(!showPanel);
    };

    return (
        <>
            <TableRow
                className={`expandable-row ${showPanel ? 'panel-open' : ''}`}
                row={{ ...row, handlePanel, showPanel }}
            />
            {showPanel && <tr className="expandable-panel">{row.panel}</tr>}
        </>
    );
};

export default ExpandableItem;

ExpandableItem.propTypes = {
    row: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        cols: PropTypes.arrayOf(PropTypes.node).isRequired,
        panel: PropTypes.node.isRequired,
    }),
};
