import React from 'react';

export const SavedSearchButton = () => {
    const openSavedSearch = () => {
        window.open('/savedsearches', '_blank', 'width=1000,height=400');
    };

    return (
        <button
            type="button"
            className="open-saved-search"
            onClick={openSavedSearch}
            aria-label="Open Saved Searched in New Window"
        >
            Saved Searches
        </button>
    );
};
