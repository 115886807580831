import React, { useState } from 'react';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import ConditionalWrapper from '../ConditionalWrapper';
import PiconIcon from '../FormControls/Icons/PiconIcon';
import SearchModal from './SearchModal';

const SearchButton = () => {
    const [showModal, setModal] = useState(false);

    const popoverSearch = (
        <Popover id="popover-search" className="popover fade bottom in">
            <Popover.Title className="popover-title">Search</Popover.Title>
            <Popover.Content className="popover-content">
                Search the full text of your current document or across a selection of documents.
            </Popover.Content>
        </Popover>
    );

    const displayToolTip = app.user.get('display_prefs').contextual_help;

    return (
        <ConditionalWrapper
            condition={displayToolTip && !showModal}
            wrapper={(children) => (
                <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="bottom"
                    overlay={popoverSearch}
                    rootClose={true}
                >
                    {children}
                </OverlayTrigger>
            )}
        >
            <div>
                <button
                    className="toolbar-item btn"
                    onClick={() => setModal(true)}
                    title="Full text search of current document or across all documents"
                    role="menuitem"
                >
                    <span className="icon">
                        <PiconIcon className={`lg left top`} iconName="search" />
                    </span>
                    <span className="label">Search</span>
                </button>
                {showModal && (
                    <SearchModal handleModal={() => setModal(false)} showModal={showModal} />
                )}
            </div>
        </ConditionalWrapper>
    );
};

export default SearchButton;
