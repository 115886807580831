import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import DocSearchItem from './DocSearchItem';
import DocResultsHeader from './DocResultsHeader';
import LoadingSpinner from '../LoadingSpinner';
import { formatFamilyTitle } from '../utils';

const DocSearchContainer = ({
    query,
    permission,
    triggerComparison,
    handleModal,
    searching,
    abbrevSearch,
    titleSearch,
    families,
}) => {
    const [selectedRow, updateSelectedRow] = useState(0);
    const createDocUrl = (doc_id) => {
        let docUrl = '';
        if (doc_id.includes('timeline')) {
            docUrl = '/timeline';
        } else {
            docUrl = `${app.urls.documents}/${doc_id}`;
        }
        return docUrl;
    };
    const abbrevResults = abbrevSearch.documents;

    const titleResults = titleSearch.documents;
    // Still need to join abbrev and title searches because of key-press scrolling
    const totalResults = abbrevResults.concat(titleResults);

    const handlePress = async (event) => {
        const { key } = event;
        const totalRows = totalResults.length;
        if (key === 'ArrowUp' && selectedRow > 0) {
            updateSelectedRow(selectedRow - 1);
        } else if (key === 'ArrowDown' && selectedRow < totalRows - 1) {
            updateSelectedRow(selectedRow + 1);
        } else if (key === 'Enter') {
            // Check if we're comparing two documents, and therefore need to send the one we want back to the Compare View
            var notComparing = await triggerComparison(
                totalResults[selectedRow].doc_id,
                totalResults[selectedRow].doc_instance,
            );
            if (notComparing) {
                // Or we just want to see this one document
                const docId = totalResults[selectedRow].doc_id;
                const docUrl = createDocUrl(docId);
                handleModal();
                event.ctrlKey ? window.open(docUrl) : (window.location.href = docUrl);
            }
        }
    };

    const handleClick = async (event, doc_id, doc_instance) => {
        event.persist();
        const notComparing = await triggerComparison(doc_id, doc_instance);
        if (notComparing) {
            const docUrl = createDocUrl(doc_id);
            handleModal();
            event.ctrlKey ? window.open(docUrl) : (window.location.href = docUrl);
        }
    };

    const handleAuxClick = async (doc_id, doc_instance) => {
        const notComparing = await triggerComparison(doc_id, doc_instance);
        if (notComparing) {
            const docUrl = createDocUrl(doc_id);
            handleModal();
            window.open(docUrl);
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handlePress);

        return () => {
            window.removeEventListener('keydown', handlePress);
        };
    });

    if (searching) {
        return (
            <div className="retrieving-msg">
                <LoadingSpinner />
                <i>Retrieving all matching documents...</i>
            </div>
        );
    } else if (abbrevSearch.documents.length === 0 && titleSearch.documents.length === 0) {
        return (
            <div>
                <i>No documents found</i>
            </div>
        );
    } else {
        return (
            <table className="doc-search-container">
                <DocResultsHeader {...{ permission }} />
                <tbody>
                    {abbrevResults &&
                        abbrevResults.map((result, index) => {
                            const formattedResult = formatFamilyTitle(result, families);
                            return (
                                <DocSearchItem
                                    key={index}
                                    {...{ index, query, permission, handleClick, handleAuxClick }}
                                    doc={formattedResult}
                                    highlight={
                                        abbrevResults && selectedRow === index ? true : false
                                    }
                                />
                            );
                        })}
                    {abbrevResults.length > 0 && titleResults.length > 0 && (
                        <tr className="doc-search-item table-divider">
                            <td colSpan="102%"></td>
                        </tr>
                    )}
                    {titleResults &&
                        titleResults.map((result, index) => {
                            const formattedResult = formatFamilyTitle(result, families);
                            return (
                                <DocSearchItem
                                    key={index}
                                    {...{ query, permission, handleClick, handleAuxClick }}
                                    doc={formattedResult}
                                    index={index + abbrevResults.length}
                                    highlight={
                                        titleResults && selectedRow === index + abbrevResults.length
                                            ? true
                                            : false
                                    }
                                />
                            );
                        })}
                </tbody>
            </table>
        );
    }
};

export default DocSearchContainer;

DocSearchContainer.propTypes = {
    query: PropTypes.string,
    permission: PropTypes.bool,
    triggerComparison: PropTypes.func,
    handleModal: PropTypes.func,
    families: PropTypes.arrayOf(PropTypes.object),
    abbrevSearch: PropTypes.shape({
        documents: PropTypes.arrayOf(PropTypes.object),
    }),
    titleSearch: PropTypes.shape({
        documents: PropTypes.arrayOf(PropTypes.object),
    }),
    searching: PropTypes.bool,
};
