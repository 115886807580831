import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import SaveThisSearchButton from '../SavedSearch/SaveThisSearchManager';
import SearchParamsDisplay from './SearchParamsDisplay';

import SearchModal from '../SearchModal';

import icons from '../../../img/Icons';
import { RefineSearch } from './RefineSearch';
import { ActionPanelWrapper } from './ActionPanelWrapper';

const SearchDetailsBar = ({ queryParams, previousCount, collectionData }) => {
    const [showModal, setShowModal] = useState({});
    const [detailsHeight, setDetailsHeight] = useState(137);
    const searchDetailsRef = useRef(null);

    useEffect(() => {
        const searchDetails = searchDetailsRef.current;
        if (!searchDetails) return;

        const resizeObserver = new ResizeObserver((entries) => {
            if (entries[0]) {
                setDetailsHeight(entries[0].contentRect.height);
            }
        });

        resizeObserver.observe(searchDetails);

        return () => {
            resizeObserver.unobserve(searchDetails);
        };
    }, []);

    useEffect(() => {
        // Set height of the Results table based off the height of the Results detail panel
        if (detailsHeight) {
            document.querySelector('.search-results').style.top = `${detailsHeight + 7}px`;
        }
    }, [detailsHeight]);

    const handleRefine = () => {
        if (showModal.refineSearch) {
            setShowModal({ refineSearch: false });
        } else {
            setShowModal({ refineSearch: true });
        }
    };

    return (
        <div className="previous-search" ref={searchDetailsRef}>
            <SearchParamsDisplay {...{ queryParams, disabled: true }} />
            <div className="search-action">
                <ActionPanelWrapper title={'Search options:'}>
                    <button
                        className={`search-btn amend ${
                            queryParams.tab === 'within' ? 'disabled' : ''
                        }`}
                        onClick={() =>
                            setShowModal({
                                amendSearch: true,
                                firstSearch: app._documentResultsView?.queryParams,
                            })
                        }
                        title={
                            queryParams.tab === 'within'
                                ? 'Unable to amend Refined search'
                                : 'Reopen Search where you can amend the search criteria.'
                        }
                        disabled={queryParams.tab === 'within' ? true : false}
                    >
                        Amend search
                    </button>
                    <SaveThisSearchButton resultsCount={previousCount} queryParams={queryParams} />
                    <button
                        className={`search-btn refine ${
                            queryParams.doc_id || collectionData.numFound === 0 ? 'disabled' : ''
                        } ${showModal.refineSearch ? 'expanded' : ''}`}
                        onClick={() => handleRefine()}
                        title={
                            queryParams.doc_id
                                ? 'Unable to refine search of a single document'
                                : 'Apply new criteria to search only within the documents listed below'
                        }
                        disabled={
                            queryParams.doc_id || collectionData.numFound === 0 ? true : false
                        }
                    >
                        Refine search
                        <span className="refine-icon">{icons['dropdown']()}</span>
                    </button>
                </ActionPanelWrapper>
                <ActionPanelWrapper>
                    <button
                        className="search-btn"
                        onClick={() => setShowModal({ newSearch: true })}
                        title="Open new Search without any criteria already added."
                    >
                        New search
                    </button>
                </ActionPanelWrapper>
            </div>
            {showModal.refineSearch && (
                <RefineSearch
                    handleRefine={handleRefine}
                    queryParams={queryParams}
                    collectionData={collectionData}
                />
            )}
            {showModal.amendSearch && (
                <SearchModal
                    handleModal={() => setShowModal({ ...showModal, amendSearch: false })}
                    showModal={showModal.amendSearch}
                    queryParams={showModal.firstSearch ? showModal.firstSearch : queryParams}
                />
            )}
            {showModal.newSearch && (
                <SearchModal
                    handleModal={() => setShowModal({ ...showModal, newSearch: false })}
                    showModal={showModal.newSearch}
                />
            )}
        </div>
    );
};

export default SearchDetailsBar;

SearchDetailsBar.propTypes = {
    queryParams: PropTypes.object.isRequired,
    previousCount: PropTypes.number.isRequired,
    hasEmptyResult: PropTypes.bool,
    collectionData: PropTypes.object,
};
