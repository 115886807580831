// router for Perspective app

define(['backbone'], function (Backbone) {
    var Router = Backbone.Router.extend({
        initialize: function (app, controller) {
            this.app = app;
            this.controller = controller;
            this.current = null;
        },
        routes: {
            '': 'index',
            'login(/)': 'login',
            'activate/:uidb64/:token(/)': 'activate',
            'documents/compare(/)': 'documentCompare',
            'documents/compare/:documentID(/)(:instanceID)': 'documentCompare',
            'documents/search(/)': 'search',
            'documents/print/:documentID(/)(:instanceID)(/)': 'documentPrint',
            'documents/:documentID(/)(:instanceID)(/)': 'document',
            'notes/:documentID(/)(:instanceID)(/)(:sectionID)(/)': 'notes',
            'annotations(/)(:documentID)(/)': 'annotations',
            'news(/)(:documentID)(/)(:instanceID)(/)': 'news',
            'surveys(/)(:documentID)(/)(:instanceID)(/)': 'surveys',
            'tod(/)*path': 'tod',
            'rod(/)': 'rod',
            'new-and-recently-amended-documents(/)': 'newAndRecent',
            'inter/:documentID(/)(:instanceID)(/)': 'document',
            'administration(/)': 'administration',
            'administration/users(/)(:organisation)(/)(:users_status)(/)(:users_size)':
                'administrationUsers',
            'administration/multiuserupload(/)': 'administrationMultiUserUpload',
            'administration/documents(/)': 'administrationDocuments',
            'administration/document_upload/:uploadID(/)': 'administrationDocumentUpload',
            'administration/documents/:documentID(/)': 'administrationDocument',
            'administration/upload(/)': 'administrationUpload',
            'administration/user/(:userID)(/)': 'administrationUserDetails',
            'administration/download(/)': 'administrationDownload',
            'organisation/(:organisationID)(/)': 'organisation',
            'organisation/user/(:organisationID)(/)(:userID)(/)': 'organisationUserDetails',
            'settings(/)': 'settings',
            'preferences(/)': 'preferences',
            'favourites(/)': 'favourites',
            'timeline(/)': 'timeline',
            'timeline/:documentID(/)': 'timeline',
            'savedsearches(/)': 'savedSearches',

            // legacy
            'docs/:documentID(/)(:instanceID)(/)': 'document',
        },
        index: function () {
            this.current = 'index';
            this.controller.index();
        },
        login: function () {
            this.current = 'login';
            this.controller.login();
        },
        activate: function () {
            this.current = 'activate';
            this.controller.activate();
        },
        documentCompare: function (documentID, instanceID) {
            this.current = 'documentCompare';
            this.controller.documentCompare(documentID, instanceID);
        },
        search: function () {
            this.current = 'search';
            var urlParams = new URLSearchParams(window.location.search);
            var queryParams = _.fromPairs([...urlParams]);
            queryParams['fam'] = urlParams.getAll('fam');
            this.controller.search(queryParams);
        },
        documentPrint: function (documentID, instanceID) {
            this.current = 'documentPrint';
            this.controller.documentPrint(documentID, instanceID);
        },
        document: function (documentID, instanceID) {
            this.current = 'document';
            this.controller.document(documentID, instanceID);
        },
        notes: function (documentID, instanceID, sectionID) {
            this.current = 'notes';
            this.controller.notes(documentID, instanceID, sectionID);
        },
        annotations: function (documentID) {
            var urlParams = new URLSearchParams(window.location.search);
            const families = urlParams.getAll('families');
            // If Annotations is opened outside a doc view, there won't be families
            const familiesList = families.length ? families[0].split(',') : families;
            this.current = 'annotations';
            this.controller.annos(documentID, familiesList);
        },
        favourites: function () {
            this.current = 'favourites';
            this.controller.faves();
        },
        news: function (documentID, instanceID) {
            this.current = 'news';
            this.controller.news(documentID, instanceID);
        },
        surveys: function (documentID, instanceID) {
            this.current = 'surveys';
            this.controller.surveys(documentID, instanceID);
        },
        tod: function (path) {
            this.current = 'tod';
            this.controller.tod(path);
        },
        newAndRecent: function () {
            this.current = 'newAndRecent';
            this.controller.newAndRecent();
        },
        rod: function () {
            this.current = 'rod';
            this.controller.rod();
        },
        settings: function () {
            this.current = 'settings';
            this.controller.settings();
        },
        email_alerts: function () {
            this.current = 'email_alerts';
            this.controller.emailAlerts();
        },
        preferences: function () {
            this.current = 'preferences';
            this.controller.preferences();
        },
        administration: function () {
            this.current = 'administration';
            this.controller.addUser();
        },
        administrationUsers: function (organisation, users_status, users_size) {
            this.current = 'administrationUsers';
            this.controller.administrationUsers(organisation, users_status, users_size);
        },
        administrationMultiUserUpload: function () {
            this.current = 'administrationMultiUserUpload';
            this.controller.multiUserUpload();
        },
        administrationDocuments: function () {
            this.current = 'administrationDocuments';
            this.controller.administrationDocuments();
        },
        administrationDocumentUpload: function (uploadID) {
            this.current = 'administrationDocumentUpload';
            this.controller.administrationDocumentUpload(uploadID);
        },
        administrationDocument: function (documentID) {
            this.current = 'administrationDocument';
            this.controller.administrationDocument(documentID);
        },
        administrationUpload: function () {
            this.current = 'administrationUpload';
            this.controller.documentUpload();
        },
        administrationDownload: function () {
            this.current = 'administrationDownload';
            this.controller.fileDownload();
        },
        organisation: function (organisationID) {
            this.current = 'organisation';
            this.controller.Organisation(organisationID || app.user.get('organisation'));
        },
        administrationUserDetails: function (userID) {
            this.current = 'administrationUserDetails';
            this.controller.administrationUserDetails(userID);
        },
        organisationUserDetails: function (organisationID, userID) {
            this.current = 'administrationUserDetails';
            this.controller.administrationUserDetails(userID);
        },
        timeline: function () {
            this.current = 'timeline';
            this.controller.timeline();
        },
        savedSearches: function () {
            this.current = 'savedSearches';
            this.controller.savedSearches();
        },
    });

    return Router;
});
