import React from 'react';
import PropTypes from 'prop-types';
import icons from '../../img/Icons';

export const AIButton = ({ text }) => {
    const handleClick = async () => {
        try {
            const response = await fetch(app.urls.ai, {
                method: 'GET',
            });
            const json = await response.json();

            if (response.ok && json.ai_site_url && json.token) {
                // Create a form dynamically
                const form = document.createElement('form');
                form.action = json.ai_site_url; // AI site base URL
                form.method = 'POST';
                form.target = '_blank'; // Open in a new tab

                // Create a hidden input for the token
                const tokenInput = document.createElement('input');
                tokenInput.type = 'hidden';
                tokenInput.name = 'token';
                tokenInput.value = json.token;

                // Append the input to the form
                form.appendChild(tokenInput);

                // Append the form to the body and submit
                document.body.appendChild(form);
                form.submit();

                // Remove the form from the DOM after submission
                document.body.removeChild(form);
            } else {
                toaster.error(json.error || 'Failed to navigate to Perspective Ai');
            }
        } catch (error) {
            toaster.error(`There was an error navigating to Perspective Ai: ${error}`);
        }
    };

    return (
        <button className="ai-button" onClick={handleClick}>
            <span className="ai-white">{icons['ai']()}</span>
            <span>{text}</span>
        </button>
    );
};
AIButton.propTypes = {
    text: PropTypes.string.isRequired,
};
