import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { SEARCH_SCOPE, SEARCH_SCOPE_TITLES } from './SearchFilterTypes';
import { handleRadioChange } from '../utils';

export const Scope = ({ additionalFilters, setAdditionalFilters }) => {
    const [selectedRadio, setSelectedRadio] = useState('exnotes');
    return (
        <div className="filter-group">
            <label className="filter-label">Search Extent:</label>
            <Form.Group className="mode-group">
                {Object.entries(SEARCH_SCOPE).map(([scope, value]) => {
                    return (
                        <Form.Check
                            type="radio"
                            key={scope}
                            className={selectedRadio === scope ? 'selected-radio' : ''}
                            data-dd-action-name="Select Search Extent"
                        >
                            <Form.Check.Input
                                type="radio"
                                isValid
                                name="scope-group"
                                value={scope}
                                onChange={(evt) => {
                                    handleRadioChange(evt, 'scope', setAdditionalFilters);
                                    setSelectedRadio(scope);
                                }}
                                checked={additionalFilters.scope === scope}
                                id={`scope-${scope}`}
                            />
                            <Form.Check.Label
                                title={SEARCH_SCOPE_TITLES[scope]}
                                htmlFor={`scope-${scope}`}
                                id={scope === 'exnotes' ? 'exnotes-label' : ''}
                            >
                                {value}
                            </Form.Check.Label>
                        </Form.Check>
                    );
                })}
            </Form.Group>
        </div>
    );
};

Scope.propTypes = {
    additionalFilters: PropTypes.object.isRequired,
    setAdditionalFilters: PropTypes.func.isRequired,
};
