import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import Form from 'react-bootstrap/Form';

import models from '../../../models';
import FormModal, { MODAL_SIZE } from '../../FormControls/FormModal';
import SearchParamsDisplay from '../SearchResults/SearchParamsDisplay';
import { StepWrapper } from '../StepWrapper';
import { formatResultsFromUrl } from '../utils';

const AddSavedSearchModal = ({
    showModal,
    handleModal,
    resultsCount,
    queryParams,
    formattedItems,
    savedTitle,
    searchedURL,
    setDirty,
    itemId,
}) => {
    const [title, setTitle] = useState('');
    const [error, setError] = useState(null);
    const [searchedItems, setSearchedItems] = useState([]);

    const savedSearchModel = new models.SavedSearch();
    const location = window.location.origin;

    const checkInputValidity = async () => {
        let isValid = true;

        const duplicate = await savedSearchModel.fetchSavedSearchByTitle({ title });

        if (duplicate.length) {
            setError('Saved Search title must be unique');
            isValid = false;
        }
        return isValid;
    };

    const handleSubmit = async (form) => {
        const originalSearchURL = app.prevSearch && location + app.urls.search + app.prevSearch;
        const searchURL = originalSearchURL || searchedURL || window.location.href;
        const searchTitle = form.elements.searchTitle.value;

        const data = {
            title: searchTitle,
            search_url: searchURL,
            owner: app.user.attributes.id,
            document_result_count: resultsCount,
        };

        try {
            if (setDirty && itemId) {
                await savedSearchModel.update(itemId, data);
                setDirty();
            } else {
                await savedSearchModel.save(data);
                const savedURl = new URL(`${window.location.href}&saved=${encodeURI(searchTitle)}`);
                window.history.pushState({}, '', savedURl);
                queryParams.saved = searchTitle;
                app.trigger('title:saved', searchTitle);
            }
            app.savedSearch = searchTitle;
            toaster.success('Search saved successfully');
        } catch (err) {
            console.error(err);
            toaster.error('Search could not be saved');
        }
    };

    useEffect(() => {
        (async () => {
            await formatResultsFromUrl(queryParams, setSearchedItems, formattedItems);
        })();
    }, [queryParams]);

    return (
        <FormModal
            show={showModal}
            hideModal={handleModal}
            title="Edit Saved Search"
            submitForm={handleSubmit}
            size={MODAL_SIZE.LARGE}
            checkInputValidity={checkInputValidity}
            backdrop="static"
            cancelText="Close"
            saveText="Save"
            className="add-saved-search-modal"
            draggable={true}
            isMain={true}
        >
            <StepWrapper title={'Saved Search Name'}>
                <Form.Group className="save-search-title">
                    <Form.Control
                        id="searchTitle"
                        type="text"
                        name="title"
                        aria-label="search title"
                        placeholder="Enter a title for this search"
                        defaultValue={savedTitle}
                        required={true}
                        onChange={(evt) => {
                            setError(null);
                            setTitle(evt.target.value);
                        }}
                        isInvalid={error}
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                        {error ? error : 'Please provide a title'}
                    </Form.Control.Feedback>
                </Form.Group>
            </StepWrapper>
            <StepWrapper title={'Search criteria to save:'}>
                <span className="search-count">Items Searched: {searchedItems.length}</span>
                <div className="save-search-params-container">
                    <SearchParamsDisplay
                        {...{
                            queryParams,
                            disabled: true,
                            formattedItems,
                            savedSearch: true,
                        }}
                    />
                </div>
            </StepWrapper>
        </FormModal>
    );
};

export default AddSavedSearchModal;

AddSavedSearchModal.propTypes = {
    handleModal: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
    resultsCount: PropTypes.number.isRequired,
    queryParams: PropTypes.object.isRequired,
    formattedItems: PropTypes.array,
    savedTitle: PropTypes.string,
    searchedURL: PropTypes.string,
    setDirty: PropTypes.func,
    itemId: PropTypes.number,
};
