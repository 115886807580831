import React from 'react';
import icons from '../../img/Icons';
import { AIButton } from './AIButton';

export const AIBanner = () => {
    if (app.user.attributes.ai_access) {
        return (
            <>
                <span className="ai-blue">{icons['ai']()}</span>
                <section>
                    <header>Your expert pensions assistant is just one click away...</header>
                    <p>
                        Empower your expertise with Perspective Ai — Get precise, reliable answers
                        to complex pensions law queries through a deep understanding of nuance and
                        detail
                    </p>
                </section>
                <AIButton text={'Explore Perspective AI'} />
            </>
        );
    }
    return null;
};
