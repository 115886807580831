import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { SearchTypeahead } from '../SearchTypeahead';
import { Button, Form } from 'react-bootstrap';
import { formatAdditionalFilters } from '../utils';
import { StepWrapper } from '../StepWrapper';
import { Mode } from '../AdditionalFilters/Mode';
import { Scope } from '../AdditionalFilters/Scope';
import { Dates } from '../AdditionalFilters/Dates';
import { ActionPanelWrapper } from './ActionPanelWrapper';
import icons from '../../../img/Icons';

export const RefineSearch = ({ handleRefine, queryParams, collectionData }) => {
    const [refineSearchQuery, setRefineSearchQuery] = useState('');
    const [showOptions, setShowOptions] = useState(false);
    const [additionalFilters, setAdditionalFilters] = useState();
    const [dateErrors, setDateErrors] = useState({
        range: false,
        period: false,
    });
    const [typeaheadError, setTypeaheadError] = useState(false);

    useEffect(() => {
        if (queryParams) {
            setAdditionalFilters((currFilters) => ({
                ...currFilters,
                mode: queryParams.mode,
                scope: queryParams.scope,
                state: queryParams.state,
            }));
        }
    }, [queryParams]);

    const submitRefineSearch = (evt) => {
        evt.preventDefault();
        const filtersUnchanged = Object.keys(additionalFilters).every(
            (key) => additionalFilters[key] === queryParams[key],
        );

        if (filtersUnchanged && refineSearchQuery.length < 1) {
            setTypeaheadError(true);
            return;
        }
        if (refineSearchQuery.length < 1) {
            if (additionalFilters['date'] !== 'range' && additionalFilters['date'] !== 'period') {
                setTypeaheadError(true);
                return false;
            }
        }
        if (
            additionalFilters['date'] === 'range' &&
            !additionalFilters['date_from'] &&
            !additionalFilters['date_to']
        ) {
            setDateErrors((currErrors) => ({
                ...currErrors,
                range: true,
            }));
            return false;
        } else if (
            additionalFilters['date'] === 'period' &&
            !additionalFilters['date_last_value']
        ) {
            setDateErrors((currErrors) => ({
                ...currErrors,
                period: true,
            }));
            return false;
        } else if (
            additionalFilters['date'] === 'range' &&
            (!additionalFilters.date_from ||
                additionalFilters.date_from >= additionalFilters.date_to)
        ) {
            setDateErrors((currErrors) => ({
                ...currErrors,
                range: true,
                futureRange: true,
            }));
            return false;
        }
        let numQueries = 0;

        const previousCount = collectionData.numFound;
        const previousQuery = queryParams.q;
        const previousFilter = collectionData.last_fq;
        const previousField = collectionData.last_qf;

        const refineData = { ...queryParams };
        refineData['search'] = 'document';
        refineData['tab'] = 'within';
        refineData['q'] = refineSearchQuery;
        refineData['count'] = collectionData.numFound;
        delete refineData['saved'];

        const formattedFilters = formatAdditionalFilters(additionalFilters);
        for (const key in formattedFilters) {
            refineData[key] = formattedFilters[key];
        }

        if (queryParams.pq) {
            refineData['pq'] = Array.isArray(queryParams.pq) ? queryParams.pq : [queryParams.pq];
            refineData['count'] = Array.isArray(queryParams.count)
                ? queryParams.count
                : [queryParams.count];
            refineData['pfq'] = Array.isArray(queryParams.pfq)
                ? queryParams.pfq
                : [queryParams.pfq];
            refineData['pqf'] = Array.isArray(queryParams.pqf)
                ? queryParams.pqf
                : [queryParams.pqf];
            numQueries = refineData['pq'].length;
        } else {
            refineData['pq'] = [];
            refineData['count'] = [];
            refineData['pfq'] = [];
            refineData['pqf'] = [];
        }
        // add back the last query details - need to check what the prev query count and filter are
        refineData['pq'].push(numQueries + '~' + '"' + previousQuery + '"');
        refineData['count'].push(numQueries + '~' + previousCount);
        refineData['pqf'].push(numQueries + '~' + previousField);
        refineData['pfq'] = refineData['pfq'].concat(
            previousFilter?.map((item) => numQueries + '~' + item),
        );
        refineData.q = refineSearchQuery;
        for (const key in refineData) {
            if (Array.isArray(refineData[key]) && refineData[key].length === 0) {
                delete refineData[key];
            }
        }
        window.location.href = '/documents/search?' + $.param(refineData, true);
    };

    return (
        <Form className="refine-search" onSubmit={submitRefineSearch}>
            <StepWrapper title="Enter additional search term(s)">
                {typeaheadError && (
                    <div className="error">
                        Please either enter an additional search term or adjust further search
                        options
                    </div>
                )}
                <div className="search-within-text">
                    <SearchTypeahead
                        searchQuery={refineSearchQuery}
                        setSearchQuery={setRefineSearchQuery}
                        resultsPage={true}
                        typeaheadError={typeaheadError}
                        setTypeaheadError={setTypeaheadError}
                    />
                    <Button type="submit" className="refine-search-btn">
                        Search
                    </Button>
                    <Button
                        variant="secondary btn-default"
                        className="close-refine-btn"
                        onClick={handleRefine}
                    >
                        Close
                    </Button>
                </div>
                <ActionPanelWrapper className="refine-options">
                    <button
                        className={`search-btn ${showOptions ? 'expanded' : ''}`}
                        onClick={(evt) => {
                            evt.preventDefault();
                            setShowOptions(!showOptions);
                            setTypeaheadError(false);
                        }}
                    >
                        Further Search Options
                        <span className="refine-icon">{icons['dropdown']()}</span>
                    </button>
                </ActionPanelWrapper>
                {showOptions && (
                    <div className="refine-filters">
                        <Mode
                            additionalFilters={additionalFilters}
                            setAdditionalFilters={setAdditionalFilters}
                        />
                        <Scope
                            additionalFilters={additionalFilters}
                            setAdditionalFilters={setAdditionalFilters}
                        />
                        <Dates
                            additionalFilters={additionalFilters}
                            setAdditionalFilters={setAdditionalFilters}
                            dateErrors={dateErrors}
                            setDateErrors={setDateErrors}
                            queryParams={queryParams}
                            refine={true}
                        />
                        {dateErrors.range && (
                            <div className="date-error">{`Please enter a valid date range ${
                                dateErrors.futureRange ? '- Start date must be before End Date' : ''
                            }`}</div>
                        )}
                        {dateErrors.period && (
                            <div className="date-error">Please enter a valid date period</div>
                        )}
                    </div>
                )}
            </StepWrapper>
        </Form>
    );
};

RefineSearch.propTypes = {
    handleRefine: PropTypes.func,
    queryParams: PropTypes.object,
    collectionData: PropTypes.object,
};
