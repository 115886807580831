import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import { Form } from 'react-bootstrap';
import { customStyles } from '../FormControls/Select/Select';
import MultiSelect from '../FormControls/Select/MultiSelect';
import { highlightSearch } from '../utils';
import { SearchROD } from './SearchROD';

const parentsLabel = (props) => {
    const formattedParents = props.data.parents.map((parent) => {
        return parent.charAt(0).toUpperCase() + parent.slice(1);
    });
    return formattedParents.join(' / ');
};

const setPlaceholder = (isSearchModal) => {
    if (isSearchModal) {
        return 'Type at least two characters to search';
    } else {
        return '';
    }
};

const multiValue = (props) => {
    const title = props.data.doc_title ? props.data.doc_title : props.data.value;
    if (props.data.doc_abbrv && props.data.doc_abbrv[0] && props.data.doc_abbrv[0].length !== 0) {
        const abbrev = ' [' + props.data.doc_abbrv[0] + ']';
        return title + abbrev;
    } else {
        return title;
    }
};

const MultiValueOption = (props) => {
    if (props.selectProps.isSearchModal) {
        return null;
    }
    const chipLabel = props.data.subset
        ? `${props.data.label} / ${parentsLabel(props)}`
        : multiValue(props);

    return (
        <div className="multi-select-container">
            <components.MultiValue
                {...props}
                className={props.data.collection ? 'collection' : 'document'}
            >
                <div className="selected-item">
                    <span className="search-option" title={chipLabel}>
                        {chipLabel}
                    </span>
                </div>
            </components.MultiValue>
        </div>
    );
};

const Option = (props) => {
    const isChecked = (props) => {
        const checkSelectedDocs = props.selectProps.selectedDocs?.some(
            (doc) => doc.doc_id === props.data.doc_id,
        );
        const checkTodDocs = props.selectProps.todDocuments?.some(
            (doc) => doc.doc_id === props.data.doc_id,
        );
        const checkRodDocs = props.selectProps.rodDocuments?.some(
            (doc) => doc.doc_id === props.data.doc_id,
        );

        return checkSelectedDocs || checkTodDocs || checkRodDocs || false;
    };
    if (props.selectProps.isSearchModal) {
        return (
            <components.Option
                {...props}
                className={
                    props.isFocused ? 'select-option-container-focus' : 'select-option-container'
                }
            >
                <Form.Check
                    type="checkbox"
                    name="select-options"
                    className="select-option"
                    data-dd-action-name="Select document via search select"
                >
                    <Form.Check.Input
                        type="checkbox"
                        name="select-options"
                        checked={isChecked(props)}
                        onChange={() => null}
                        className="select-checkbox"
                        id={props.data.value}
                    />
                    <Form.Check.Label
                        className="select-option-label"
                        htmlFor={props.data.value}
                        onClick={(evt) => evt.preventDefault()}
                    >
                        {highlightSearch(
                            props.data.label,
                            'matched-query',
                            props.selectProps.inputValue,
                        )}
                        <div className="select-option-family" htmlFor={`${props.data.value}-input`}>
                            {`In ${props.data.doc_primary_family_set_title}`}
                            {app.user.has_perm('content.view_all_document_states') && (
                                <span className="select-option-state">
                                    {props.data.doc_state.toUpperCase()}
                                </span>
                            )}
                        </div>
                    </Form.Check.Label>
                </Form.Check>
            </components.Option>
        );
    } else {
        return (
            <div>
                <components.Option {...props}>
                    <span>{props.data.label}</span>
                </components.Option>
            </div>
        );
    }
};

const MenuList = (props) => {
    return (
        <components.MenuList {...props}>
            {(!props.selectProps.isSearchModal || props.options.length > 0) && props.children}
            {props.selectProps.isSearchModal &&
                props.options.length === 0 &&
                !props.selectProps.inputValue && (
                    <SearchROD
                        selectedDocs={props.selectProps.selectedDocs}
                        setSelectedDocs={props.selectProps.setSelectedDocs}
                        todDocuments={props.selectProps.todDocuments}
                        allRODSelected={props.selectProps.allRODSelected}
                        setAllRODSelected={props.selectProps.setAllRODSelected}
                        rodDocuments={props.selectProps.rodDocuments}
                        setRodDocuments={props.selectProps.setRodDocuments}
                        allSelected={props.selectProps.allSelected}
                    />
                )}
            {props.options.length === 0 && props.selectProps.inputValue && props.children}
        </components.MenuList>
    );
};

const CustomInput = (props) => {
    return (
        <>
            <components.Input
                {...props}
                placeholder={
                    props.selectProps.inputValue
                        ? ''
                        : setPlaceholder(props.selectProps.isSearchModal)
                }
                data-dd-action-name="Open documents search select input"
            />
            {props.selectProps.inputValue && (
                <div
                    className="clear-doc-search"
                    onClick={() => props.selectProps.setSearchText('')}
                >
                    ✕
                </div>
            )}
        </>
    );
};

const SearchSelect = ({
    disabled,
    setSearchText,
    searchText,
    isSearchModal,
    selectedDocs,
    docSelectError,
    todDocuments,
    rodDocuments,
    menuIsOpen,
    allRODSelected,
    allSelected,
    setRodDocuments,
    setSelectedDocs,
    ...props
}) => {
    const searchStyles = {
        ...customStyles(),
        container: (base) => ({
            ...base,
            width: '-webkit-fill-available',
            paddingTop: '3px',
            paddingBottom: '4px',
        }),
        menu: (base) => ({
            ...base,
            marginTop: '2px',
            zIndex: 5,
        }),
        menuList: (base) => ({
            ...base,
            minHeight: '289px',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '0 0 6px 6px',
            maxHeight: '290px',
            '@media only screen and (max-height: 900px)': {
                maxHeight: '200px',
                minHeight: '218px',
            },
            backgroundColor: 'var(--modal-bg)',
        }),
        control: (base) => ({
            ...base,
            ...(disabled && {
                pointerEvents: 'none',
                border: '1px solid #ced4da',
                borderRadius: '5px',
                backgroundColor: 'white',
                '&:hover': {
                    border: '1px solid #ced4da',
                    borderRadius: '5px',
                },
            }),
            ...(!disabled && {
                maxHeight: '100px',
                overflowY: 'auto',
            }),
        }),
        valueContainer: (base) => ({
            ...base,
            ...(disabled && {
                pointerEvents: 'auto',
                overflowY: 'auto',
                maxHeight: '100px',
                backgroundColor: 'white',
                borderRadius: '5px',
            }),
        }),
        multiValue: (base) => ({
            ...base,
            borderRadius: '12px',
            fontWeight: 'bold',
            lineHeight: '20px',
            alignItems: 'center',
        }),
        multiValueLabel: (base) => ({
            ...base,
            position: 'relative',
            zIndex: 1,
            borderRadius: '12px',
            padding: '2px 4px 2px 6px',
            fontSize: '12px',
        }),
        multiValueRemove: (base, state) => ({
            ...base,
            display: state.isDisabled ? 'none' : 'flex',
        }),
        input: (base) => ({
            ...base,
        }),
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            const formElements = Array.from(
                document.querySelectorAll('input, button, select, textarea'),
            );
            const currentIndex = formElements.indexOf(event.target);
            const nextElement = formElements[currentIndex + 1];
            if (nextElement) {
                nextElement.focus();
            }
        }
    };
    return (
        <MultiSelect
            components={{
                MultiValue: MultiValueOption,
                MenuList,
                Option,
                DropdownIndicator: null,
                ClearIndicator: null,
                Input: CustomInput,
            }}
            {...props}
            isMulti={true}
            isSearchable={true}
            removeSelected={disabled}
            hideSelectedOptions={false}
            noOptionsMessage={() => 'No results found'}
            closeMenuOnSelect={false}
            blurInputOnSelect={false}
            placeholder={''}
            styles={searchStyles}
            disabled={disabled}
            className={docSelectError ? 'select-error' : 'search-select'}
            inputValue={searchText}
            onInputChange={(inputValue, { action }) => {
                if (action === 'input-change') {
                    setSearchText(inputValue);
                }
            }}
            isSearchModal={isSearchModal}
            selectedDocs={selectedDocs}
            todDocuments={todDocuments}
            rodDocuments={rodDocuments}
            menuIsOpen={menuIsOpen}
            allRODSelected={allRODSelected}
            allSelected={allSelected}
            setRodDocuments={setRodDocuments}
            setSelectedDocs={setSelectedDocs}
            setSearchText={setSearchText}
            autoFocus={true}
            onKeyDown={handleKeyDown}
        />
    );
};

export default SearchSelect;

MultiValueOption.propTypes = {
    data: PropTypes.shape({
        document: PropTypes.bool,
        label: PropTypes.string,
        doc_primary_family_set_title: PropTypes.string,
        collection: PropTypes.bool,
        subset: PropTypes.bool,
        parents: PropTypes.array,
        doc_abbrv: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        doc_title: PropTypes.string,
        value: PropTypes.string,
    }),
    selectProps: PropTypes.shape({
        isSearchModal: PropTypes.bool,
    }),
};

CustomInput.propTypes = {
    selectProps: PropTypes.shape({
        inputValue: PropTypes.string,
        placeholder: PropTypes.string,
        isSearchModal: PropTypes.bool,
        setSearchText: PropTypes.func,
    }),
};

Option.propTypes = {
    data: PropTypes.shape({
        document: PropTypes.bool,
        label: PropTypes.string,
        doc_primary_family_set_title: PropTypes.string,
        value: PropTypes.string,
        doc_state: PropTypes.string,
        doc_abbrv: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        doc_title: PropTypes.string,
        doc_id: PropTypes.string,
    }),
    selectProps: PropTypes.shape({
        isSearchModal: PropTypes.bool,
        selectedDocs: PropTypes.array,
        inputValue: PropTypes.string,
        todDocuments: PropTypes.array,
        rodDocuments: PropTypes.array,
    }),
    isFocused: PropTypes.bool,
};

MenuList.propTypes = {
    children: PropTypes.node,
    options: PropTypes.array,
    selectProps: PropTypes.shape({
        isSearchModal: PropTypes.bool,
        allRODSelected: PropTypes.bool,
        allSelected: PropTypes.bool,
        setRodDocuments: PropTypes.func,
        setSelectedDocs: PropTypes.func,
        selectedDocs: PropTypes.array,
        todDocuments: PropTypes.array,
        rodDocuments: PropTypes.array,
        setAllRODSelected: PropTypes.func,
        inputValue: PropTypes.string,
    }),
};

SearchSelect.propTypes = {
    disabled: PropTypes.bool,
    setSearchText: PropTypes.func,
    searchText: PropTypes.string,
    isSearchModal: PropTypes.bool,
    selectedDocs: PropTypes.array,
    docSelectError: PropTypes.bool,
    todDocuments: PropTypes.array,
    rodDocuments: PropTypes.array,
    menuIsOpen: PropTypes.bool,
    allRODSelected: PropTypes.bool,
    allSelected: PropTypes.bool,
    setRodDocuments: PropTypes.func,
    setSelectedDocs: PropTypes.func,
};
