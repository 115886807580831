import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import EditButton from '../ContentManagement/EditButton';
import PiconIcon from '../FormControls/Icons/PiconIcon';
import { formatHtmlId, handleSearchScroll } from '../utils';
import { todDocument } from './utils';

const MAX_CONTENT_SIZE = {};

const IndexItem = ({
    title,
    items,
    activeItem,
    indexDepth,
    setActiveItem,
    nested,
    setShowEditor,
    displayAsBtnList,
    collapseInnerFilters,
    setHash,
    parentTitle,
    docFilter,
    searchModal,
}) => {
    const [collapse, setCollapse] = useState(true);
    const [loading, setLoading] = useState(true);
    const [gridStyles, setGridStyles] = useState(undefined);

    const elRefs = useRef([]);

    useEffect(() => {
        setCollapse(collapseInnerFilters);
    }, [collapseInnerFilters]);

    const titleId = parentTitle ? `${parentTitle}-${title}` : title;

    const displayAsBtn =
        Array.isArray(displayAsBtnList) &&
        (displayAsBtnList.includes(title.trim()) || displayAsBtnList.includes(docFilter));

    useEffect(() => {
        if (indexDepth <= 1 && elRefs.current.length === Object.keys(items).length) {
            let biggestMinContent = 0;
            elRefs.current.forEach((el) => {
                if (el) {
                    const minContentValue = el.getBoundingClientRect().width;
                    const minContentNumber = parseFloat(minContentValue);
                    if (!(titleId in MAX_CONTENT_SIZE)) {
                        // set what Max size can be based off what it started out as
                        MAX_CONTENT_SIZE[titleId] = minContentNumber;
                    }
                    if (
                        minContentNumber > biggestMinContent &&
                        minContentNumber <= MAX_CONTENT_SIZE[titleId]
                    ) {
                        biggestMinContent = minContentNumber;
                    } else if (
                        minContentNumber > biggestMinContent &&
                        minContentNumber >= MAX_CONTENT_SIZE[titleId]
                    ) {
                        // don't go over the max content size if window is resizing larger
                        biggestMinContent = MAX_CONTENT_SIZE[titleId];
                    }
                }
            });

            const newGridColumns = `repeat(auto-fit, minmax(${biggestMinContent}px, 1fr))`;
            // only reset gridStyles if the column size has changed
            if (newGridColumns != gridStyles?.gridTemplateColumns) {
                setGridStyles({
                    display: 'grid',
                    gridTemplateColumns: newGridColumns,
                    gridGap: '5px',
                });
            }
        }
        setLoading(false);
    }, []);

    return (
        <div
            id={title ? `index-panel-${formatHtmlId(titleId)}` : 'index-panel'}
            className={`tod-index-item-container ${nested ? 'nested' : ''}`}
        >
            <div className={`tod-title ${nested ? 'nested' : ''}`}>
                {title && (
                    <>
                        <button
                            aria-label="collapse"
                            className="icon-container"
                            onClick={(evt) => {
                                evt.preventDefault();
                                setCollapse(!collapse);
                            }}
                        >
                            <PiconIcon
                                className={`item-title-icon sm ${collapse ? 'collapsed' : ''}`}
                                iconName="next"
                            />
                        </button>
                        <button
                            type="button"
                            onClick={() => {
                                if (collapse) setCollapse(!collapse);
                                const itemId = `tod-section-header-${formatHtmlId(titleId)}`;
                                if (searchModal) {
                                    handleSearchScroll(itemId, 'tod-page-list');
                                } else {
                                    setHash(`#${itemId}`);
                                }
                            }}
                            className="index-item-text"
                        >
                            {title}
                        </button>
                    </>
                )}
                {app.user.has_perm('cms_lite.edit_tod_filter') && !nested && !searchModal && (
                    <EditButton
                        title={`Edit ${title} Filter`}
                        handleEditor={() => setShowEditor()}
                    />
                )}
            </div>
            <div
                style={displayAsBtn ? gridStyles : undefined}
                className={`${displayAsBtn ? '' : 'list'} ${
                    nested ? 'nested' : ''
                } tod-index-item-list`}
            >
                {!collapse &&
                    (indexDepth > 1
                        ? Object.entries(items).map(([itemKey, valuesObject]) => {
                              const key = itemKey.replaceAll('meta::', '');
                              return (
                                  <div
                                      className={!title ? 'not-nested-group' : 'nested-group'}
                                      key={`tod-index-${key}`}
                                  >
                                      <IndexItem
                                          {...{
                                              title: key,
                                              items: valuesObject,
                                              indexDepth: indexDepth - 1,
                                              activeItem,
                                              setActiveItem,
                                              nested: true,
                                              collapseInnerFilters: true,
                                              setHash,
                                              parentTitle: title,
                                              displayAsBtnList,
                                              docFilter,
                                              searchModal,
                                          }}
                                      />
                                  </div>
                              );
                          })
                        : Object.keys(items).map((itemKey, index) => {
                              const item = itemKey.replaceAll('meta::', '');
                              const itemId = title ? `${title}-${item}` : item;
                              return (
                                  <button
                                      type="button"
                                      ref={(ref) => {
                                          !gridStyles && ref && displayAsBtn
                                              ? elRefs.current.push(ref)
                                              : undefined;
                                      }}
                                      id={itemId}
                                      className={`${displayAsBtn ? 'btn' : ''} tod-item-btn ${
                                          loading ? 'tod-index-item-calculate' : ''
                                      } ${
                                          itemId === activeItem.id && activeItem.active
                                              ? 'active'
                                              : ''
                                      }`}
                                      key={index}
                                      onClick={() => {
                                          setActiveItem({ id: itemId, active: true });
                                          const elementId = `tod-section-header-${formatHtmlId(
                                              itemId,
                                          )}`;
                                          if (searchModal) {
                                              handleSearchScroll(elementId, 'tod-page-list');
                                          } else {
                                              setHash(`#${elementId}`);
                                          }
                                      }}
                                  >
                                      {item}
                                  </button>
                              );
                          }))}
            </div>
        </div>
    );
};

export default IndexItem;

IndexItem.propTypes = {
    title: PropTypes.string,
    items: PropTypes.object,
    activeItem: PropTypes.shape(todDocument),
    setActiveItem: PropTypes.func,
    nested: PropTypes.bool,
    setShowEditor: PropTypes.func,
    indexDepth: PropTypes.number,
    displayAsBtnList: PropTypes.array,
    parentTitle: PropTypes.string,
    docFilter: PropTypes.string,
    collapseInnerFilters: PropTypes.bool,
    setHash: PropTypes.func,
    searchModal: PropTypes.bool,
};
