import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { SEARCH_MODE, SEARCH_MODE_TITLES } from './SearchFilterTypes';
import { handleRadioChange } from '../utils';

export const Mode = ({ additionalFilters, setAdditionalFilters }) => {
    const [selectedRadio, setSelectedRadio] = useState('exact');

    return (
        <div className="filter-group">
            <Form.Group className="mode-group">
                {Object.entries(SEARCH_MODE).map(([mode, value]) => {
                    return (
                        <Form.Check
                            type="radio"
                            key={mode}
                            id={`mode-${mode}`}
                            className={selectedRadio === mode ? 'selected-radio' : ''}
                            data-dd-action-name="Select Search Mode"
                        >
                            <Form.Check.Input
                                type="radio"
                                isValid
                                name="mode-group"
                                onChange={(evt) => {
                                    handleRadioChange(evt, 'mode', setAdditionalFilters);
                                    setSelectedRadio(evt.target.value);
                                }}
                                value={mode}
                                checked={additionalFilters.mode === mode}
                            />
                            <Form.Check.Label
                                htmlFor={`mode-${mode}`}
                                title={SEARCH_MODE_TITLES[mode]}
                            >
                                {value}
                                {mode === 'all' && <span>{` within the same 'section'`}</span>}
                            </Form.Check.Label>
                        </Form.Check>
                    );
                })}
            </Form.Group>
        </div>
    );
};

Mode.propTypes = {
    additionalFilters: PropTypes.object.isRequired,
    setAdditionalFilters: PropTypes.func.isRequired,
};
