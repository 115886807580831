import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import models from '../../../models';

const SearchTitle = ({ title }) => {
    const savedSearchModel = new models.SavedSearch();
    const resultsCount = window.documentSearchResultsPreFill.collectionData.numFound;

    useEffect(() => {
        (async () => {
            try {
                const savedSearch = await savedSearchModel.fetchSavedSearchByTitle({ title });
                if (savedSearch.length > 0) {
                    const itemId = savedSearch[0].pk;
                    await savedSearchModel.update(itemId, { document_result_count: resultsCount });
                }
            } catch (err) {
                console.error(err);
            }
        })();
    }, [title, resultsCount]);

    return (
        <>
            <div className="saved-search-title">Saved Search:</div>
            <div className="title">{title}</div>
        </>
    );
};

export default SearchTitle;

SearchTitle.propTypes = {
    title: PropTypes.string.isRequired,
};
