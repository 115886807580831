import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import parse, { attributesToProps, domToReact } from 'html-react-parser';

import ContentEditor from '../ContentManagement/ContentEditor';
import EditButton from '../ContentManagement/EditButton';
import { WithLoadingOverlay } from '../FormControls/WithLoading';
import Checkbox from '../FormControls/Checkbox';
import icons from '../../img/Icons';
import { SearchToDHeader } from '../Search/SearchToDHeader';

const TOD_LINE_REQUIRED_CONTENT = {
    CONTENT: 'content',
    FAMILIES: 'families',
};
Object.freeze(TOD_LINE_REQUIRED_CONTENT);

const ToDList = ({
    loggedIn,
    todWindow,
    doFetch,
    doPost,
    families,
    fromCompare,
    searchModal,
    setSelectionFromTOD,
    setSelectedDocs,
    selectedDocs,
    allSelected,
    setAllSelected,
    todLineCollections,
    setTodLineCollections,
    setRodDocuments,
    setAllRODSelected,
    setPrevToDPage,
    prevToDPage,
}) => {
    const [loading, setLoading] = useState(false);
    const [lines, setLines] = useState({ primary: [], secondary: [] });
    const [showEditor, setShowEditor] = useState({ show: false, content: {} });
    const [familyTree, setFamilyTree] = useState({});
    const [childFamiliesHidden, setChildFamiliesHidden] = useState([]);

    // This might not be needed now? Will have a think about what's going on between homepage and /tod
    const filterNews = (lines, wantNews) => {
        if (wantNews) {
            return lines.filter((line) => line.families.some((family) => family.slug === 'news'));
        }
        return lines.filter((line) => line.families.some((family) => family.slug !== 'news'));
    };

    const formatFamilies = (lines) => {
        if (!loggedIn) {
            // Filter out any families that shouldn't be viewed on logged out page
            return lines.filter((line) => !line.logged_in_view_only);
        }
        // Filter out any lines that are only for search view if we're not in search view
        const firstFilterd = searchModal ? lines : lines.filter((line) => !line.search_view_only);
        // Filter out any families a user shouldn't see
        const userFamilySlugs = families.map((family) => family.slug);
        const filteredLines = userFamilySlugs.includes('all-documents')
            ? firstFilterd
            : firstFilterd.filter((line) =>
                  line.families.some((family) => userFamilySlugs.includes(family.slug)),
              );
        const groupedLines = _.groupBy(filteredLines, (line) => line.secondary_column);

        return {
            primary: groupedLines[false],
            secondary: groupedLines[true],
        };
    };

    const selectAllFamilies = (evt, lines) => {
        const filteredLines = lines.primary
            .concat(lines.secondary)
            .filter((line) => line.selectable_in_search)
            .flatMap((line) => line.families);

        const uniqueFams = _.uniqBy(filteredLines, 'slug');
        uniqueFams.forEach((family) => {
            handleCheckbox(evt, family);
        });
    };

    const searchModalToDPath = (evt, targetElement) => {
        evt.preventDefault();
        const pageParts = targetElement?.href.split('/');
        const cleanedPath = pageParts[pageParts.length - 1];
        setSelectionFromTOD(cleanedPath);
        setPrevToDPage(cleanedPath);
    };

    const handleNavigation = (evt, line) => {
        let targetElement = evt.target;
        while (targetElement !== evt.currentTarget) {
            if (
                todWindow &&
                targetElement.tagName.toLowerCase() === 'a' &&
                targetElement.hasAttribute('href') &&
                !/\/tod\//.test(targetElement.href)
            ) {
                if (
                    fromCompare &&
                    (line.families.some((family) => family.slug === 'news') ||
                        line.families.some((family) => family.slug === 'srv'))
                ) {
                    targetElement = targetElement.parentElement;
                } else {
                    evt.preventDefault();
                    window.manager.open(targetElement.href);
                    break;
                }
            } else {
                targetElement = targetElement.parentElement;
            }
        }
    };

    const handleCheckbox = (evt, family) => {
        evt.persist();
        if (!evt.target.checked && family?.parent) {
            setSelectedDocs((prevSelectedDocs) => {
                const updatedSelectedDocs = prevSelectedDocs.filter(
                    (doc) => doc.doc_id !== family.parent,
                );
                const remainingTodLineCollections = todLineCollections.filter(
                    (collection) =>
                        collection.doc_id !== family.slug &&
                        !updatedSelectedDocs.some((doc) => doc.doc_id === collection.parent),
                );
                return [...updatedSelectedDocs, ...remainingTodLineCollections];
            });

            setTodLineCollections((prevTodLineCollections) => {
                const updatedTodLineCollections = prevTodLineCollections.filter(
                    (collection) => collection.parent !== family.parent,
                );
                return updatedTodLineCollections;
            });
        }
        const collection = {
            value: family.title,
            doc_abbrv: '',
            doc_id: family.slug,
            label: family.title,
            collection: true,
            doc_title: family.title,
        };
        const selectedFamilies = [];
        const selectedChildren = [];
        if (familyTree[family.slug] && evt.target.checked) {
            selectedFamilies.push(collection);
            familyTree[family.slug].map((childFamily) => {
                const childCollection = {
                    value: childFamily.title,
                    doc_abbrv: '',
                    doc_id: childFamily.slug,
                    label: childFamily.title,
                    collection: true,
                    doc_title: childFamily.title,
                    parent: family.slug,
                };
                selectedChildren.push(childCollection);
                if (selectedDocs.some((doc) => doc.doc_id === childCollection.doc_id)) {
                    setSelectedDocs((prevSelectedDocs) =>
                        prevSelectedDocs.filter((doc) => doc.doc_id !== childCollection.doc_id),
                    );
                }
            });
            setTodLineCollections(_.uniqBy([...todLineCollections, ...selectedChildren], 'doc_id'));
        } else if (familyTree[family.slug] && !evt.target.checked) {
            selectedFamilies.push(collection);
            familyTree[family.slug].map((childFamily) => {
                const childCollection = {
                    value: childFamily.title,
                    doc_abbrv: '',
                    doc_id: childFamily.slug,
                    label: childFamily.title,
                    collection: true,
                    doc_title: childFamily.title,
                    parent: family.slug,
                };
                setTodLineCollections((prevtodLineCollections) =>
                    prevtodLineCollections.filter((doc) => doc.doc_id !== childCollection.doc_id),
                );
            });
        } else {
            selectedFamilies.push(collection);
        }
        const uniqueSelectedFamilies = _.uniqBy(selectedFamilies, 'doc_id');
        selectSearchDocs(evt.target.checked, uniqueSelectedFamilies);
    };

    const getLinkFamily = (href, slug, innerText, families) => {
        const url = new URL(`${window.location.origin}/${href}`);
        const familyPart = url.pathname.split('/').slice(-1)[0];
        const familySlug = families.find((family) => family.slug === slug);
        if (familySlug) return familySlug;
        return families.find(
            (family) =>
                family.title === innerText ||
                href.includes(family.title.replace(/ /g, '_')) ||
                href.includes(family.title.replace(/ /g, '-').replace(':', '').toLowerCase()) ||
                family.title.toLowerCase().includes(familyPart),
        );
    };

    const findChecked = (family) => {
        const selectedChildren =
            todLineCollections &&
            todLineCollections.some((collection) => collection.doc_id === family.slug);
        const selectedDocsCheck =
            selectedDocs && selectedDocs.some((selectedDoc) => selectedDoc.doc_id === family?.slug);
        return selectedDocsCheck || selectedChildren || false;
    };

    const prependCheckboxes = (htmlString, families, searchModal) => {
        return parse(htmlString, {
            replace: (domNode) => {
                if (domNode.name === 'a') {
                    const properties = attributesToProps(domNode);
                    properties.attribs = {
                        ...properties.attribs,
                        className: properties.attribs.fam_slug,
                    };
                    // we need to pass props in as properties because it has its own children which knocks out the children prop
                    return (
                        <AnchorWrapper
                            families={families}
                            properties={{ ...properties }}
                            searchModal={searchModal}
                        >
                            {domToReact(domNode.children)}
                        </AnchorWrapper>
                    );
                }
            },
        });
    };

    const formatFamilyTree = (families) => {
        const groupedFamilies = _.groupBy(families, (family) => family.parent);
        return groupedFamilies;
    };

    const toggleHiddenFamilies = (slug) => {
        setChildFamiliesHidden((prevChildFamiliesHidden) => {
            const updatedChildFamiliesHidden = {
                ...prevChildFamiliesHidden,
            };
            updatedChildFamiliesHidden[slug] = !prevChildFamiliesHidden[slug];
            return updatedChildFamiliesHidden;
        });
    };

    useEffect(() => {
        (async () => {
            setLoading(true);
            const content = await doFetch();
            // Regroup lines that are viewable to logged-in user or
            // remove news and remove list of families that shouldn't be on logged out page
            const formattedLines = loggedIn
                ? formatFamilies(content)
                : { primary: formatFamilies(filterNews(content, loggedIn)) };
            setLines(formattedLines);
            const formattedFamilies = formatFamilyTree(families);
            setFamilyTree(formattedFamilies);
            const childFamiliesHidden = Object.keys(formattedFamilies)
                .filter((key) => key !== 'null')
                .reduce((families, key) => {
                    families[key] = true;
                    return families;
                }, {});
            setChildFamiliesHidden(childFamiliesHidden);
            setLoading(false);
        })();
    }, [loggedIn]);

    const AnchorWrapper = ({ children, families, properties, searchModal }) => {
        const anchorFamilies =
            families.length == 1
                ? families[0]
                : getLinkFamily(
                      properties.attribs.href,
                      properties.attribs.fam_slug,
                      properties.children[0].data,
                      families,
                  );
        const checkboxComponent = (
            <Checkbox
                value={properties.attribs.href}
                checked={findChecked(anchorFamilies)}
                onChange={(evt) => handleCheckbox(evt, anchorFamilies)}
                data-dd-action-name="Select ToD Collection"
            />
        );

        return (
            <>
                {checkboxComponent}
                {searchModal && families[0].parent ? (
                    <span
                        {...properties.attribs}
                        title={`Click to view all docs in ${children}`}
                        data-dd-action-name="Click to view all documents for ToD Collection"
                    >
                        {children}
                    </span>
                ) : (
                    <a
                        {...properties.attribs}
                        title={`Click to view all docs in ${children}`}
                        data-dd-action-name="Click to view all documents for ToD Collection"
                    >
                        {children}
                    </a>
                )}
            </>
        );
    };

    AnchorWrapper.propTypes = {
        children: PropTypes.node.isRequired,
        properties: PropTypes.shape({
            attribs: PropTypes.object,
            children: PropTypes.array,
        }).isRequired,
    };

    const ToDLine = (line, index) => {
        //checks if every family in the line.families array has a parent property which is true in childFamiliesHidden, this hides the todLine.
        if (searchModal && line.families.every((family) => childFamiliesHidden[family.parent])) {
            return null;
        }
        if (!line.selectable_in_search && searchModal) {
            return null;
        }

        return (
            <li className={!searchModal ? 'tod-item' : ''} key={`tod-${index}`}>
                {searchModal && line.selectable_in_search ? (
                    <div
                        className="searchable-tod-line"
                        onClick={(evt) => {
                            // if we're in the ToD window, non-ToD or external URLs need to open a new tab in the parent window
                            let targetElement = evt.target;
                            // Make sure evt has an href
                            if (allSelected) {
                                evt.preventDefault();
                            } else if (targetElement.href) {
                                searchModalToDPath(evt, targetElement);
                            }
                        }}
                    >
                        {searchModal && line.families.some((family) => family.slug in familyTree) && (
                            <button
                                type="button"
                                className={
                                    childFamiliesHidden[line.slug]
                                        ? 'tod-line-expand'
                                        : 'tod-line-hide'
                                }
                                onClick={() => {
                                    toggleHiddenFamilies(line.slug);
                                }}
                            >
                                {icons['dropdown']()}
                            </button>
                        )}
                        {prependCheckboxes(line.line, line.families, searchModal)}
                    </div>
                ) : (
                    <div
                        className={!line.selectable_in_search && searchModal ? 'disabled' : ''}
                        dangerouslySetInnerHTML={{
                            __html: line.line,
                        }}
                        onClick={(evt) => {
                            // Don't navigate if the line is not selectable in search
                            if (searchModal && !line.selectable_in_search) evt.preventDefault();
                            // if we're in the ToD window, non-ToD or external URLs need to open a new tab in the parent window
                            let targetElement = evt.target;
                            // Make sure evt has an href
                            if (targetElement.href && line.selectable_in_search) {
                                handleNavigation(evt, line);
                            }
                        }}
                    ></div>
                )}
                {app.user.has_perm('cms_lite.edit_tod_line') && !searchModal && (
                    <EditButton
                        title="Edit ToD line"
                        handleEditor={() =>
                            setShowEditor({
                                show: true,
                                content: line,
                            })
                        }
                    />
                )}
            </li>
        );
    };

    const selectSearchDocs = (checked, docs) => {
        if (checked) {
            setSelectedDocs((currSelectedDocs) => {
                const newDocs = docs.filter(
                    (doc) =>
                        !currSelectedDocs.some((selectedDoc) => {
                            return selectedDoc.doc_id === doc.doc_id;
                        }),
                );
                return [...currSelectedDocs, ...newDocs];
            });
        } else {
            setSelectedDocs((currSelectedDocs) => {
                return currSelectedDocs.filter((currDoc) => {
                    return !docs.some((doc) => currDoc.value === doc.value);
                });
            });
        }
    };

    const validateData = (data) => {
        const valid = {};

        if (data[TOD_LINE_REQUIRED_CONTENT.FAMILIES]) {
            if (data[TOD_LINE_REQUIRED_CONTENT.FAMILIES].length > 0) {
                valid[TOD_LINE_REQUIRED_CONTENT.FAMILIES] = true;
            } else {
                valid[TOD_LINE_REQUIRED_CONTENT.FAMILIES] = false;
            }
        }
        if (data[TOD_LINE_REQUIRED_CONTENT.CONTENT]) {
            if (
                data[TOD_LINE_REQUIRED_CONTENT.CONTENT].length > 1 &&
                data[TOD_LINE_REQUIRED_CONTENT.CONTENT] !== '<p></p>'
            ) {
                valid[TOD_LINE_REQUIRED_CONTENT.CONTENT] = true;
            } else {
                valid[TOD_LINE_REQUIRED_CONTENT.CONTENT] = false;
            }
        } else {
            valid[TOD_LINE_REQUIRED_CONTENT.CONTENT] = false;
        }

        return valid;
    };

    const saveContent = async (data) => {
        try {
            const newContent = await doPost(data);

            const flatLines = Object.values(lines).flat();
            flatLines.splice(
                _.findIndex(flatLines, (line) => line.pk === newContent.pk),
                1,
                newContent,
            );
            const regrouped = _.groupBy(flatLines, (line) => line.secondary_column);

            setLines({
                primary: regrouped[false],
                secondary: regrouped[true],
            });

            toaster.success('The Table of Documents was successfully updated');
        } catch (exc) {
            toaster.error('There was a problem updating a line in the Table of Documents');
        }
    };
    const ulClassName = `tod-menu ${loggedIn ? 'no-bullets' : ''}`;
    // TODO: move the styling for Contents into a className in css
    return (
        <WithLoadingOverlay loading={loading} className="tod-loader">
            {searchModal && (
                <SearchToDHeader
                    setSelectionFromTOD={setSelectionFromTOD}
                    todHome={true}
                    prevToDPage={prevToDPage}
                />
            )}
            <div className="tod-container">
                <div className="tod-col-primary">
                    {loggedIn ? (
                        todWindow || searchModal ? (
                            ''
                        ) : (
                            <a href="/tod">
                                <h2>Table of Documents</h2>
                            </a>
                        )
                    ) : (
                        <h2
                            style={{
                                borderTop: '3px solid #75c6c6',
                                borderBottom: '3px solid #75c6c6',
                                paddingTop: '5px',
                                paddingBottom: '5px',
                            }}
                        >
                            Contents
                        </h2>
                    )}
                    {searchModal && (
                        <div className="select-all-checkbox">
                            <Checkbox
                                value="all"
                                checked={allSelected}
                                onChange={(evt) => {
                                    setAllSelected(evt.target.checked);
                                    if (evt.target.checked) {
                                        selectAllFamilies(evt, lines);
                                        setRodDocuments([]);
                                        setAllRODSelected(false);
                                    } else {
                                        setSelectedDocs([]);
                                        setTodLineCollections([]);
                                    }
                                }}
                            />
                            <div className="select-all-label">Select All</div>
                        </div>
                    )}
                    <ul className={`${ulClassName} primary`}>
                        {lines.primary?.map((line, index) => {
                            return ToDLine(line, index);
                        })}
                    </ul>
                </div>
                <div className="tod-col-secondary">
                    {lines.secondary && (todWindow || searchModal) && (
                        <ul className="tod-section secondary">
                            {filterNews(lines.secondary, true).map((line, index) => {
                                return ToDLine(line, index);
                            })}
                            {!searchModal && (
                                <li
                                    className="tod-item"
                                    onClick={(evt) => {
                                        let targetElement = evt.target;
                                        if (searchModal) {
                                            searchModalToDPath(evt, targetElement);
                                        }
                                    }}
                                >
                                    <a
                                        className={'new-amended-link'}
                                        href="/tod/new-and-recently-amended-documents"
                                    >
                                        New and Recently Amended Documents
                                    </a>
                                </li>
                            )}
                        </ul>
                    )}
                    <ul className={`secondary`}>
                        {
                            // Logged-in ToDs aren't exactly the same: Homepage should not include News
                            lines.secondary &&
                                filterNews(lines.secondary).map((line, index) => {
                                    return ToDLine(line, index);
                                })
                        }
                    </ul>
                </div>

                {showEditor.show && (
                    <ContentEditor
                        show={showEditor.show}
                        hideModal={() => setShowEditor({ show: false, content: {} })}
                        content={showEditor.content.line}
                        title="ToD line content editor"
                        extraOptions={showEditor.content}
                        families={families}
                        saveContent={saveContent}
                        helpTextUrl={`/admincms_lite/todline/${showEditor.content.pk}/change/`}
                        validateData={validateData}
                    />
                )}
            </div>
        </WithLoadingOverlay>
    );
};

export default ToDList;

ToDList.propTypes = {
    loggedIn: PropTypes.bool,
    todWindow: PropTypes.bool,
    doFetch: PropTypes.func,
    doPost: PropTypes.func,
    families: PropTypes.array,
    fromCompare: PropTypes.bool,
    searchModal: PropTypes.bool,
    setSelectionFromTOD: PropTypes.func,
    setSelectedDocs: PropTypes.func,
    selectedDocs: PropTypes.array,
    allSelected: PropTypes.bool,
    setAllSelected: PropTypes.func,
    todLineCollections: PropTypes.array,
    setTodLineCollections: PropTypes.func,
    setRodDocuments: PropTypes.func,
    setAllRODSelected: PropTypes.func,
    setPrevToDPage: PropTypes.func,
    prevToDPage: PropTypes.string,
};
