import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';

import EditButton from '../ContentManagement/EditButton';
import { strToCapital } from '../utils';
import ToDIndexFilter from './ToDIndexFilter';
import { editableContentPropType } from './utils';

const ToDPageHeader = ({ editableContent, setShowEditor, page, validatePageData, searchModal }) => {
    // eslint-disable-next-line no-unused-vars
    const { filters, ...nonFilterContent } = editableContent;
    // eslint-disable-next-line no-unused-vars
    const { content_top, content_bottom, ...content } = nonFilterContent;
    const pageTitle = strToCapital(page);
    return (
        <div className="tod-page-header">
            <div className="tod-header-inner">
                <div className="tod-page-title">
                    {editableContent.title ? editableContent.title : pageTitle}
                    {app.user.has_perm('cms_lite.change_tod_page_content') && !searchModal && (
                        <EditButton
                            title={`Edit ${pageTitle} Page`}
                            handleEditor={() =>
                                setShowEditor({
                                    show: true,
                                    content: {
                                        ...content,
                                        content: content.header_content,
                                        contentType: 'header_content',
                                    },
                                    validateData: validatePageData,
                                    helpTextUrl: `/admincms_lite/todpagecontent/${editableContent.id}/change/`,
                                })
                            }
                        />
                    )}
                </div>
                {editableContent.display_filter_options && !searchModal && (
                    <ToDIndexFilter
                        {...{
                            options: editableContent.filters,
                        }}
                    />
                )}
                {!searchModal && (
                    <div>
                        <p className="find-help-text">
                            Use <strong>Ctrl+f</strong> to search on this page
                        </p>
                        <p className="find-help-text">
                            <strong>Ctrl-click</strong> to open a document in a new tab
                        </p>
                    </div>
                )}
            </div>
            {nonFilterContent.header_content && !searchModal && (
                <div
                    className="editable-header-content"
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(nonFilterContent.header_content),
                    }}
                ></div>
            )}
        </div>
    );
};

export default ToDPageHeader;

ToDPageHeader.propTypes = {
    editableContent: PropTypes.shape(editableContentPropType),
    setShowEditor: PropTypes.func.isRequired,
    page: PropTypes.string,
    validatePageData: PropTypes.func.isRequired,
    searchModal: PropTypes.bool,
};
