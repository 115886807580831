import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { allRecentDocsSelected, fetchRecentDocuments } from './utils';

export const SearchROD = ({
    setSelectedDocs,
    selectedDocs,
    todDocuments,
    allRODSelected,
    setAllRODSelected,
    rodDocuments,
    setRodDocuments,
    allSelected,
}) => {
    const [rod, setRod] = useState([]);

    useEffect(() => {
        let isMounted = true;
        (async () => {
            const checklistRod = await fetchRecentDocuments();
            if (isMounted) {
                setRod(checklistRod);
            }
        })();
        return () => {
            isMounted = false;
        };
    }, []);

    const selectRodDocs = (evt, doc) => {
        if (evt.target.checked) {
            setSelectedDocs((currSelectedDocs) => [...currSelectedDocs, doc]);
        } else {
            setSelectedDocs((currSelectedDocs) => {
                return currSelectedDocs.filter((currDoc) => {
                    return currDoc.value !== doc.value;
                });
            });
            setRodDocuments((currRodDocs) => {
                return currRodDocs.filter((currDoc) => {
                    return currDoc.value !== doc.value;
                });
            });
        }
    };

    const handleSelectAll = (evt) => {
        evt.persist();
        setAllRODSelected(evt.target.checked);
        evt.target.checked ? setRodDocuments(rod) : setRodDocuments([]);
        evt.target.checked
            ? setSelectedDocs((currSelectedDocs) => [...currSelectedDocs, ...allRecentDocsSelected])
            : setSelectedDocs((currSelectedDocs) =>
                  currSelectedDocs.filter((doc) => doc.doc_id !== 'all-rod'),
              );
    };

    const findChecked = (doc) => {
        const selectedDocsCheck =
            selectedDocs &&
            selectedDocs.some((selectedDoc) => {
                if (selectedDoc.doc_id === '') {
                    return selectedDoc.value === doc.value;
                } else {
                    return selectedDoc.doc_id === doc.doc_id;
                }
            });
        const todDocsCheck =
            todDocuments && todDocuments.some((todDoc) => todDoc.doc_id === doc.doc_id);
        const rodDocsCheck =
            rodDocuments && rodDocuments.some((rodDoc) => rodDoc.doc_id === doc.doc_id);
        return selectedDocsCheck || todDocsCheck || rodDocsCheck || false;
    };

    return (
        <div>
            <Form.Group className="rod">
                <h2 className="rod-header">RECENT DOCUMENTS</h2>
                {rod.length < 1 && <div className="no-rod">No Recent Documents</div>}
                {rod.length > 0 && !allSelected && (
                    <Form.Check
                        type="checkbox"
                        id="all-rod"
                        label="Select All"
                        className="rod-checkbox all-rod"
                        checked={allRODSelected}
                        onChange={(evt) => {
                            handleSelectAll(evt);
                        }}
                        data-dd-action-name="Select All Recently Opened Documents"
                    />
                )}
                {rod.map((doc) => {
                    return (
                        <Form.Check
                            type="checkbox"
                            id={doc.value}
                            key={doc.doc_id}
                            className="rod-checkbox"
                            data-dd-action-name="Select Recently Opened Document"
                        >
                            <Form.Check.Input
                                type="checkbox"
                                isValid
                                checked={findChecked(doc)}
                                onChange={(evt) => selectRodDocs(evt, doc)}
                            />
                            <Form.Check.Label>{doc.value}</Form.Check.Label>
                            {doc.doc_abbrv.length > 0 && (
                                <Form.Check.Label id="abbrevs">{`[${doc.doc_abbrv}]`}</Form.Check.Label>
                            )}
                        </Form.Check>
                    );
                })}
            </Form.Group>
        </div>
    );
};

SearchROD.propTypes = {
    selectedDocs: PropTypes.array,
    setSelectedDocs: PropTypes.func.isRequired,
    todDocuments: PropTypes.array,
    rodDocuments: PropTypes.array,
    setRodDocuments: PropTypes.func.isRequired,
    allSelected: PropTypes.bool.isRequired,
    setAllRODSelected: PropTypes.func.isRequired,
    allRODSelected: PropTypes.bool.isRequired,
};
