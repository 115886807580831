import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Container } from 'react-bootstrap';
import icons from '../../img/Icons';

export const SearchToolbar = ({
    toolbarSelection,
    setToolbarSelection,
    setSelectionFromTOD,
    setSearchText,
}) => {
    const browseTOD = () => {
        setToolbarSelection({ openDocs: false, browse: !toolbarSelection.browse });
        setSelectionFromTOD('');
    };

    const openDocuments = () => {
        setToolbarSelection({ openDocs: !toolbarSelection.openDocs, browse: false });
        setSelectionFromTOD('');
        setSearchText('');
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Escape' && (toolbarSelection.openDocs || toolbarSelection.browse)) {
            event.preventDefault();
            setToolbarSelection({ openDocs: false, browse: false });
        }
    };

    return (
        <Container className="search-toolbar">
            <ButtonGroup>
                <Button
                    id={`docs${toolbarSelection.openDocs ? '-active' : ''}`}
                    onClick={openDocuments}
                    aria-label="Click to search for Documents"
                    data-dd-action-name="Open Document Search"
                >
                    <span>{icons['browse']()}</span>
                    Open Document
                    <span className="expand-icon">{icons['expandLeft']()}</span>
                </Button>
                <Button
                    id={`browse${toolbarSelection.browse ? '-active' : ''}`}
                    onClick={browseTOD}
                    onKeyDown={handleKeyDown}
                    aria-label="Show Table of Documents"
                    data-dd-action-name="Browse Table of Documents Search"
                >
                    <span>{icons['tod']()}</span>
                    Table of Documents
                    <span className="expand-icon">{icons['expandLeft']()}</span>
                </Button>
            </ButtonGroup>
        </Container>
    );
};

SearchToolbar.propTypes = {
    toolbarSelection: PropTypes.shape({
        openDocs: PropTypes.bool,
        browse: PropTypes.bool,
    }),
    setToolbarSelection: PropTypes.func.isRequired,
    setSelectionFromTOD: PropTypes.func.isRequired,
    setSearchText: PropTypes.func,
};
