import React, { useEffect, useRef, useState } from 'react';
import models from '../../models';
import PropTypes from 'prop-types';
import FormModal, { MODAL_SIZE } from '../FormControls/FormModal';
import { Form } from 'react-bootstrap';
import { SearchTypeahead } from './SearchTypeahead';
import ToDList from '../ToD/ToDList';
import ToDPage from '../ToD/ToDPage';
import { QuickSearchManager } from './QuickSearchManager';
import icons from '../../img/Icons';
import { SearchToolbar } from './SearchToolbar';
import {
    formatAdditionalFilters,
    formatURLDocuments,
    formatSubsets,
    fetchRecentDocuments,
    allRecentDocsSelected,
} from './utils';
import { StepWrapper } from './StepWrapper';
import ChipContainer from './ChipContainer';
import { Dates } from './AdditionalFilters/Dates';
import { Mode } from './AdditionalFilters/Mode';
import { Scope } from './AdditionalFilters/Scope';
import { DocSelectorHeader } from './DocSelectorHeader';
import { States } from './AdditionalFilters/States';

const SearchModal = ({ showModal, handleModal, queryParams }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedDocs, setSelectedDocs] = useState([]);
    const [docSelectQuery, setDocSelectQuery] = useState('');
    const [toolbarSelection, setToolbarSelection] = useState({ openDocs: false, browse: false });
    const [allSelected, setAllSelected] = useState(false);
    const [allRODSelected, setAllRODSelected] = useState(false);
    const [rodDocuments, setRodDocuments] = useState([]);
    const [additionalFilters, setAdditionalFilters] = useState({
        mode: 'exact',
        scope: 'exnotes',
        date: 'all',
        state: 'published',
    });
    const [todLineCollections, setTodLineCollections] = useState([]);
    const [selectionFromTOD, setSelectionFromTOD] = useState('');
    const [prevToDPage, setPrevToDPage] = useState('');
    const [todDocuments, setTodDocuments] = useState([]);
    const [families, setFamilies] = useState([
        {
            children: [],
            description: '',
            parent: null,
            part_name: '',
            path: '',
            slug: '',
            title: '',
        },
    ]);
    const [docSelectError, setDocSelectError] = useState(false);
    const [dateErrors, setDateErrors] = useState({
        range: false,
        period: false,
        futureRange: false,
    });
    const [typeaheadError, setTypeaheadError] = useState(false);
    const searchTypeaheadRef = useRef(null);
    const allCollectionsSelected = [
        {
            doc_id: 'all',
            doc_title: 'All Collections',
            collection: true,
            value: 'all',
            label: 'All Collections',
        },
    ];
    const loggedInView = app.user.isAuthenticated;
    const todModel = new models.TableOfDocuments();
    const familiesModel = new models.Families();

    const getTabType = (doc) => {
        if (doc?.collection) {
            return 'collection';
        } else if (doc?.subset) {
            return 'recent';
        } else {
            return 'current';
        }
    };

    useEffect(() => {
        if (showModal) {
            searchTypeaheadRef.current.focus();
        }
    }, [showModal]);

    useEffect(() => {
        if (selectedDocs) {
            setDocSelectError(false);
            if (selectedDocs.length === 1 && selectedDocs[0].doc_id !== 'all-rod') {
                const doc = selectedDocs[0];
                setAdditionalFilters((currFilters) => ({
                    ...currFilters,
                    ...(!doc?.collection && !doc?.subset && { doc_id: doc.doc_id }),
                    tab: getTabType(doc),
                }));
            } else if (selectedDocs.every((doc) => doc.collection)) {
                setAdditionalFilters((currFilters) => ({
                    ...currFilters,
                    tab: 'collection',
                }));
            } else {
                setAdditionalFilters((currFilters) => {
                    const newFilters = {
                        ...currFilters,
                        tab: 'recent',
                    };
                    delete newFilters.doc_id;
                    return newFilters;
                });
            }
        } else {
            setSelectedDocs([]);
        }
    }, [selectedDocs]);

    useEffect(() => {
        if (rodDocuments.length < 1) {
            setAllRODSelected(false);
            setSelectedDocs((currSelectedDocs) => {
                return currSelectedDocs.filter((doc) => doc.doc_id !== 'all-rod');
            });
        }
    }, [rodDocuments]);

    const doFetch = async () => {
        try {
            return await todModel.retrieve();
        } catch (exc) {
            toaster.error('There was a problem retrieving the Table of Documents');
        }
    };

    useEffect(() => {
        (async () => {
            try {
                const content = await familiesModel.getUserFamilies();
                setFamilies(content);
            } catch (exc) {
                setFamilies([]);
            }
        })();
    }, [setFamilies]);

    const formatRodDocs = async (amendedDocs) => {
        const checklistRod = await fetchRecentDocuments();

        const filteredRecentDocs = amendedDocs.filter((doc) => {
            return !checklistRod.some((checklistDoc) => checklistDoc.doc_id === doc.doc_id);
        });
        const recentDocsToSelect = amendedDocs.filter((doc) => {
            return checklistRod.some((checklistDoc) => checklistDoc.doc_id === doc.doc_id);
        });
        const allInChecklist = checklistRod.every((checklistDoc) =>
            recentDocsToSelect.some((doc) => doc.doc_id === checklistDoc.doc_id),
        );
        if (allInChecklist) {
            setAllRODSelected(true);
        }
        setRodDocuments(recentDocsToSelect);
        setSelectedDocs([...filteredRecentDocs, ...allRecentDocsSelected]);
    };

    const isResultsPage = () => window.location.search.includes('search');
    // checks to see if we should be showing the add current doc button
    const showCurrentDoc = () => app.documents.length > 0;

    useEffect(() => {
        const urlParams = { ...queryParams };
        const formattedItems = { selectedSubsets: [], docsToRemove: [] };

        (async () => {
            if (urlParams) {
                setSearchQuery(urlParams.q);
                if (urlParams.all) {
                    setAllSelected(true);
                }
                if (urlParams.subset && urlParams.subset.length > 0 && !urlParams.doc_id) {
                    const result = await formatSubsets(urlParams);
                    formattedItems.selectedSubsets = [
                        ...formattedItems.selectedSubsets,
                        ...result.formatSubsetDocs.selectedSubsets,
                    ];
                    urlParams.docs = result.urlParams.docs;
                    formattedItems.docsToRemove = formattedItems.docsToRemove.concat(
                        result.formatSubsetDocs.docsToRemove,
                    );
                }
                if (urlParams.fam) {
                    const collectionChildren = [];
                    urlParams.fam.forEach((fam) => {
                        const family = families.filter((family) => family.parent === fam);
                        if (family) {
                            family.forEach((child) => {
                                collectionChildren.push({
                                    value: child.title,
                                    doc_id: child.slug,
                                    doc_abbrv: [],
                                    parent: fam,
                                    label: child.title,
                                    collection: true,
                                });
                            });
                        }
                    });
                    setTodLineCollections((prevTodLineCollections) => [
                        ...prevTodLineCollections,
                        ...collectionChildren,
                    ]);
                }
                const amendedDocs = await formatURLDocuments(
                    urlParams,
                    formattedItems.selectedSubsets,
                );
                if (urlParams.rod) {
                    await formatRodDocs(amendedDocs);
                } else {
                    setSelectedDocs(amendedDocs);
                }

                setTodDocuments(formattedItems.docsToRemove);
                setAdditionalFilters((currFilters) => ({
                    ...currFilters,
                    mode: urlParams.mode || 'exact',
                    scope: urlParams.scope || 'exnotes',
                    date: urlParams.date || 'all',
                    state: urlParams.state || 'published',
                    ...(urlParams.date_from && { date_from: urlParams.date_from }),
                    ...(urlParams.date_to && { date_to: urlParams.date_to }),
                    ...(urlParams.date_last_value && {
                        date_last_value: urlParams.date_last_value,
                    }),
                    ...(urlParams.date_last_unit && {
                        date_last_unit: urlParams.date_last_unit,
                    }),
                }));
            }
        })();
    }, [queryParams, families]);

    const handleSubmit = async () => {
        if (!searchQuery || searchQuery.length < 1) {
            if (additionalFilters['date'] !== 'range' && additionalFilters['date'] !== 'period') {
                setTypeaheadError(true);
                return false;
            }
        }
        if (!selectedDocs.length) {
            setDocSelectError(true);
            return false;
        } else if (
            additionalFilters['date'] === 'range' &&
            !additionalFilters['date_from'] &&
            !additionalFilters['date_to']
        ) {
            setDateErrors((currErrors) => ({
                ...currErrors,
                range: true,
            }));
            return false;
        } else if (
            additionalFilters['date'] === 'period' &&
            (!additionalFilters['date_last_value'] ||
                Number(additionalFilters['date_last_value']) < 1)
        ) {
            setDateErrors((currErrors) => ({
                ...currErrors,
                period: true,
            }));
            return false;
        } else if (
            additionalFilters['date'] === 'range' &&
            (!additionalFilters.date_from ||
                additionalFilters.date_from >= additionalFilters.date_to)
        ) {
            setDateErrors((currErrors) => ({
                ...currErrors,
                range: true,
                futureRange: true,
            }));
            return false;
        } else {
            additionalFilters['search'] =
                additionalFilters['tab'] == 'current' ? 'section' : 'document';
            //if tab is current, change date to all and remove additional search filters - check this
            //proximity = removed search filter option, default is currently always 30
            if (additionalFilters['mode'] == 'all') {
                additionalFilters['proximity'] = '30';
            }
            const formattedFilters = formatAdditionalFilters(additionalFilters);
            const additionalFiltersQuery = new URLSearchParams(formattedFilters);
            if (
                !additionalFilters['doc_id'] ||
                !additionalFilters['fam'] ||
                additionalFilters['doc_id'] === 'all-rod'
            ) {
                selectedDocs
                    .concat(rodDocuments) //add docs when select all recent docs is chosen
                    .concat(todDocuments) // add the individual documents selected from the ToD
                    .map((doc) => {
                        if (doc?.collection) {
                            // Add collections as fams in the url
                            additionalFiltersQuery.append('fam', doc.doc_id);
                        } else if (doc?.subset) {
                            // Add subsets as docs in the url- doc.value & doc.parents used for docSelector label-doc.doc_filter used for filtering out docs in url that are contained in the subset
                            //split by!, ensures splitting url in correct place in searchDetailsBar
                            additionalFiltersQuery.append(
                                'subset',
                                `${doc.value}!${doc.parents?.join('+')}!${doc.doc_filter}!${
                                    doc.page
                                }`,
                            );
                        } else {
                            if (doc.doc_id !== 'all-rod') {
                                additionalFiltersQuery.append('docs', doc.doc_id);
                            }
                        }
                    });
            }

            const normalisedQuery = searchQuery ? searchQuery.replace(/[“”]/g, '"') : '';

            const url = `${app.urls.search}?${additionalFiltersQuery.toString()}&q=${
                normalisedQuery ? encodeURIComponent(normalisedQuery) : ''
            }${allSelected ? '&all=true' : ''}${allRODSelected ? '&rod=true' : ''}`;

            //if the url is too long, it will throw either a bad request or a 413 error, anything over 4100 bytes will cause an error
            const bytes = new TextEncoder().encode(url).length;
            if (bytes > 4000) {
                toaster.error(
                    'Unable to search, please reduce the number of documents selected, or search collections',
                );
                return false;
            } else {
                isResultsPage() ? (window.location.href = url) : window.open(url);
            }
        }
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            // ToD checkboxes don't focus when clicked, so after selection allows enter to submit modal
            const isInputFocused =
                (document.activeElement.tagName === 'INPUT' &&
                    document.activeElement.type !== 'checkbox') ||
                document.activeElement.tagName === 'TEXTAREA' ||
                document.activeElement.tagName === 'BUTTON' ||
                document.activeElement.tagName === 'A';

            if (event.key === 'Enter' && !isInputFocused) {
                event.preventDefault();
                handleSubmit();
            }
            if (event.key === 'Escape' && (toolbarSelection.browse || toolbarSelection.openDocs)) {
                event.preventDefault();
                setToolbarSelection({ openDocs: false, browse: false });
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleSubmit, toolbarSelection.browse]);

    return (
        <FormModal
            show={showModal}
            hideModal={handleModal}
            title="Search"
            submitForm={handleSubmit}
            size={MODAL_SIZE.NONE}
            backdrop="static"
            cancelText="Close"
            saveText="Search"
            className="search-modal"
            draggable={true}
            isMain={true}
            isSearch={true}
            isError={docSelectError || typeaheadError || dateErrors.range || dateErrors.period}
        >
            <Form.Group>
                <StepWrapper title="Step 1: Search Term(s)">
                    <SearchTypeahead
                        ref={searchTypeaheadRef}
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                        setTypeaheadError={setTypeaheadError}
                        typeaheadError={typeaheadError}
                    />
                    <Mode
                        additionalFilters={additionalFilters}
                        setAdditionalFilters={setAdditionalFilters}
                    />
                </StepWrapper>
                <StepWrapper title="Step 2: Document Selection">
                    <div className={`document-selector ${docSelectError ? 'error' : ''}`}>
                        <DocSelectorHeader
                            selectedDocs={selectedDocs}
                            setSelectedDocs={setSelectedDocs}
                            setTodDocuments={setTodDocuments}
                            setTodLineCollections={setTodLineCollections}
                            setAllSelected={setAllSelected}
                            setAllRODSelected={setAllRODSelected}
                            setRodDocuments={setRodDocuments}
                            todDocuments={todDocuments}
                            rodDocuments={rodDocuments}
                            allSelected={allSelected}
                            showCurrentDoc={showCurrentDoc()}
                        />
                        <ChipContainer
                            setAllSelected={setAllSelected}
                            setSelectedDocs={setSelectedDocs}
                            selectedDocs={allSelected ? allCollectionsSelected : selectedDocs}
                            setTodLineCollections={setTodLineCollections}
                            setTodDocuments={setTodDocuments}
                            todDocuments={todDocuments}
                            todLineCollections={todLineCollections}
                            setAllRODSelected={setAllRODSelected}
                            setRodDocuments={setRodDocuments}
                            showCurrentDoc={showCurrentDoc()}
                        />
                        <SearchToolbar
                            toolbarSelection={toolbarSelection}
                            setToolbarSelection={setToolbarSelection}
                            setSelectionFromTOD={setSelectionFromTOD}
                            setSearchText={setDocSelectQuery}
                        />
                        {toolbarSelection.openDocs && (
                            <QuickSearchManager
                                selectedDocs={allSelected ? allCollectionsSelected : selectedDocs}
                                setSelectedDocs={setSelectedDocs}
                                isSearchModal={true}
                                query={docSelectQuery}
                                setSearchText={setDocSelectQuery}
                                setDocSelectError={setDocSelectError}
                                docSelectError={docSelectError}
                                todDocuments={todDocuments}
                                setTodDocuments={setTodDocuments}
                                allSelected={allSelected}
                                setAllRODSelected={setAllRODSelected}
                                rodDocuments={rodDocuments}
                                setRodDocuments={setRodDocuments}
                                menuIsOpen={true}
                                allRODSelected={allRODSelected}
                            />
                        )}
                        {docSelectError && (
                            <div className="selected-docs-error">
                                <span id="above">{icons['above']()}</span>
                                <div className="search-docs">
                                    {`Select documents or collections for your search using 'Open
                                    Document' or 'Table of Documents'`}
                                </div>
                                <span id="above-mirror">{icons['above']()}</span>
                            </div>
                        )}
                        {allSelected && (
                            <div className="all-selected">
                                When &apos;Select All&apos; is chosen, all collections and their
                                documents are included in the search. Individual documents will not
                                be displayed.
                            </div>
                        )}
                    </div>
                    <div>
                        {toolbarSelection.browse && !selectionFromTOD && (
                            <div className="search-tod">
                                <ToDList
                                    doFetch={doFetch}
                                    searchModal={true}
                                    setSelectionFromTOD={setSelectionFromTOD}
                                    families={families}
                                    loggedIn={loggedInView}
                                    setSelectedDocs={setSelectedDocs}
                                    selectedDocs={selectedDocs}
                                    allSelected={allSelected}
                                    setAllSelected={setAllSelected}
                                    todLineCollections={todLineCollections}
                                    setTodLineCollections={setTodLineCollections}
                                    setRodDocuments={setRodDocuments}
                                    setAllRODSelected={setAllRODSelected}
                                    setPrevToDPage={setPrevToDPage}
                                    prevToDPage={prevToDPage}
                                />
                            </div>
                        )}
                        {toolbarSelection.browse && selectionFromTOD && (
                            <div className="search-tod">
                                <ToDPage
                                    page={selectionFromTOD}
                                    todModel={todModel}
                                    families={families}
                                    searchModal={true}
                                    setSelectedDocs={setSelectedDocs}
                                    setTodDocuments={setTodDocuments}
                                    selectedDocs={selectedDocs}
                                    todDocuments={todDocuments}
                                    rodDocuments={rodDocuments}
                                    setRodDocuments={setRodDocuments}
                                    setSelectionFromTOD={setSelectionFromTOD}
                                    selectionFromTOD={selectionFromTOD}
                                    prevToDPage={prevToDPage}
                                />
                            </div>
                        )}
                    </div>
                    <Scope
                        additionalFilters={additionalFilters}
                        setAdditionalFilters={setAdditionalFilters}
                    />
                </StepWrapper>
                <StepWrapper>
                    <Dates
                        additionalFilters={additionalFilters}
                        setAdditionalFilters={setAdditionalFilters}
                        dateErrors={dateErrors}
                        setDateErrors={setDateErrors}
                        queryParams={queryParams}
                    />
                    {app.user.has_perm('content.view_all_document_states') && (
                        <States
                            additionalFilters={additionalFilters}
                            setAdditionalFilters={setAdditionalFilters}
                        />
                    )}
                    {dateErrors.range && (
                        <div className="date-error">{`Please enter a valid date range ${
                            dateErrors.futureRange ? '- Start date must be before End Date' : ''
                        }`}</div>
                    )}
                    {dateErrors.period && (
                        <div className="date-error">Please enter a valid date period</div>
                    )}
                </StepWrapper>
            </Form.Group>
        </FormModal>
    );
};

export default SearchModal;

SearchModal.propTypes = {
    handleModal: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
    queryParams: PropTypes.shape({
        q: PropTypes.string,
        doc_id: PropTypes.string,
        docs: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
        fam: PropTypes.arrayOf(PropTypes.string),
    }),
};
