import React from 'react';

import models from '../../../models';
import SavedSearchList from './SavedSearchList';
import { TableContextWrapper } from '../../FormControls/Table/TableContext';
import { useTableReducer } from '../../customHooks';
import { initialTableState } from '../../FormControls/Table/utils';

const SavedSearchManager = () => {
    const defaultTableState = {
        ...initialTableState,
        sortColumn: '',
    };

    const [tableState, dispatchTableState] = useTableReducer({
        tableName: 'savedSearchManager',
        defaultTableState,
    });

    const savedSearchModel = new models.SavedSearches({});

    return (
        <>
            <h1 className="sr-only">Saved Searches</h1>
            <TableContextWrapper
                {...{
                    tableState,
                    dispatchTableState,
                    options: {
                        selectable: false,
                        sortable: true,
                        pageable: false,
                        orderable: false,
                        expandable: true,
                        idPropertyName: 'pk',
                    },
                }}
            >
                <SavedSearchList {...{ model: savedSearchModel }} />
            </TableContextWrapper>
        </>
    );
};

export default SavedSearchManager;
