import React from 'react';
import PropTypes from 'prop-types';
import icons from '../../img/Icons';

export const AddCurrentDoc = ({
    setSelectedDocs,
    todDocuments,
    rodDocuments,
    selectedDocs,
    allSelected,
    isChipContainer,
}) => {
    const currentDocInstance = app.documents[0].attributes.instances[0];
    const currentDocId = currentDocInstance.document;

    const addCurrentDoc = () => {
        const currentDoc = {
            value: currentDocInstance.title,
            label: currentDocInstance.title,
            doc_title: currentDocInstance.title,
            document: true,
            doc_id: currentDocId,
            doc_abbrv: currentDocInstance.abbrevs,
        };
        setSelectedDocs((currSelectedDocs) => [...currSelectedDocs, currentDoc]);
    };

    const currentDocSelectionCheck = () => {
        if (isChipContainer) {
            return false;
        }
        const currentDocFamilies = currentDocInstance.families.map((family) => family.slug);
        return (
            todDocuments?.some((doc) => doc.doc_id === currentDocId) ||
            rodDocuments?.some((doc) => doc.doc_id === currentDocId) ||
            selectedDocs?.some(
                (doc) => doc.doc_id === currentDocId || currentDocFamilies.includes(doc.doc_id),
            ) ||
            allSelected
        );
    };

    return (
        <button
            type="button"
            className="add-current-doc"
            disabled={currentDocSelectionCheck()}
            onClick={addCurrentDoc}
            title={
                currentDocSelectionCheck()
                    ? 'Document already selected'
                    : 'Add your current document to the selection'
            }
            data-dd-action-name="Add current document"
        >
            {currentDocSelectionCheck() ? 'Current Document Selected' : 'Add Current Document'}
            <span>{currentDocSelectionCheck() ? icons['tick']() : icons['currentDoc']()}</span>
        </button>
    );
};

AddCurrentDoc.propTypes = {
    setSelectedDocs: PropTypes.func,
    todDocuments: PropTypes.array,
    rodDocuments: PropTypes.array,
    selectedDocs: PropTypes.array,
    allSelected: PropTypes.bool,
    isChipContainer: PropTypes.bool,
};
