import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import models from '../../models';
import DocSearchContainer from '../DocumentFinder/DocSearchContainer';
import { SearchDocSelector } from './SearchDocSelector';

export const QuickSearchManager = ({
    isSearchModal,
    query,
    permission,
    triggerComparison,
    handleModal,
    selectedDocs,
    setSelectedDocs,
    setSearchText,
    setDocSelectError,
    docSelectError,
    todDocuments,
    setTodDocuments,
    allSelected,
    setAllRODSelected,
    rodDocuments,
    setRodDocuments,
    menuIsOpen,
    allRODSelected,
}) => {
    const docSearchResults = new models.DocumentSearchResults();

    // Title and Abbreviation searches happen separately because of how Django searches
    // within Solr for each of them respectfully. This means that although they are sorted
    // in Django within their type, they need to be resorted here when made into one list.
    // Also, can't limit the search return until here either...
    const [abbrevSearch, setAbbrevSearch] = useState({
        collectionData: {},
        documents: [],
        query: '',
    });
    const [titleSearch, setTitleSearch] = useState({
        collectionData: {},
        documents: [],
        query: '',
    });
    const [searching, setSearching] = useState(false);

    const [families, setFamilies] = useState([]);

    const [options, setOptions] = useState([]);

    useEffect(() => {
        let isMounted = true;
        (async () => {
            const results = await new models.Families().fetch();
            if (isMounted) {
                setFamilies(results);
            }
        })();
        return () => {
            isMounted = false;
        };
    }, []);

    const fetchSearch = async (setSearch, searchType) => {
        try {
            const result = await docSearchResults.fetch({
                data: {
                    q: query,
                    state: 'draft OR ready OR published',
                    search: searchType,
                    sort_field: 'doc_reference_sort',
                    strict: true,
                },
                processData: true,
            });
            setSearch(result);
        } catch (error) {
            toaster.error(`Could not retrieve ${searchType} Search Results`);
            console.error(error);
        }
    };

    const debounceSearch = useCallback(
        _.debounce(async () => {
            if (query.length < 2) {
                setAbbrevSearch({ collectionData: {}, documents: [], query: '' });
                setTitleSearch({ collectionData: {}, documents: [], query: '' });
                setOptions([]);
                return;
            } else {
                await fetchSearch(setTitleSearch, 'title');
                await fetchSearch(setAbbrevSearch, 'abbreviation');
                if (!isSearchModal) {
                    setSearching(false);
                }
            }
        }, 300),
        [query],
    );

    useEffect(() => {
        // **NOTE**: unless an unpublished file has been marked 'Index for Search' on the Document lifecycle page, it will not appear in the search results

        // Incase they type something that was a hit, but then continue typing and the new query isn't a match, we need to clear out the old matches
        setAbbrevSearch({ collectionData: {}, documents: [], query: '' });
        setTitleSearch({ collectionData: {}, documents: [], query: '' });
        setSearching(true);
        if (isSearchModal) {
            setOptions([]);
            setDocSelectError(false);
        }
        debounceSearch();
        return debounceSearch.cancel;
    }, [query, debounceSearch]);

    if (isSearchModal) {
        return (
            <SearchDocSelector
                {...{
                    query,
                    setSearchText,
                    abbrevSearch,
                    titleSearch,
                    families,
                    selectedDocs,
                    setSelectedDocs,
                    options,
                    setOptions,
                    docSelectError,
                    todDocuments,
                    setTodDocuments,
                    allSelected,
                    setAllRODSelected,
                    rodDocuments,
                    setRodDocuments,
                    menuIsOpen,
                    allRODSelected,
                }}
            />
        );
    } else {
        return (
            <DocSearchContainer
                {...{
                    query,
                    permission,
                    triggerComparison,
                    handleModal,
                    searching,
                    abbrevSearch,
                    titleSearch,
                    families,
                }}
            />
        );
    }
};

QuickSearchManager.propTypes = {
    isSearchModal: PropTypes.bool,
    query: PropTypes.string,
    permission: PropTypes.bool,
    triggerComparison: PropTypes.func,
    handleModal: PropTypes.func,
    selectedDocs: PropTypes.array,
    setSelectedDocs: PropTypes.func,
    setSearchText: PropTypes.func,
    setDocSelectError: PropTypes.func,
    docSelectError: PropTypes.bool,
    todDocuments: PropTypes.array,
    setTodDocuments: PropTypes.func,
    allSelected: PropTypes.bool,
    setAllRODSelected: PropTypes.func,
    rodDocuments: PropTypes.array,
    setRodDocuments: PropTypes.func,
    menuIsOpen: PropTypes.bool,
    allRODSelected: PropTypes.bool,
};
