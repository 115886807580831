import React from 'react';
import { Badge } from 'react-bootstrap';
import PropTypes from 'prop-types';

export const SearchChip = ({
    selection,
    setAllSelected,
    setSelectedDocs,
    setTodLineCollections,
    setTodDocuments,
    todDocuments,
    selectedDocs,
    todLineCollections,
    setAllRODSelected,
    setRodDocuments,
    setDeselectEvent,
}) => {
    const onRemove = () => {
        setDeselectEvent(() => true);
        if (selection.doc_id === 'all') {
            setAllSelected(false);
            setSelectedDocs([]);
            setTodLineCollections([]);
        } else if (selection.doc_id === '') {
            //if removed value is a subset we need to remove the subset from selectedDocs and it's documents from todDocs
            const subdocIds = selection.subDocs.map((subdoc) => subdoc.doc_id);
            setTodDocuments(todDocuments.filter((doc) => !subdocIds.includes(doc.doc_id)));
            setSelectedDocs(
                selectedDocs.filter(
                    (doc) => `${doc.value} ${doc.page}` !== `${selection.value} ${selection.page}`,
                ),
            );
        } else if (selection.collection) {
            setSelectedDocs(selectedDocs.filter((doc) => doc.doc_id !== selection.doc_id));
            setTodLineCollections(
                todLineCollections.filter((doc) => doc.parent !== selection.doc_id),
            );
        } else if (selection.doc_id === 'all-rod') {
            setAllRODSelected(false);
            setRodDocuments([]);
            setSelectedDocs(selectedDocs.filter((doc) => doc.doc_id !== selection.doc_id));
        } else {
            setSelectedDocs(selectedDocs.filter((doc) => doc.doc_id !== selection.doc_id));
            setTodDocuments(todDocuments.filter((doc) => doc.doc_id !== selection.doc_id));
        }
    };

    const parentsLabel = () => {
        const formattedParents = selection.parents?.map((parent) => {
            return parent.charAt(0).toUpperCase() + parent.slice(1);
        });
        return formattedParents.join(' / ');
    };

    const multiValue = () => {
        const title = selection.doc_title ? selection.doc_title : selection.value;
        if (selection.doc_abbrv && selection.doc_abbrv[0] && selection.doc_abbrv[0].length !== 0) {
            const abbrev = ' [' + selection.doc_abbrv[0] + ']';
            return title + abbrev;
        } else {
            return title;
        }
    };

    return (
        <Badge
            pill
            className={`search-chip ${selection.collection ? 'collection' : ''}`}
            title={selection.label}
        >
            {selection.subset ? (
                <span>{`${selection.label} / ${parentsLabel()}`}</span>
            ) : (
                <span>{multiValue()}</span>
            )}
            <span className="remove-chip" onClick={onRemove}>
                &times;
            </span>
        </Badge>
    );
};

SearchChip.propTypes = {
    selection: PropTypes.shape({
        doc_id: PropTypes.string,
        subDocs: PropTypes.arrayOf(
            PropTypes.shape({
                doc_id: PropTypes.string,
            }),
        ),
        collection: PropTypes.bool,
        doc_title: PropTypes.string,
        value: PropTypes.string,
        page: PropTypes.string,
        parents: PropTypes.arrayOf(PropTypes.string),
        doc_abbrv: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        subset: PropTypes.bool,
        label: PropTypes.string,
    }),
    setAllSelected: PropTypes.func,
    setSelectedDocs: PropTypes.func,
    setTodLineCollections: PropTypes.func,
    setTodDocuments: PropTypes.func,
    todDocuments: PropTypes.arrayOf(
        PropTypes.shape({
            doc_id: PropTypes.string,
        }),
    ),
    selectedDocs: PropTypes.arrayOf(
        PropTypes.shape({
            doc_id: PropTypes.string,
            value: PropTypes.string,
            page: PropTypes.string,
        }),
    ),
    todLineCollections: PropTypes.arrayOf(
        PropTypes.shape({
            parent: PropTypes.string,
        }),
    ),
    setAllRODSelected: PropTypes.func,
    setRodDocuments: PropTypes.func,
    setDeselectEvent: PropTypes.func,
};
