import React, { useState, forwardRef, useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import ReactDatePicker from 'react-datepicker';
import PropTypes from 'prop-types';

import 'react-datepicker/dist/react-datepicker.css';

import { enGB } from 'date-fns/locale';
import PiconIcon from './Icons/PiconIcon';
import { CustomDateInput } from '../Search/AdditionalFilters/CustomDateInput';

export const DatePicker = ({
    minDate,
    maxDate,
    required,
    name,
    initialDate,
    setState,
    className,
    dateErrors,
}) => {
    const [date, setDate] = useState(initialDate);
    const changeDate = (date) => {
        if (setState) {
            setState(date);
            setDate(date);
        } else {
            setDate(date);
        }
    };

    useEffect(() => {
        if (initialDate && setState) setDate(initialDate);
    }, [initialDate]);

    const ref = React.createRef();

    const formatDate = (date) => {
        if (!date) return null;
        return app.moment(date, 'DD/MM/YYYY').format();
    };

    const inputRef = useRef(null);

    const handleChangeRaw = (e) => {
        const newRaw = new Date(e.currentTarget.value);
        if (!isNaN(newRaw)) {
            setDate(newRaw);
        }
    };

    const TextField = forwardRef(({ onClick, value, onChange, className }, _ref) => {
        return (
            <>
                <PiconIcon className="blue internal" iconName="calendar" />
                <Form.Control
                    placeholder="DD/MM/YYYY"
                    type="text"
                    ref={_ref}
                    {...{ onChange, onClick, value, name, required }}
                    autoComplete="off"
                    data-formatted={formatDate(value)}
                    className={dateErrors?.range ? className + ' date-range-error' : className}
                />
                <Form.Control.Feedback type="invalid">Please choose a date.</Form.Control.Feedback>
            </>
        );
    });

    TextField.propTypes = {
        onClick: PropTypes.func,
        value: PropTypes.string,
        onChange: PropTypes.func,
        className: PropTypes.string,
    };

    return (
        <ReactDatePicker
            selected={date}
            onChange={changeDate}
            locale={enGB}
            dateFormat="dd/MM/yyyy"
            minDate={minDate}
            maxDate={maxDate}
            onChangeRaw={handleChangeRaw}
            calendarClassName="calendar-picker"
            customInput={
                setState ? (
                    <CustomDateInput
                        inputRef={inputRef}
                        dateErrors={dateErrors}
                        changeDate={changeDate}
                    />
                ) : (
                    <TextField ref={ref} />
                )
            }
            popperPlacement={setState ? 'top-start' : 'bottom-start'}
            className={className}
            showMonthDropdown={setState}
            showYearDropdown={setState}
            dropdownMode={setState ? 'select' : undefined}
        />
    );
};

DatePicker.defaultProps = {
    name: 'date',
    className: '',
};

DatePicker.propTypes = {
    initialDate: PropTypes.instanceOf(Date),
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
    required: PropTypes.bool,
    name: PropTypes.string,
    setState: PropTypes.func,
    className: PropTypes.string,
    dateErrors: PropTypes.object,
};
