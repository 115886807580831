import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { formatResultsFromUrl, handleSearchQueryLabel } from '../utils';

import Col from 'react-bootstrap/esm/Col';
import Form from 'react-bootstrap/Form';
import PiconIcon from '../../FormControls/Icons/PiconIcon';
import { SEARCH_MODE } from '../AdditionalFilters/SearchFilterTypes';
import SearchSelect from '../SearchSelect';
import { FiltersModalPanel } from '../SavedSearch/FiltersModalPanel';

const SearchParamsDisplay = ({ queryParams, disabled, formattedItems, savedSearch }) => {
    const [searchedItems, setSearchedItems] = useState([]);

    useEffect(() => {
        (async () => {
            if (queryParams.search === 'section') {
                delete queryParams.rod;
            }
            await formatResultsFromUrl(queryParams, setSearchedItems, formattedItems);
        })();
    }, [queryParams]);

    return (
        <Form.Group className="search-description">
            <Form.Row className="search-query">
                {savedSearch ? (
                    <Form.Label column sm={3} htmlFor="search-query">
                        Search Term(s):
                    </Form.Label>
                ) : (
                    <Form.Label column sm={3} htmlFor="search-query">
                        Results for
                        <div className="search-mode">{SEARCH_MODE[queryParams.mode]}</div>
                    </Form.Label>
                )}
                <Col>
                    {savedSearch ? (
                        queryParams.pq || savedSearch ? (
                            handleSearchQueryLabel(queryParams, savedSearch, true)
                        ) : (
                            handleSearchQueryLabel(queryParams)
                        )
                    ) : (
                        <>
                            <PiconIcon className="dark-grey" iconName="search" />
                            <Form.Control
                                className="search-query"
                                readOnly
                                as="textarea"
                                value={handleSearchQueryLabel(queryParams)}
                                aria-label="Search query"
                                rows={handleSearchQueryLabel(queryParams).length > 250 ? 2 : 1}
                                id="search-query"
                            />
                        </>
                    )}
                </Col>
            </Form.Row>
            <Form.Row className={`search-items ${savedSearch ? 'saved' : ''}`}>
                <Form.Label column sm={2}>
                    in
                </Form.Label>
                <Col className={`searched-in ${disabled ? 'disabled' : ''}`}>
                    <SearchSelect
                        disabled={disabled}
                        value={searchedItems}
                        options={[]}
                        onChange={() => null}
                    />
                </Col>
            </Form.Row>
            {savedSearch && <FiltersModalPanel {...{ queryParams }} />}
        </Form.Group>
    );
};

export default SearchParamsDisplay;

SearchParamsDisplay.propTypes = {
    queryParams: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    formattedItems: PropTypes.array,
    savedSearch: PropTypes.bool,
};
