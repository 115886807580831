import React, { useEffect, useRef, useState } from 'react';
import { SearchChip } from './SearchChip';
import PropTypes from 'prop-types';
import { AddCurrentDoc } from './AddCurrentDoc';

const ChipContainer = ({
    setAllSelected,
    setSelectedDocs,
    setTodLineCollections,
    setTodDocuments,
    todDocuments,
    selectedDocs,
    todLineCollections,
    setAllRODSelected,
    setRodDocuments,
    showCurrentDoc,
}) => {
    const [deselectEvent, setDeselectEvent] = useState(false);
    const ref = useRef(null); // Ref for chip container
    useEffect(() => {
        //when adding a chip to the selector, scroll to the bottom, don't scroll if a chip is being removed
        if (ref.current && !deselectEvent) {
            ref.current.scrollTop = ref.current.scrollHeight;
        }
        setDeselectEvent(false);
    }, [selectedDocs, ref.current]);

    const NoDocsSelected = () => {
        return (
            <div className="chip-container no-doc">
                {!showCurrentDoc && 'No documents or collections currently selected'}
                {showCurrentDoc && (
                    <AddCurrentDoc
                        setSelectedDocs={setSelectedDocs}
                        selectedDocs={selectedDocs}
                        isChipContainer={true}
                    />
                )}
                {showCurrentDoc && 'OR'}
            </div>
        );
    };

    return (
        <div className={`chip-container ${selectedDocs?.length === 0 ? 'empty' : ''}`} ref={ref}>
            {selectedDocs?.length === 0 && <NoDocsSelected />}
            {selectedDocs?.map((selection) => (
                <SearchChip
                    key={selection.value}
                    selection={selection}
                    setAllSelected={setAllSelected}
                    setSelectedDocs={setSelectedDocs}
                    setTodLineCollections={setTodLineCollections}
                    setTodDocuments={setTodDocuments}
                    todDocuments={todDocuments}
                    selectedDocs={selectedDocs}
                    todLineCollections={todLineCollections}
                    setAllRODSelected={setAllRODSelected}
                    setRodDocuments={setRodDocuments}
                    setDeselectEvent={setDeselectEvent}
                />
            ))}
        </div>
    );
};

export default ChipContainer;

ChipContainer.propTypes = {
    setAllSelected: PropTypes.func,
    setSelectedDocs: PropTypes.func,
    setTodLineCollections: PropTypes.func,
    setTodDocuments: PropTypes.func,
    todDocuments: PropTypes.array,
    selectedDocs: PropTypes.array,
    todLineCollections: PropTypes.array,
    setAllRODSelected: PropTypes.func,
    setRodDocuments: PropTypes.func,
    showCurrentDoc: PropTypes.bool,
};
