import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import AddSavedSearchModal from './AddSavedSearchModal';

const SaveThisSearchButton = ({ resultsCount, queryParams }) => {
    const [showModal, setShowModal] = useState(false);
    const [searchParams, setSearchParams] = useState({});

    useEffect(() => {
        if (app.prevSearch) {
            const urlParams = new URLSearchParams(app.prevSearch);
            const params = _.fromPairs([...urlParams]);
            params.docs = urlParams.getAll('docs');
            params.fam = urlParams.getAll('fam');
            setSearchParams(params);
        } else {
            setSearchParams(queryParams);
        }
    }, []);

    return (
        <>
            <button
                className={`search-btn saved ${queryParams.saved ? 'disabled' : ''}`}
                onClick={() => setShowModal(true)}
                title={
                    queryParams.saved
                        ? 'Search already saved'
                        : 'Save this Search criteria and recall them again at a later date'
                }
                disabled={queryParams.saved ? true : false}
            >
                Save this search
            </button>
            {showModal && (
                <AddSavedSearchModal
                    showModal={showModal}
                    handleModal={() => setShowModal(false)}
                    resultsCount={resultsCount}
                    queryParams={searchParams}
                />
            )}
        </>
    );
};

export default SaveThisSearchButton;

SaveThisSearchButton.propTypes = {
    resultsCount: PropTypes.number.isRequired,
    queryParams: PropTypes.object.isRequired,
};
