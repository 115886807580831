import React, { forwardRef } from 'react';
import icons from '../../../img/Icons';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

const formatDate = (date) => {
    if (!date) return null;
    return app.moment(date, 'DD/MM/YYYY').format();
};

export const CustomDateInput = forwardRef(
    ({ value, onClick, onChange, onFocus, dateErrors, className }, ref) => (
        <>
            <span className="calendar">{icons['dateSelect']()}</span>
            <Form.Control
                placeholder="DD/MM/YYYY"
                type="text"
                autoComplete="off"
                data-formatted={formatDate(value)}
                value={value}
                ref={ref}
                onFocus={onFocus}
                onChange={onChange}
                onClick={onClick}
                className={dateErrors?.range ? className + ' date-range-error' : className}
                name="date-range"
            />
        </>
    ),
);

CustomDateInput.propTypes = {
    onClick: PropTypes.func,
    value: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    dateErrors: PropTypes.object,
    changeDate: PropTypes.func,
};
