import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { TableContext, TABLE_ACTION } from '../../FormControls/Table/TableContext';
import TableControl from '../../FormControls/Table/TableControl';
import {
    allRecentDocsSelected,
    fetchRecentDocuments,
    formatSubsets,
    formatURLDocuments,
    generateLabel,
    handleSearchQueryLabel,
    reRunSearch,
} from '../utils';
import SavedSearchActions from './SavedSearchActions';
import FiltersPanel from './FiltersPanel';
import models from '../../../models';
import { Modal } from 'react-bootstrap';
import { StepWrapper } from '../StepWrapper';
import DraggableModalDialog from '../../FormControls/DraggableModalDialog';

const headers = [
    { title: 'Title', dataField: 'title' },
    { title: 'Search Term(s)' },
    { title: 'Documents searched' },
    { title: 'Last run', dataField: 'last_run_date' },
    { title: 'Actions' },
];

const filter_types = [
    { type: 'mode', label: 'Method', icon: 'searchMode' },
    { type: 'scope', label: 'Scope', icon: 'searchScope' },
    { type: 'date', label: 'Date', icon: 'date' },
    { type: 'state', label: 'State', icon: 'state' },
    { type: 'tt_option', label: 'Time Travel', icon: 'timeTravel' },
];

const SavedSearchList = ({ model }) => {
    const [showModal, setShowModal] = useState(false);
    const [modalItems, setModalItems] = useState({ title: '', searchedItems: [] });
    const { tableState } = useContext(TableContext);
    const savedSearchModel = new models.SavedSearch();
    const { dispatchTableState } = useContext(TableContext);

    const setDirty = () => {
        dispatchTableState({ type: TABLE_ACTION.SET_DIRTY });
    };

    const setLoading = () => {
        dispatchTableState({ type: TABLE_ACTION.LOADING, loading: true });
    };

    const selectedItems = (
        <ul>
            {modalItems.searchedItems.map((item) => (
                <li key={item.label} className="query">
                    {item.label}
                </li>
            ))}
        </ul>
    );

    const doFetch = async () => {
        const data = await model.fetchSavedSearches({ sort_column: tableState.sortColumn });
        // Get the titles of the documents and families searched in
        const formattedData = await Promise.all(
            data.map(async (item) => {
                const urlParams = new URLSearchParams(new URL(item.search_url).search);
                const params = _.fromPairs([...urlParams]);
                //subsets in object due to esLint. Unable to add result unless it is in object or let variable
                const subsets = { selectedSubsets: [] };
                params.docs = urlParams.getAll('docs');
                params.fam = urlParams.getAll('fam');
                if (params?.doc_id) {
                    params.docs.push(params.doc_id);
                }
                if (params.subset) {
                    params.subset = urlParams.getAll('subset');
                    const result = await formatSubsets(params);
                    subsets.selectedSubsets = [
                        ...subsets.selectedSubsets,
                        ...result.formatSubsetDocs.selectedSubsets,
                    ];
                    params.docs = result.urlParams.docs;
                }

                const amendSearchDocs = await formatURLDocuments(params, subsets.selectedSubsets);
                amendSearchDocs.forEach((amendDoc) => {
                    if (amendDoc.subset) {
                        amendDoc.label =
                            amendDoc.value +
                            ` / ${amendDoc.parents
                                .map((parent) => parent.charAt(0).toUpperCase() + parent.slice(1))
                                .join(' / ')}`;
                    }
                });
                if (params.rod) {
                    const checklistRod = await fetchRecentDocuments();

                    const filteredRecentDocs = amendSearchDocs.filter((doc) => {
                        return !checklistRod.some(
                            (checklistDoc) => checklistDoc.doc_id === doc.doc_id,
                        );
                    });
                    item.searchedItems = [...filteredRecentDocs, ...allRecentDocsSelected];
                } else {
                    item.searchedItems = amendSearchDocs;
                }
                return item;
            }),
        );
        return formattedData;
    };

    const getTableColumns = (item) => {
        const urlParams = new URLSearchParams(new URL(item.search_url).search);
        const queryParams = _.fromPairs([...urlParams]);
        const firstLetter = queryParams.pq?.charAt(0);
        const firstLetterNumber = parseInt(firstLetter);
        const searchCount = firstLetterNumber + 1;
        queryParams.pq = urlParams.getAll('pq');
        queryParams.pfq = urlParams.getAll('pfq');
        queryParams.pqf = urlParams.getAll('pqf');
        queryParams.q = queryParams.q ? `"${queryParams.q}"` : 'Date search';

        const searchWithin = (
            <span className="query">
                {queryParams.pq && queryParams.pq.length > 0 ? (
                    <a href="#" onClick={(evt) => showModal(evt, true)}>
                        {searchCount === 1
                            ? ' within ' + searchCount + ' other search...'
                            : ' within ' + searchCount + ' other searches...'}
                    </a>
                ) : (
                    ''
                )}
            </span>
        );

        const searchWithinLabel = queryParams.pq?.length > 0 ? searchWithin : '';

        const allItems = item.searchedItems
            .map((searchedItem) => {
                if (searchedItem.collection) {
                    return { type: 'collection', label: searchedItem.label };
                } else if (searchedItem.document) {
                    return { type: 'document', label: searchedItem.label };
                } else if (searchedItem.subset) {
                    return { type: 'subset', label: searchedItem.label };
                }
                return null;
            })
            .filter((item) => item !== null);

        const showModal = (evt, refine) => {
            evt.preventDefault();
            setShowModal(true);
            if (refine) {
                const refineQueries = handleSearchQueryLabel(
                    queryParams,
                    false,
                    false,
                    true,
                    item.last_run_date,
                ).split('within ');
                const refinedItems = refineQueries.map((query) => ({ label: query }));
                setModalItems({ searchedItems: refinedItems, title: item.title });
            } else {
                setModalItems({ searchedItems: allItems, title: item.title });
            }
        };

        const searchedIn = (
            <>
                {queryParams.all && <span className="query">All Collections</span>}
                {!queryParams.all && generateLabel(allItems, searchWithinLabel, showModal)}
                {searchWithinLabel}
            </>
        );

        const title = (
            <div className="search-title-container">
                <a
                    href="#"
                    title="Click to rerun this search"
                    onClick={(event) => {
                        setLoading();
                        reRunSearch(
                            event,
                            savedSearchModel,
                            item.search_url,
                            item.title,
                            item.pk,
                            setDirty,
                        );
                    }}
                    onAuxClick={(event) => {
                        reRunSearch(
                            event,
                            savedSearchModel,
                            item.search_url,
                            item.title,
                            item.pk,
                            setDirty,
                        );
                    }}
                >
                    {item.title}
                </a>
            </div>
        );

        const query = (
            <div className="query-container" title={`${queryParams.q}`}>
                <span className="query">
                    <span className="query-string">{queryParams.q}</span>
                </span>
            </div>
        );

        const lastRun = (
            <div className="last-run-container">
                <span>{app.moment(item.last_run_date).format(app.settings.longDateFormat)}</span>
                <span className="results-container">
                    <span className="results-count">{item.document_result_count}</span>
                    {item.searchedItems.length === 1 && item.searchedItems[0].document
                        ? 'locations'
                        : 'documents'}
                </span>
            </div>
        );

        const actions = (
            <SavedSearchActions
                {...{
                    queryParams,
                    formattedItems: item.searchedItems,
                    savedTitle: item.title,
                    resultsCount: item.document_result_count,
                    searchedURL: item.search_url,
                    itemId: item.pk,
                }}
            />
        );

        const panel = <FiltersPanel {...{ queryParams, filter_types }} />;

        const summaryItems = <div>{searchedIn}</div>;

        return { cols: [title, query, summaryItems, lastRun, actions], panel };
    };

    const closeModal = () => {
        setShowModal(false);
        setModalItems({ searchedItems: [], title: '' });
    };

    return (
        <div className="saved-search-list">
            <TableControl
                {...{
                    doFetch,
                    getTableColumns,
                    headers,
                    className: 'saved-search-table popup-table',
                    model,
                }}
            />
            <Modal
                show={showModal}
                onHide={closeModal}
                className="saved-search-modal"
                dialogAs={DraggableModalDialog}
                backdrop={false}
            >
                <Modal.Body>
                    <StepWrapper title={modalItems.title}>
                        <span className="saved-search-modal-content">
                            {selectedItems}
                            <button type="button" onClick={closeModal}>
                                Close
                            </button>
                        </span>
                    </StepWrapper>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default SavedSearchList;

SavedSearchList.propTypes = {
    model: PropTypes.shape({
        fetchSavedSearches: PropTypes.func.isRequired,
    }).isRequired,
};
