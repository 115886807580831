define([
    'bootbox',
    '../../models',
    '../helpers',
    '../document/main',
    './base_results',
    '../../templates/search/document_search_results.html',
    '../../templates/search/document_search_results_table.html',
    '../../templates/search/document_search_results_row.html',
], function (
    bootbox,
    models,
    helpers,
    documentViews,
    baseResultsViews,
    documentSearchResultsTpl,
    documentSearchResultsTableTpl,
    documentSearchResultsRowTpl,
) {
    const { pluralise } = require('../../extras/index');

    /*
        Document search results table row
    */
    var DocumentSearchResultsRow = baseResultsViews.BaseSearchResultsRow.extend({
        template: documentSearchResultsRowTpl,
        events: function () {
            return _.extend(baseResultsViews.BaseSearchResultsRow.prototype.events, {
                'click .show-document': 'showDocument',
                'click .show-abstract': 'showAbstract',
            });
        },
        context: function () {
            const modelObject = this.model.toJSON();
            return { ...modelObject, includeHits: this.data.q.length > 0 };
        },
        initialize: function () {
            // getting the search params from the URL
            this.data = _.fromPairs([...new URLSearchParams(window.location.search)]);
        },
        showDocument: function (evt) {
            evt.preventDefault();
            evt.stopPropagation();

            if ($(evt.currentTarget).hasClass('new-tab')) {
                delete this.data['fam'];
                this.data['search'] = 'section';
                this.data['doc_id'] = this.model.get('doc_id');
                this.data['doc_instance'] = this.model.get('doc_instance');
                this.data['tab'] = 'current';
                this.data['view'] = 'document';
                this.data['show_doc'] = 'true';

                if (this.data['doc_id'] === 'reg-timeline') {
                    let docUrl;
                    if (this.model.get('doc_state') === 'published') {
                        docUrl = `/timeline`;
                    } else {
                        docUrl = `/timeline/${this.data['doc_instance']}`;
                    }
                    window.manager.open(docUrl);
                } else {
                    const docUrl = `${app.urls.search}?${$.param(this.data, true)}`;
                    window.manager.open(docUrl);
                }
            } else {
                if (this.model.get('doc_id') === 'reg-timeline') {
                    let docUrl;
                    if (this.model.get('doc_state') === 'published') {
                        docUrl = `/timeline`;
                    } else {
                        docUrl = `/timeline/${this.data['doc_instance']}`;
                    }
                    window.open(docUrl, '_self');
                } else {
                    this.$el.addClass('active').siblings('tr').removeClass('active');
                    this.trigger('result:selected', { showDocument: true });
                }
            }
        },
        showAbstract: function (evt) {
            evt.preventDefault();
            evt.stopPropagation();

            var pane = $(evt.currentTarget).closest('td.title').find('.mini-previewpane');

            $('.mini-previewpane').not(pane).slideUp();

            pane.slideToggle();
        },
    });

    /*
        Document search results table
    */
    var DocumentSearchResultsTable = baseResultsViews.BaseSearchResultsTable.extend({
        template: documentSearchResultsTableTpl,
        itemView: DocumentSearchResultsRow,
        events: {
            'click thead th.sortable': 'sortColumn',
        },
        initialize: function (options) {
            baseResultsViews.BaseSearchResultsTable.prototype.initialize.apply(this, arguments);

            if (app.user.has_perm('content.view_all_document_states')) {
                this.blankRowStr =
                    '<tr class="no-print"><td colspan="7" class="loading">Retrieving result, please wait...</td></tr>';
            } else {
                this.blankRowStr =
                    '<tr class="no-print"><td colspan="5" class="loading">Retrieving result, please wait...</td></tr>';
            }
            this.queryParams = options.queryParams;
            this.numFound = window.documentSearchResultsPreFill.collectionData.numFound;
            this.collectionLoaded = this.collection.length == this.numFound;
            this.listenTo(this.collection, 'sync', (collection) => {
                this.collectionLoaded =
                    collection.length ==
                    window.documentSearchResultsPreFill.collectionData.numFound;
                if (this.collectionLoaded) {
                    this.unsetPopovers();
                    // don't blow away incrementally rendered results
                    //this.render();
                }
            });
            this.listenTo(this, 'render', this.markSortColumn);

            if (!this.collectionLoaded) {
                this.once('render', this.setPopovers, this);
            }
        },
        templateHelpers: function () {
            return {
                includeHits: this.queryParams.q.length > 0,
            };
        },
        setPopovers: function () {
            this.$('th.sort-client').popover({
                placement: 'auto top',
                title: 'Please wait...',
                container: 'body',
                trigger: 'hover',
                delay: { show: 0, hide: 0 },
                content:
                    'Column sort on hits and extracts is only available when all results have been returned',
            });
        },
        unsetPopovers: function () {
            this.$('th.sort-client').popover('hide').popover('destroy');
        },
        markSortColumn: function () {
            var sortClass = this.collection.reverseSort ? 'picon-arrow-down' : 'picon-arrow-up';

            this.$('th.sortable')
                .find('span.picon')
                .removeClass('picon-arrow-up, picon-arrow-down');

            this.$('[data-sort_field="' + this.collection.sortField + '"]')
                .data('reverse_sort', !this.collection.reverseSort)
                .find('span.picon')
                .addClass(sortClass);
        },
        sortColumn: function (evt) {
            var th = $(evt.currentTarget);

            this.queryParams['reverse_sort'] = th.data('reverse_sort');
            this.queryParams['sort_field'] = th.data('sort_field');

            var newUrl = window.location.pathname + '?' + $.param(this.queryParams, true);

            if (this.collectionLoaded) {
                this.collection.reverseSort = this.queryParams['reverse_sort'];
                this.collection.sortField = this.queryParams['sort_field'];
                this.collection.sort();
                this.render();

                app.router.navigate(newUrl, { replace: true });
            } else if (!th.hasClass('sort-client')) {
                document.location.href = newUrl;
            }
        },
    });

    /*
        Document search results
    */
    var DocumentSearchResultsView = baseResultsViews.BaseSearchResultsView.extend({
        el: '#results',
        className: 'pan-top pan-searchresults',
        template: documentSearchResultsTpl,
        templateHelpers: function () {
            return {
                collectionData: this.searchResults.collectionData,
            };
        },
        initialize: function (options) {
            app.trigger('document:ready');
            this.queryParams = options.queryParams;
            this.savedSearch = this.queryParams?.saved || app.savedSearch;
            this.documentRowIdx = options.documentRowIdx;
            this.searchResults = options.searchResults;
            this.numFound = window.documentSearchResultsPreFill.collectionData['numFound'];

            // set window title
            document.title = 'Search - Perspective';
            this.listenTo(app, 'title:saved', (searchTitle) => this.renderSearchTitle(searchTitle));
        },
        render: function () {
            baseResultsViews.BaseSearchResultsView.prototype.render.apply(this, arguments);

            if (this.savedSearch || app.savedSearch) {
                this.renderSearchTitle(this.savedSearch || app.savedSearch);
            }

            this.renderSearchDetails(this.queryParams, this.searchResults.collectionData);

            if (this.numFound > 0) {
                this.renderDocumentResultsTable();
            } else {
                this.handleNoResults();
            }
        },
        renderDocumentResultsTable: function () {
            // notify that there are search results
            app.trigger('search:results:document');
            this.$('.back-to-full-results').hide();
            this.resultsTable = new DocumentSearchResultsTable({
                queryParams: this.queryParams,
                collection: this.searchResults,
            });

            // scroll to document row if we've come from section results
            if (this.rowIndex) {
                // listen in on the rows being created
                this.listenTo(this.resultsTable, 'render', function () {
                    this.resultsTable.$el
                        .find('tbody tr:eq(' + this.rowIndex + ')')
                        .addClass('active')
                        .get(0)
                        .scrollIntoView(true);
                });
            }
            // row clicked, navigate to Section Results
            this.listenTo(this.resultsTable, 'result:selected', function (view, args) {
                this.rowIndex = this.$('.results-table tbody tr').index(view.$el);

                if (this.queryParams.q) {
                    this.trigger('result:selected', view.model, args['showDocument']);
                } else {
                    this.trigger('result:selected', view.model, { showDocument: true });
                }
            });
            this.assign(this.resultsTable, '.search-results');
        },
    });

    /*
        Global body view for document results, drilling down to section results and a highlighted document
    */
    var SearchResultsView = helpers.BaseView.extend({
        el: 'body',
        _rows: window.SOLR_DEFAULT_RESULT_ROWS,
        _start: window.SOLR_DEFAULT_RESULT_ROWS,
        events: { 'click .back-to-full-results': 'renderDocumentResults' },
        retryWait: 30000,
        retryCount: 0,
        retryLimit: 5,
        initialize: function (options) {
            this.searchResults = options.searchResults;

            var urlParams = new URLSearchParams(window.location.search);
            this.queryParams = _.fromPairs([...urlParams]);
            this.queryParams.q = this.queryParams.q.replace(/%2F/g, '/');

            this.queryParams.docs = urlParams.getAll('docs');
            this.queryParams.fam = urlParams.getAll('fam');
            this.queryParams.pq = urlParams.getAll('pq');
            this.queryParams.count = urlParams.getAll('count');
            this.queryParams.pfq = urlParams.getAll('pfq');
            this.queryParams.pqf = urlParams.getAll('pqf');
            this.queryParams.subset = urlParams.getAll('subset');

            this.numFound = this.searchResults.collectionData.numFound;
            this.searchResults.qs.ngroups = false;
            this.renderDocumentResults();
            this.updateDocumentResults();

            this.listenTo(this.searchResults, 'sync', this.updateWaitMessage);
            this.listenTo(app, 'search:skip-section-results', this.renderDocumentResults);
        },
        renderDocumentResults: function () {
            this.closeSectionResults();

            if (!this.resultsView) {
                this.resultsView = new DocumentSearchResultsView({
                    searchResults: this.searchResults,
                    queryParams: this.queryParams,
                    documentRowIdx: this.documentRowIndex,
                });
                this.listenTo(this.resultsView, 'result:selected', this.renderSectionResults);
            } else {
                var newUrl = window.location.pathname + '?' + $.param(this.queryParams, true);
                if (app.savedSearch) {
                    newUrl += '&saved=' + app.savedSearch;
                }
                app.router.navigate(newUrl, { replace: true });
                this.resultsView.resultsTable.close();
            }

            this.assign(this.resultsView, '#results');
            this.updateWaitMessage();
        },
        updateDocumentResults: function () {
            // Recursively get the next set of results until there are none left
            if (this._start < this.numFound) {
                this.searchResults.qs['start'] = this._start;
                if (!_.isEmpty(window.documentSearchResultsPreFill.collectionData)) {
                    this.searchResults.qs['fids'] =
                        window.documentSearchResultsPreFill.collectionData.refine_ids.slice(
                            this._start,
                            Math.min(
                                window.documentSearchResultsPreFill.collectionData.refine_ids
                                    .length + 1, // eslint-disable-line
                                this._start + this._rows,
                            ),
                        );
                    if (window.documentSearchResultsPreFill.collectionData.refine_ids.length > 0) {
                        this.searchResults.qs['flength'] =
                            window.documentSearchResultsPreFill.collectionData.refine_ids.length;
                    }
                }

                this.searchResults
                    .fetch({
                        data: this.searchResults.qs,
                        processData: true,
                        traditional: true,
                        remove: false,
                    })
                    .done(
                        _.bind(function () {
                            // delay from animation frame to smooth out scrolling on large datasets
                            _.delay(
                                _.bind(function () {
                                    this.updateDocumentResults();
                                    // increment
                                    this._start += this._rows;
                                }, this),
                            );

                            // reset retries
                            this.retryCount = 0;
                        }, this),
                    )
                    .fail(
                        _.bind(function (xhr) {
                            if (xhr.status === 503 && this.retryCount < this.retryLimit) {
                                this.retryCount++;
                                if (this.retryCount === this.retryLimit) {
                                    toaster.error(
                                        'Could not retrieve search results. Please try again in a few moments.',
                                    );
                                } else {
                                    toaster.error(
                                        `Could not retrieve search results. Retrying in ${
                                            this.retryWait / 1000
                                        } seconds...`,
                                        { ttl: this.retryWait },
                                    );
                                    _.delay(
                                        _.bind(this.updateDocumentResults, this),
                                        this.retryWait,
                                    );
                                }
                            }
                        }, this),
                    );
            }
        },
        updateWaitMessage: function () {
            if (!app.documentView) {
                if (this._start >= this.numFound) {
                    this.$('.found').html(
                        this.numFound +
                            ' ' +
                            pluralise(this.numFound, 'document', 'documents') +
                            ' found',
                    );
                } else {
                    this.$('.found').html(
                        'Retrieving document ' + this._start + ' of ' + this.numFound,
                    );
                }
            }
        },
        closeSectionResults: function () {
            if (app.documentView) {
                // nuke from orbit
                app.documentView.closeSubViews();
                app.documentView.stopListening();
                app.documentView.searchResults.remove(app.documentView.searchResults.models);
                app.documentView = null;
                app.documents = [];
                delete app.documentView;
                delete app.prevSearch;
            }
        },
        renderSectionResults: async function (docModel, showDocument) {
            // This is for the showDocument button on the Document Results list
            if (this.resultsView) {
                this.resultsView.resultsTable.close();
            }

            const docId = docModel.get('doc_id');

            var sectionParams = _.extend({}, this.queryParams, {
                search: 'section',
                doc_id: docId,
                doc_state: docModel.get('doc_state'),
            });

            app.prevSearch = window.location.search;
            sectionParams = _.omit(sectionParams, ['fam', 'docs', 'sort_field', 'reverse_sort']);

            var doc = new models.Document({
                doc_id: docId,
                activeInstance: docModel.get('doc_instance'),
            });
            var annotations = new models.Annotations({ document: docId });
            const favourites = new models.Favourites({ document: docId });

            await annotations.fetch();
            await favourites.fetchByDoc();

            if (docId.includes('timeline')) {
                // We need to add the documentSearchResultsPreFill to localStorage so that if we navigate back from timeline, we can get back to the full results list
                if (window.documentSearchResultsPreFill?.qs?.search === 'document') {
                    window.localStorage.setItem(
                        'documentSearchResults',
                        JSON.stringify(this.queryParams),
                    );
                }
            }

            doc.fetch()
                .then(
                    _.bind(function () {
                        var docInstance = doc.getInstance();

                        if (docInstance) {
                            app.documentView = new documentViews.DocumentView({
                                model: docInstance,
                                doc: doc,
                                queryParams: sectionParams,
                                annotations: annotations,
                                favourites: favourites,
                                view: showDocument ? 'document' : 'results',
                                source: 'docSearch',
                            });
                            app.documents = [doc];
                        } else {
                            app.controller.error404();
                        }
                    }, this),
                )
                .fail(_.bind(app.controller.errorHandler, app.controller));
        },
    });

    return SearchResultsView;
});
