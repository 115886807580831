import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';

import Select from './Select';
import { getChecked } from '../../Annotations/utils';

const Option = (props) => {
    const currOption = props.options.find((option) => option.value === props.value);
    return (
        <div>
            <components.Option {...props}>
                <span
                    className={`multiselect-option${
                        Object.keys(currOption).includes('nested') ? `-${currOption.nested}` : ''
                    }`}
                >
                    {' '}
                    <input
                        type="checkbox"
                        checked={getChecked(props, currOption)}
                        onChange={() => null}
                    />{' '}
                    <label>{props.label}</label>{' '}
                </span>
            </components.Option>
        </div>
    );
};

const MultiSelect = (props) => {
    return (
        <Select
            components={{ Option }}
            {...props}
            isMulti={true}
            isSearchable={true}
            removeSelected={false}
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            disabled={props.disabled}
            onBlur={props.onBlur ? props.onBlur : () => {}}
        />
    );
};

export default MultiSelect;

MultiSelect.propTypes = {
    onBlur: PropTypes.func,
    selectedDocs: PropTypes.array,
    disabled: PropTypes.bool,
};

Option.propTypes = {
    isSelected: PropTypes.bool,
    label: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    options: PropTypes.array,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selectProps: PropTypes.object,
};
