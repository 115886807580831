import React, { useEffect } from 'react';
import SearchSelect from './SearchSelect';
import PropTypes from 'prop-types';
import { InputGroup } from 'react-bootstrap';
import { formatFamilyTitle } from '../utils';

export const SearchDocSelector = ({
    query,
    setSearchText,
    selectedDocs,
    setSelectedDocs,
    abbrevSearch,
    titleSearch,
    families,
    options,
    setOptions,
    docSelectError,
    todDocuments,
    setTodDocuments,
    allSelected,
    setAllRODSelected,
    rodDocuments,
    setRodDocuments,
    menuIsOpen,
    allRODSelected,
}) => {
    const formatSearchResults = (results) => {
        const searchResults = results.documents.map(({ ...doc }) => {
            // Because the Display title for family can be changed in Django Admin,
            // we need to match the doc_primary_family saved in Solr to the family slug.
            const formattedResult = formatFamilyTitle(doc, families);
            return {
                ...formattedResult,
                label: `${doc.doc_title}
                ${
                    doc.doc_abbrv.length > 0
                        ? `[${doc.doc_abbrv[0]}]
                    `
                        : ''
                }`,
                value: doc.doc_title,
                document: true,
            };
        });
        return searchResults;
    };

    useEffect(() => {
        const formatTitleResults = formatSearchResults(titleSearch);
        const formatAbbrevResults = formatSearchResults(abbrevSearch);
        setOptions(() => {
            return [
                ...formatAbbrevResults,
                ...formatTitleResults.filter(
                    (abbrev) =>
                        !formatAbbrevResults.find((title) => title.doc_id === abbrev.doc_id),
                ),
            ];
        });
    }, [titleSearch, abbrevSearch]);

    const handleDocuments = (list, evt) => {
        const { option } = evt;
        if (evt.action === 'select-option') {
            if (!allSelected) {
                const isDocumentSelected =
                    todDocuments?.some((doc) => doc.doc_id === option.doc_id) ||
                    rodDocuments?.some((doc) => doc.doc_id === option.doc_id);
                if (!isDocumentSelected) {
                    setSelectedDocs([...selectedDocs, option]);
                } else {
                    setTodDocuments(todDocuments.filter((doc) => doc.doc_id !== option.doc_id));
                    setRodDocuments(rodDocuments.filter((doc) => doc.doc_id !== option.doc_id));
                }
            }
        }
        if (evt.action === 'deselect-option') {
            setTodDocuments(todDocuments?.filter((doc) => doc.doc_id !== option.doc_id));
            setSelectedDocs(selectedDocs?.filter((doc) => doc.doc_id !== evt.option.doc_id));
        }
    };

    return (
        <InputGroup className="open-doc-container">
            <SearchSelect
                disabled={false}
                value={selectedDocs}
                options={options}
                onChange={handleDocuments}
                setSearchText={setSearchText}
                searchText={query}
                isSearchModal={true}
                selectedDocs={selectedDocs}
                docSelectError={docSelectError}
                todDocuments={todDocuments}
                rodDocuments={rodDocuments}
                menuIsOpen={menuIsOpen}
                allRODSelected={allRODSelected}
                allSelected={allSelected}
                setRodDocuments={setRodDocuments}
                setAllRODSelected={setAllRODSelected}
                setSelectedDocs={setSelectedDocs}
            />
        </InputGroup>
    );
};

SearchDocSelector.propTypes = {
    query: PropTypes.string,
    setSearchText: PropTypes.func.isRequired,
    selectedDocs: PropTypes.array,
    setSelectedDocs: PropTypes.func.isRequired,
    abbrevSearch: PropTypes.object,
    titleSearch: PropTypes.object,
    families: PropTypes.array,
    options: PropTypes.array,
    setOptions: PropTypes.func.isRequired,
    docSelectError: PropTypes.bool,
    todDocuments: PropTypes.array,
    setTodDocuments: PropTypes.func,
    allSelected: PropTypes.bool,
    todLineCollections: PropTypes.array,
    setAllRODSelected: PropTypes.func,
    rodDocuments: PropTypes.array,
    setRodDocuments: PropTypes.func,
    menuIsOpen: PropTypes.bool,
    allRODSelected: PropTypes.bool,
};
