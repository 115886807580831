import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';
import ModalDialog from 'react-bootstrap/ModalDialog';

import { WithLoadingOverlay } from './WithLoading';
import DraggableModalDialog from './DraggableModalDialog';
import icons from '../../img/Icons';
import { HelpModal } from '../Search/HelpModal';
import { AIButton } from '../AI/AIButton';
import { SavedSearchButton } from '../Search/SavedSearch/SavedSearchButton';

export const MODAL_SIZE = {
    SMALL: 'sm',
    MEDIUM: 'md',
    LARGE: 'lg',
    NONE: '',
};
Object.freeze(MODAL_SIZE);

const FormModal = ({
    show,
    hideModal,
    title,
    submitForm,
    children,
    loading,
    checkInputValidity,
    size,
    backdrop,
    cancelText,
    saveText,
    className,
    extraButtons,
    draggable,
    isMain,
    isSearch,
    isError,
}) => {
    const [validated, setValidated] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [showHelpModal, setShowHelpModal] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        setSubmitLoading(true);
        const form = event.currentTarget;
        const isValid = form.checkValidity(); // checks that required fields are filled

        const validInput = checkInputValidity && (await checkInputValidity(form)); // checks contents of fields

        setValidated(true);

        if (isValid === false || validInput === false) {
            setSubmitLoading(false);
            return;
        }

        const submitted = await submitForm(form);
        setSubmitLoading(false);

        if (submitted === false) return;

        setValidated(false);
        hideModal();
    };

    const handleHelpModal = () => {
        setShowHelpModal(!showHelpModal);
    };

    return (
        <Modal
            className={`rbs4 ${className ?? ''}`}
            show={show}
            onHide={hideModal}
            size={size}
            backdrop={backdrop}
            dialogAs={draggable ? DraggableModalDialog : ModalDialog}
            aria-label="form-modal"
        >
            <WithLoadingOverlay loading={loading || submitLoading} isMain={isMain}>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <h1 className="sr-only">{title}</h1>
                        <Modal.Title>{title}</Modal.Title>
                        {isSearch && (
                            <>
                                {app.user.attributes.ai_access && (
                                    <AIButton text={'Perspective AI'} />
                                )}
                                <SavedSearchButton />
                                <Button
                                    className="help-button"
                                    onClick={handleHelpModal}
                                    aria-label="help button"
                                    title="help"
                                >
                                    {icons['help']()}
                                </Button>
                            </>
                        )}
                    </Modal.Header>
                    <Modal.Body>{children}</Modal.Body>
                    <Modal.Footer>
                        <div className="footer-buttons">
                            {extraButtons}
                            {saveText && (
                                <Button
                                    type="submit"
                                    className={`${isError ? 'search-error' : ''}`}
                                    data-dd-action-name={`${
                                        isSearch ? 'Main Search triggered' : ''
                                    }`}
                                >
                                    {saveText}
                                </Button>
                            )}
                            <Button
                                variant="secondary btn-default"
                                onClick={hideModal}
                                data-dd-action-name={`${isSearch ? 'Main Search cancelled' : ''}`}
                            >
                                {cancelText}
                            </Button>
                        </div>
                    </Modal.Footer>
                </Form>
            </WithLoadingOverlay>
            {showHelpModal && (
                <HelpModal
                    showHelpModal={showHelpModal}
                    setShowHelpModal={setShowHelpModal}
                    data-dd-action-name="Display Help Modal"
                />
            )}
        </Modal>
    );
};

export default FormModal;

FormModal.defaultProps = {
    size: MODAL_SIZE.LARGE,
    cancelText: 'Cancel',
    saveText: 'Save',
    backdrop: true,
    loading: false,
    draggable: true,
};

FormModal.propTypes = {
    show: PropTypes.bool.isRequired,
    hideModal: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    submitForm: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    loading: PropTypes.bool.isRequired,
    size: PropTypes.oneOf([MODAL_SIZE.SMALL, MODAL_SIZE.MEDIUM, MODAL_SIZE.LARGE, MODAL_SIZE.NONE]),
    cancelText: PropTypes.string,
    saveText: PropTypes.string,
    checkInputValidity: PropTypes.func,
    backdrop: PropTypes.oneOf([true, false, 'static']),
    className: PropTypes.string,
    extraButtons: PropTypes.node,
    draggable: PropTypes.bool.isRequired,
    isMain: PropTypes.bool,
    isSearch: PropTypes.bool,
    isError: PropTypes.bool,
};
