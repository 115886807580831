import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ContentEditor from './ContentEditor';
import EditButton from './EditButton';

export const USER_SETTING_CONTENT_TYPES = {
    ALERTS_GUIDANCE: 'alerts_guidance',
    SITE_DATA: 'site_data',
    PERSONAL_DETAILS: 'personal_details',
};

export const HOMEPAGE_CONTENT_TYPES = {
    PRIMARY_TOP_COMMON: 'primary_top_common',
    PRIMARY_TOP: 'primary_top',
    PRIMARY_MIDDLE: 'primary_middle',
    PRIMARY_BOTTOM: 'primary_bottom',
    SECONDARY_TOP: 'secondary_top',
    SECONDARY_BOTTOM: 'secondary_bottom',
};

export const HOMEPAGE_ANON_CONTENT_TYPES = {
    PRIMARY_TOP_COMMON: 'primary_top_common',
    PRIMARY_TOP_ANON: 'primary_top_anon',
    PRIMARY_MIDDLE_ANON: 'primary_middle_anon',
    PRIMARY_BOTTOM_ANON: 'primary_bottom_anon',
    SECONDARY_TOP_ANON: 'secondary_top_anon',
    SECONDARY_BOTTOM_ANON: 'secondary_bottom_anon',
};

const ContentContainer = ({ doFetch, doPost, contentType, canEdit, searchModal }) => {
    const [showEditor, setShowEditor] = useState(false);
    const [guidance, setGuidance] = useState({});

    useEffect(() => {
        (async () => {
            const content = await doFetch();
            if (content) {
                content.content = content.content.replace(/(<\w+><\/\w+>)/g, '');
                if (content.content_type === null) {
                    // If there wasn't already a content block, fill the type and time
                    content.content_type = contentType.toUpperCase();
                    content.publish_start = app.moment();
                }
            }
            setGuidance(content);
        })();
    }, []);

    const saveContent = async (newData) => {
        const data = {};
        data.content_type = contentType.toUpperCase();
        data.contentType = contentType;

        if (Object.keys(newData).length) {
            Object.entries(newData).forEach(([key, val]) => (data[key] = val));
        }

        try {
            const newContent = await doPost(data);
            const updated = newContent.results.content.replace(/(<\w+><\/\w+>)/g, '');
            newContent.results.content = updated;
            setShowEditor(false);
            setGuidance(newContent.results);
            toaster.success('Content updated');
        } catch (exc) {
            console.error(exc);
            toaster.error('There was a problem updating the content');
        }
    };

    return (
        <>
            {guidance?.content ? (
                <div className="editable-content-container">
                    <div
                        className="tint-box editable-content"
                        dangerouslySetInnerHTML={{
                            __html: guidance.content,
                        }}
                    ></div>
                    {canEdit && !searchModal && (
                        <EditButton
                            title={`Edit ${contentType} content block`}
                            handleEditor={() => setShowEditor(true)}
                        />
                    )}
                </div>
            ) : (
                canEdit &&
                !searchModal && (
                    <EditButton
                        title={`Add ${contentType} content block`}
                        handleEditor={() => setShowEditor(true)}
                    />
                )
            )}
            {showEditor && (
                <ContentEditor
                    show={showEditor}
                    hideModal={() => setShowEditor(false)}
                    content={guidance.content}
                    title={`${contentType} Content Editor`}
                    saveContent={saveContent}
                    extraOptions={guidance}
                    helpTextUrl={guidance.helpTextUrl}
                    // TODO: Limit what editor options are passed in for the toolbar
                />
            )}
        </>
    );
};

export default ContentContainer;

ContentContainer.propTypes = {
    doFetch: PropTypes.func.isRequired,
    doPost: PropTypes.func,
    contentType: PropTypes.string,
    canEdit: PropTypes.bool,
    searchModal: PropTypes.bool,
};
