import React from 'react';
import PropTypes from 'prop-types';
import { AddCurrentDoc } from './AddCurrentDoc';

export const DocSelectorHeader = ({
    selectedDocs,
    setSelectedDocs,
    setTodDocuments,
    setTodLineCollections,
    setAllSelected,
    setAllRODSelected,
    setRodDocuments,
    todDocuments,
    rodDocuments,
    allSelected,
    showCurrentDoc,
}) => {
    const clearAll = () => {
        setSelectedDocs([]);
        setTodDocuments([]);
        setTodLineCollections([]);
        setAllSelected(false);
        setAllRODSelected(false);
        setRodDocuments([]);
    };

    return (
        <div className="doc-select-header">
            <span className="current-doc-actions">
                {selectedDocs.length > 0 && showCurrentDoc && (
                    <AddCurrentDoc
                        setSelectedDocs={setSelectedDocs}
                        todDocuments={todDocuments}
                        rodDocuments={rodDocuments}
                        selectedDocs={selectedDocs}
                        allSelected={allSelected}
                    />
                )}
                {selectedDocs.length > 0 && (
                    <button
                        type="button"
                        className="clear-all"
                        onClick={clearAll}
                        data-dd-action-name="Clear all selected Items"
                    >
                        &times; Clear selection
                    </button>
                )}
            </span>
        </div>
    );
};

DocSelectorHeader.propTypes = {
    selectedDocs: PropTypes.array,
    setSelectedDocs: PropTypes.func,
    setTodDocuments: PropTypes.func,
    setTodLineCollections: PropTypes.func,
    setAllSelected: PropTypes.func,
    setAllRODSelected: PropTypes.func,
    setRodDocuments: PropTypes.func,
    todDocuments: PropTypes.array,
    rodDocuments: PropTypes.array,
    allSelected: PropTypes.bool,
    showCurrentDoc: PropTypes.bool,
};
