import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { SEARCH_STATE } from './SearchFilterTypes';
import { handleRadioChange } from '../utils';

export const States = ({ additionalFilters, setAdditionalFilters }) => {
    const [selectedRadio, setSelectedRadio] = useState('published');

    return (
        <div className="filter-group">
            <label>State:</label>
            <Form.Group className="mode-group">
                {Object.entries(SEARCH_STATE).map(([state, value]) => {
                    return (
                        <Form.Check
                            type="radio"
                            key={state}
                            id={`state-${state}`}
                            className={selectedRadio === state ? 'selected-radio' : ''}
                            data-dd-action-name="Select State"
                        >
                            <Form.Check.Input
                                type="radio"
                                isValid
                                name="state-group"
                                value={state}
                                onChange={(evt) => {
                                    handleRadioChange(evt, 'state', setAdditionalFilters);
                                    setSelectedRadio(evt.target.value);
                                }}
                                checked={additionalFilters.state === state}
                            />
                            <Form.Check.Label htmlFor={`state-${state}`}>{value}</Form.Check.Label>
                        </Form.Check>
                    );
                })}
            </Form.Group>
        </div>
    );
};

States.propTypes = {
    additionalFilters: PropTypes.object.isRequired,
    setAdditionalFilters: PropTypes.func.isRequired,
};
