import React from 'react';
import icons from '../../img/Icons';
import PropTypes from 'prop-types';

export const SearchToDHeader = ({
    setSelectionFromTOD,
    prevToDPage,
    selectionFromTOD,
    todHome,
    editableContent,
}) => {
    const handleNavigation = (direction) => {
        setSelectionFromTOD(direction === 'back' ? '' : prevToDPage);
    };

    //not using strToCapital util function, as need to keep any capitalisation in the path
    const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

    const formatPathToTitle = (path) => {
        return path.split('#').map((part, index) => (
            <span key={index} className="tod-nav-icon">
                {icons['dropdown']()} {part.split(/[-_]/).map(capitalize).join(' ')}
            </span>
        ));
    };

    return (
        <div className="tod-header">
            <div className="tod-nav">
                <button
                    className="tod-nav-header"
                    onClick={() => handleNavigation('back')}
                    disabled={!selectionFromTOD}
                    type="button"
                    data-dd-action-name="Back to Table of Documents Home List"
                >
                    <span>{icons['expandLeft']()}</span>
                </button>
                <button
                    className="tod-nav-header"
                    onClick={() => handleNavigation('forward')}
                    disabled={!prevToDPage || !todHome}
                    type="button"
                    data-dd-action-name="Forward on Search Table of Documents Navigation"
                >
                    <span className="tod-forward">{icons['expandLeft']()}</span>
                </button>
                {!todHome ? (
                    <button
                        className="tod-nav-text"
                        onClick={() => handleNavigation('back')}
                        type="button"
                    >
                        Table of Documents&nbsp;
                    </button>
                ) : (
                    <span className="tod-nav-text">Table of Documents&nbsp;</span>
                )}
                {editableContent?.title ? (
                    <span className="tod-nav-icon">
                        {icons['dropdown']()} {editableContent.title}
                    </span>
                ) : (
                    selectionFromTOD && formatPathToTitle(selectionFromTOD)
                )}
            </div>
            {todHome && (
                <div className="tod-helptext">
                    <span className="bold-text">Tip: </span>Click Collection title to choose
                    individual documents
                </div>
            )}
        </div>
    );
};

SearchToDHeader.propTypes = {
    setSelectionFromTOD: PropTypes.func.isRequired,
    selectionFromTOD: PropTypes.string,
    prevToDPage: PropTypes.string,
    todHome: PropTypes.bool,
    editableContent: PropTypes.shape({
        title: PropTypes.string,
    }),
};
