import models from '../../models';
import React from 'react';
import { getShortDate, strToCapital } from '../utils';
import {
    SEARCH_DATE,
    SEARCH_MODE,
    SEARCH_SCOPE,
    SEARCH_STATE,
} from './AdditionalFilters/SearchFilterTypes';

const formatItems = (titles, type) => {
    const titleKeys = Object.keys(titles);
    return titleKeys.map((id) => {
        return {
            value: titles[id],
            label: titles[id],
            doc_title: titles[id],
            [type]: true,
            doc_id: id,
        };
    });
};

const formatParamTitles = async (queryParams) => {
    const selectedItems = [];
    if (queryParams?.fam?.length) {
        const familyModel = new models.Family({
            slug: queryParams.fam[0],
        });
        const titles = await getParamTitles(familyModel, queryParams.fam);
        selectedItems.push(...formatItems(titles, 'collection'));
    }
    if (queryParams?.doc_id) {
        const documentModel = new models.Document({
            doc_id: queryParams.doc_id,
        });
        const titles = await getParamTitles(documentModel, [queryParams.doc_id]);
        selectedItems.push(...formatItems(titles, 'document'));
    } else if (queryParams?.docs?.length) {
        const documentModel = new models.Document({
            doc_id: queryParams.docs[0],
        });
        const titles = await getParamTitles(documentModel, queryParams.docs);
        selectedItems.push(...formatItems(titles, 'document'));
    }

    return selectedItems;
};

const getParamTitles = async (model, ids) => {
    return await model.getTitles(ids);
};

const fetchAbbrevs = async (queryParams) => {
    //must initialise model with a single doc_id
    const docId = queryParams.docs
        ? { doc_id: queryParams.docs[0] }
        : { doc_id: queryParams.doc_id };
    const docModel = new models.Document(docId);
    try {
        //Then can pass in array of doc_ids to get the abbreviations for each
        const docs = Array.isArray(queryParams.docs) ? queryParams.docs : [queryParams.doc_id];
        const abbrevs = await docModel.getAbbrevs(docs);
        return abbrevs;
    } catch (exc) {
        console.error('Error fetching documents', exc);
    }
};

const handleRadioChange = (evt, option, setAdditionalFilters, setDateErrors) => {
    const value = evt.target.value;
    setAdditionalFilters((currFilters) => {
        return { ...currFilters, [option]: value };
    });
    if (setDateErrors) {
        setDateErrors(() => {
            return { range: false, period: false };
        });
    }
};

const formatAdditionalFilters = (additionalFilters) => {
    const formattedFilters = { ...additionalFilters };
    if (formattedFilters['date'] === 'range') {
        delete formattedFilters['date_last_value'];
        delete formattedFilters['date_last_units'];
    } else if (formattedFilters['date'] === 'period') {
        delete formattedFilters['date_from'];
        delete formattedFilters['date_to'];
    } else {
        delete formattedFilters['date_last_value'];
        delete formattedFilters['date_last_units'];
        delete formattedFilters['date_from'];
        delete formattedFilters['date_to'];
    }
    return formattedFilters;
};

const formatRefineQueries = (
    refineQueries,
    refineScope,
    refineDate,
    savedSearch,
    refineSummary,
    withinLabel,
    lastRunDate,
) => {
    if (!Array.isArray(refineQueries) || refineSummary) {
        const searchCount =
            typeof refineQueries === 'string'
                ? parseInt(refineQueries.charAt(0)) + 1
                : refineQueries.length;
        return `within ${
            searchCount === 1 ? searchCount + ' other search' : searchCount + ' other searches'
        }`;
    }

    const SEARCH_SCOPE = {
        sec_text_exact: 'All text excluding notes',
        'sec_text_exact sec_notes': 'All text including notes',
        titlesheadings: 'Titles and headings only',
        sec_heading: 'Titles and headings only',
        sec_title: 'Titles and headings only',
    };
    Object.freeze(SEARCH_SCOPE);

    const queries = {};
    const scope = {};
    //customDates in object due to esLint. Unable to edit date unless it is in object or let variable
    const dateSettings = { customDates: false };

    const splitQueries = (arr, obj) => {
        arr.forEach((item) => {
            const [key, value] = item.split('~');
            obj[parseInt(key)] = value.replace(/"/g, `'`);
        });
    };

    splitQueries(refineQueries, queries);
    splitQueries(refineScope, scope);

    // Replace empty strings with 'Date Search' for display
    Object.keys(queries).forEach((key) => {
        if (queries[key] === "''") {
            queries[key] = 'Date Search';
        }
    });

    const sortedValues = Object.keys(queries).map((key) => {
        const scopeValue = !savedSearch ? ` in ${SEARCH_SCOPE[scope[key]]}` : '';
        return `${queries[key]}${scopeValue}`;
    });

    const dateLabel = { dateRange: '', datePeriod: '' };
    if (Array.isArray(refineDate)) {
        refineDate.forEach((item) => {
            if (item.includes('doc_date')) {
                // convert date string to range or period, lastRunDate needed to calculate period
                const refineDates = parseDateString(item, lastRunDate);
                dateLabel.dateRange =
                    refineDates.date === 'range'
                        ? ` in documents published between ${refineDates.date_from} and ${refineDates.date_to}`
                        : '';
                dateLabel.datePeriod =
                    refineDates.date === 'period'
                        ? ` in documents published in the last ${refineDates.date_last_value} ${
                              refineDates.date_last_units
                          }${refineDates.date_last_value > 1 ? 's' : ''}`
                        : '';
                dateSettings.customDates = true;
            }
        });
    }
    const searchWithinLabel = sortedValues.reverse().join(' within ');
    return withinLabel
        ? `${searchWithinLabel} ${dateLabel.dateRange}${dateLabel.datePeriod}`
        : `within ${searchWithinLabel}${dateSettings.customDates ? ' within custom dates' : ''}`;
};

const parseDateString = (string, lastRunDate) => {
    const result = {};
    const rangeRegex = /\[(.*?) TO (.*?)\]/;
    const isDate = string.match(rangeRegex);

    if (string.includes('TO NOW') && isDate) {
        result.date = 'period';
        const fromDate = new Date(isDate[1]);
        const timeDiff = Math.abs(new Date(lastRunDate) - fromDate);
        const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

        if (days >= 365) {
            result.date_last_value = Math.floor(days / 365);
            result.date_last_units = 'year';
        } else if (days >= 30) {
            result.date_last_value = Math.floor(days / 30);
            result.date_last_units = 'month';
        } else {
            result.date_last_value = days;
            result.date_last_units = 'day';
        }
    } else if (isDate) {
        result.date = 'range';
        result.date_from = getShortDate(new Date(isDate[1]));
        result.date_to = getShortDate(new Date(isDate[2]));
    }

    return result;
};

const handleSearchQueryLabel = (
    queryParams,
    savedSearch,
    refineSummary,
    withinLabel,
    lastRunDate,
) => {
    const queryCheck = queryParams.q && queryParams.q !== "''" ? queryParams.q : 'Date search';
    const searchWithinLabel =
        queryParams.pq && queryParams.pq.length > 0
            ? formatRefineQueries(
                  queryParams.pq,
                  queryParams.pqf,
                  queryParams.pfq,
                  savedSearch,
                  refineSummary,
                  withinLabel,
                  lastRunDate,
              )
            : '';

    const dateLabel =
        queryParams.date === 'range'
            ? ` in documents published between ${getShortDate(
                  queryParams.date_from,
              )} and ${getShortDate(queryParams.date_to)}`
            : queryParams.date === 'period'
            ? ` in documents published in the last ${queryParams.date_last_value} ${
                  queryParams.date_last_units
              }${queryParams.date_last_value > 1 ? 's' : ''}`
            : '';

    if (withinLabel) {
        return searchWithinLabel;
    }

    return savedSearch
        ? `${queryCheck} ${searchWithinLabel}`
        : `${queryCheck}${dateLabel} in ${SEARCH_SCOPE[queryParams.scope]} ${searchWithinLabel}`;
};

const formatSubsets = async (urlParams) => {
    if (urlParams.subset && urlParams.subset.length > 0 && !urlParams.doc_id) {
        const formatSubsetDocs = {
            selectedSubsets: [],
            docsToKeep: [...urlParams.docs],
            docsToRemove: [],
        };

        const todModel = new models.TableOfDocuments();
        //for loop to handle multiple subsets, forEach does not wait for async to complete
        for (let i = 0; i < urlParams.subset.length; i++) {
            const UrlSubset = urlParams.subset[i];
            const splitSubset = UrlSubset.split('!');
            const subset = splitSubset[0];
            const parents = splitSubset[1].replace(/[-_]/g, ' ').split(/[+#]/);
            const filterFam = splitSubset[2];
            const page = splitSubset[splitSubset.length - 1];
            const result = await todModel.retrieveSubset({
                filter_family: filterFam,
            });
            const filteredResults = result.filter((item) => urlParams.docs.includes(item.doc_id));
            const docsInSubset = filteredResults.filter(
                (item) =>
                    item.level1_value === `meta::${subset}` ||
                    item.level2_value === `meta::${subset}` ||
                    item.level3_value === `meta::${subset}`,
            );
            formatSubsetDocs.docsToRemove = formatSubsetDocs.docsToRemove.concat(docsInSubset);
            formatSubsetDocs.selectedSubsets.push({
                value: subset,
                label: subset,
                subset: true,
                parents: parents,
                doc_id: '',
                doc_filter: filterFam,
                page: page,
            });
        }
        formatSubsetDocs.docsToKeep = formatSubsetDocs.docsToKeep.filter(
            (item) => !formatSubsetDocs.docsToRemove.some((doc) => doc.doc_id === item),
        );
        urlParams.docs = formatSubsetDocs.docsToKeep;
        return { formatSubsetDocs, urlParams };
    }
    return urlParams;
};

const formatURLDocuments = async (urlParams, selectedSubsets) => {
    const amendSearchDocs = [];
    const selectedItems = await formatParamTitles(urlParams);
    const abbrevs = await fetchAbbrevs(urlParams);
    selectedItems.forEach((doc) => {
        if (doc.collection) {
            doc.doc_abbrv = [];
        } else {
            doc.doc_abbrv = [abbrevs[doc.doc_id]];
        }
        amendSearchDocs.push(doc);
    });

    if (selectedSubsets?.length > 0) {
        const combinedItems = amendSearchDocs.concat(selectedSubsets);
        return combinedItems;
    }
    return amendSearchDocs;
};

const allRecentDocsSelected = [
    {
        doc_id: 'all-rod',
        doc_title: 'All Recent Documents',
        document: true,
        value: 'all-rod',
        label: 'All Recent Documents',
    },
];

const fetchRecentDocuments = async () => {
    const recentDocs = await app.recentDocuments.fetch();
    const docList = recentDocs.slice(0, 50);
    const checklistRod = docList.map(({ ...doc }) => {
        return {
            ...doc.document,
            value: doc.document.title,
            doc_abbrv: doc.document.instances[0].abbrevs,
            id: doc.document.doc_id,
            label: doc.document.title,
            document: true,
            doc_title: doc.document.title,
        };
    });
    return checklistRod;
};

const formatSearchUrl = (searchedURL, savedTitle, countOnly) => {
    const searchUrlParts = searchedURL.split('#');
    return `${searchUrlParts[0]}&saved=${encodeURI(savedTitle)}${
        countOnly ? '&count_only=true' : ''
    }#${searchUrlParts[1] ? searchUrlParts[1] : ''}`;
};

//rerun saved search
const reRunSearch = async (event, model, searchedURL, savedTitle, itemId, setDirty) => {
    event.preventDefault();
    const url = formatSearchUrl(searchedURL, savedTitle);
    if (window.opener) {
        window.opener.location.href = url;
    } else {
        window.location.href = url;
    }
    try {
        const countUrl = formatSearchUrl(searchedURL, savedTitle, true);
        const response = await fetch(countUrl);
        const resultsCount = await response.json();
        const data = {
            document_result_count: resultsCount.count,
        };
        await model.update(itemId, data);
        setDirty();
    } catch (error) {
        console.error(error);
    }
};

//saved search filters
const getFilterValue = (type, queryParams) => {
    if (type === 'tt_option') {
        if (queryParams[type]) {
            const view = strToCapital(queryParams[type].split('-')[1]);
            const ttDate = app.moment(queryParams.tt_date).format(app.settings.dateFormat);
            return `${view} view (${ttDate})`;
        } else {
            // Don't add the timetravel filter if there was no time traveling in the query
            return null;
        }
    }

    if (type === 'mode') {
        return strToCapital(SEARCH_MODE[queryParams[type]]);
    }

    if (type === 'scope') {
        return strToCapital(SEARCH_SCOPE[queryParams[type]]);
    }

    if (type === 'state') {
        return strToCapital(SEARCH_STATE[queryParams[type]]);
    }

    if (type === 'date') {
        if (queryParams[type] === 'all') {
            return strToCapital(SEARCH_DATE[queryParams[type]]);
        } else {
            const dateType = queryParams[type];
            let dateValue = '';
            if ('date_last_value' in queryParams) {
                dateValue = `In the last ${queryParams.date_last_value} ${
                    queryParams.date_last_units
                }${queryParams.date_last_value === 1 ? '' : 's'}`;
            } else {
                dateValue = `${getShortDate(queryParams.date_from)} to ${getShortDate(
                    queryParams.date_to,
                )}`;
            }
            return `${strToCapital(SEARCH_DATE[dateType])}: ${dateValue}`;
        }
    }
    if (!app.user.has_perm('content.view_all_document_states')) {
        delete queryParams['state'];
    }
    return queryParams[type];
};

const formatRodDocs = async (amendSearchDocs, setSearchedItems) => {
    const checklistRod = await fetchRecentDocuments();

    const filteredRecentDocs = amendSearchDocs.filter((doc) => {
        return !checklistRod.some((checklistDoc) => checklistDoc.doc_id === doc.doc_id);
    });
    const recentDocsToSelect = amendSearchDocs.filter((doc) => {
        return checklistRod.some((checklistDoc) => checklistDoc.doc_id === doc.doc_id);
    });
    if (recentDocsToSelect.length > 0) {
        setSearchedItems((currSearchedItems) => [
            ...currSearchedItems,
            ...filteredRecentDocs,
            ...allRecentDocsSelected,
        ]);
    } else {
        setSearchedItems((currSearchedItems) => [...currSearchedItems, ...amendSearchDocs]);
    }
};

const formatResultsFromUrl = async (queryParams, setSearchedItems, formattedItems) => {
    if (queryParams.all) {
        setSearchedItems([
            {
                doc_id: 'all',
                doc_title: 'All Collections',
                collection: true,
                value: 'all',
                label: 'All Collections',
            },
        ]);
    } else if (formattedItems) {
        // We might have formatted items if we're editing a saved search
        setSearchedItems(formattedItems);
    } else {
        const urlParams = { ...queryParams };
        //subsets in object due to esLint. Unable to add result unless it is in object or let variable
        const formattedSubsets = { selectedSubsets: [] };
        if (urlParams.subset && urlParams.subset.length > 0 && !urlParams.doc_id) {
            const result = await formatSubsets(urlParams);
            formattedSubsets.selectedSubsets = result.formatSubsetDocs.selectedSubsets;
            urlParams.docs = result.urlParams.docs;
        }
        const amendSearchDocs = await formatURLDocuments(
            urlParams,
            formattedSubsets.selectedSubsets,
        );
        if (urlParams.rod) {
            await formatRodDocs(amendSearchDocs, setSearchedItems);
        } else {
            setSearchedItems((currSearchedItems) => [...currSearchedItems, ...amendSearchDocs]);
        }
    }
};

function generateLabel(allItems, searchWithinLabel, onClick) {
    // Prioritize items based on type
    allItems.sort((a, b) => {
        if (a.label.length !== b.label.length) {
            return a.label.length - b.label.length;
        }
        const priority = { document: 1, collection: 2, subset: 3 };
        return priority[a.type] - priority[b.type];
    });

    const totalItems = allItems.length;
    if (totalItems === 0) return '';

    if (totalItems === 1) {
        return allItems[0].label;
    }

    if (totalItems === 2) {
        return `${allItems[0].label} & ${allItems[1].label}`;
    }

    if (totalItems === 3) {
        return `${allItems[0].label}, ${allItems[1].label} & ${allItems[2].label}`;
    }

    //More than 3 items:
    const remainingCounts = { document: 0, collection: 0, subset: 0 };
    //remove first two items, already displayed
    allItems.slice(2).forEach((item) => {
        remainingCounts[item.type]++;
    });

    const remainingLabels = Object.entries(remainingCounts)
        // filter out items with 0 remaining
        .filter((entry) => entry[1] > 0)
        .map(([type, count]) => `${count} more ${type}${count > 1 ? 's' : ''}`);

    // Format remaining labels
    const remainingPart =
        remainingLabels.length > 1
            ? `, ${remainingLabels.slice(0, -1).join(', ')} & ${remainingLabels.slice(-1)}`
            : ` & ${remainingLabels[0]}`;

    return (
        <>
            {`${allItems[0].label}, ${allItems[1].label}`}
            <a href="#" onClick={onClick}>
                {remainingPart}
                {remainingPart && !searchWithinLabel ? '...' : ''}
            </a>
        </>
    );
}

export {
    formatParamTitles,
    handleRadioChange,
    fetchAbbrevs,
    formatAdditionalFilters,
    formatRefineQueries,
    formatSubsets,
    formatURLDocuments,
    fetchRecentDocuments,
    allRecentDocsSelected,
    formatSearchUrl,
    reRunSearch,
    getFilterValue,
    formatRodDocs,
    formatResultsFromUrl,
    generateLabel,
    handleSearchQueryLabel,
};
