import React from 'react';
import PropTypes from 'prop-types';

export const StepWrapper = ({ title, children }) => {
    return (
        <div className="search-step">
            <div className="sidebar"></div>
            <div className="step-content">
                <span className="step-title">{title}</span>
                {children}
            </div>
        </div>
    );
};

StepWrapper.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
};
