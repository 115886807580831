import React, { useEffect, useState } from 'react';

import ModalDialog from 'react-bootstrap/ModalDialog';
import Draggable from 'react-draggable';

const DraggableModalDialog = (props) => {
    const [modalWidth, setModalWidth] = useState(0);

    useEffect(() => {
        const modalContent =
            document.querySelector('.search-modal .modal-body') ??
            document.querySelector('.modal-body');
        if (modalContent) {
            setModalWidth(modalContent.offsetWidth);
        }
    }, []);
    const bounds = {
        top: -15,
        left: -modalWidth + 50,
        right: window.innerWidth / 2 + modalWidth / 4,
        bottom: window.innerHeight - 50,
    };

    return (
        <Draggable handle=".modal-title" bounds={bounds}>
            <ModalDialog {...props} />
        </Draggable>
    );
};

export default DraggableModalDialog;
