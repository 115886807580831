import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const Checkbox = ({
    checked,
    isIndeterminate,
    onChange,
    dirty,
    label,
    helpText,
    disable,
    value,
}) => {
    const checkRef = useRef();

    useEffect(() => {
        checkRef.current.checked = checked;
        checkRef.current.indeterminate = isIndeterminate;
    }, [checked, isIndeterminate, dirty]);

    return (
        <span className="checkbox-wrapper">
            <input
                disabled={disable}
                type="checkbox"
                ref={checkRef}
                onChange={async (e) => {
                    e.stopPropagation();
                    // Because of the Confirm Modal for SelectAll on paginated list
                    // We need to make sure the SelectAll checkbox is unchecked if they cancel
                    const stopped = await onChange(e);
                    if (stopped) {
                        checkRef.current.checked = false;
                    }
                }}
                aria-label={`Select ${value || ''}`}
                id={value}
            />
            {label && <span className="checkbox-label">{label}</span>}
            {helpText && <small className="text-muted form-text">{helpText}</small>}
        </span>
    );
};

export default Checkbox;

Checkbox.defaultProps = {
    isIndeterminate: false,
    dirty: [],
};

Checkbox.propTypes = {
    isIndeterminate: PropTypes.bool,
    dirty: PropTypes.array,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    helpText: PropTypes.string,
    disable: PropTypes.bool,
    value: PropTypes.string,
};
