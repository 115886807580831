import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { formatHtmlId } from '../utils';
import Checkbox from '../FormControls/Checkbox';
import { linkCheckboxDocSelector } from './utils';

const ToDDocList = ({
    items,
    nested,
    title,
    parents,
    searchModal,
    searchLevels,
    handleCheckbox,
    checkedItem,
    setCheckedItem,
    selectedDocs,
    todDocuments,
    page,
    rodDocuments,
}) => {
    const findChecked = (parents, doc) => {
        const parentsCheck = !doc
            ? parents.some((parent) => checkedItem[`${parent} ${page}`])
            : false;

        const selectedDocsCheck =
            doc &&
            selectedDocs &&
            selectedDocs.some((selectedDoc) => {
                if (selectedDoc.doc_id === '') {
                    return selectedDoc.value === doc.value;
                } else {
                    return (
                        selectedDoc.doc_id === doc.doc_id ||
                        doc.families
                            .toLowerCase()
                            .split(',')
                            .some(
                                (family) =>
                                    family.trim() === selectedDoc.doc_title.toLowerCase() &&
                                    selectedDoc.collection,
                            )
                    );
                }
            });

        const todDocsCheck =
            todDocuments &&
            todDocuments.some((todDoc) => {
                return todDoc.doc_id === doc?.doc_id;
            });

        const rodDocsCheck =
            rodDocuments &&
            rodDocuments.some((rodDoc) => {
                return rodDoc.doc_id === doc?.doc_id;
            });
        return parentsCheck || selectedDocsCheck || todDocsCheck || rodDocsCheck || false;
    };

    const flattenValues = (values) => {
        const flatValues = [];
        if (Array.isArray(values)) {
            flatValues.push(...values);
        } else {
            const flatted = Object.values(values).flatMap((value) => flattenValues(value));
            flatValues.push(...flatted);
        }
        return flatValues;
    };

    const handleCheckedItems = (evt, subset, page) => {
        if (evt.target.checked) {
            setCheckedItem({
                ...checkedItem,
                [`${subset} ${page}`]: true,
            });
        } else {
            const updatedCheckedItem = { ...checkedItem };
            delete updatedCheckedItem[`${subset} ${page}`];
            setCheckedItem(updatedCheckedItem);
        }
    };

    const checkDocumentFamilySelection = (doc) => {
        //check if the collection or the sub familiy is already selected,
        // cannot exclude individual documents, so disable checkbox and add tooltip
        return selectedDocs?.some(
            (selectedDoc) =>
                (doc.families.includes(selectedDoc.value) && selectedDoc.collection) ||
                selectedDoc.subDocs?.some((subDoc) => subDoc.doc_id === doc.doc_id),
        );
    };

    useEffect(() => {
        linkCheckboxDocSelector(selectedDocs, checkedItem, setCheckedItem);
    }, [selectedDocs, page]);

    return (
        <>
            {items &&
                Object.entries(items).map(([itemKey, values]) => {
                    const searchLevelsArray = Array.isArray(searchLevels)
                        ? searchLevels
                        : searchLevels[itemKey] || [];
                    const key = itemKey.replace('meta::', '');
                    const itemId = title ? `${title}-${key}` : key;
                    const isSelectable = searchLevelsArray?.some(
                        (level) => Number(level) === nested,
                    );
                    const updatedParents = [...parents, ...(isSelectable ? [key] : [])];
                    if (Array.isArray(values)) {
                        return (
                            <React.Fragment key={`tod-section-${formatHtmlId(itemId)}`}>
                                <div
                                    id={`tod-section-header-${formatHtmlId(itemId)}`}
                                    className={`tod-section-header ${
                                        nested > 0 ? 'todlevel' + nested : ''
                                    } ${searchModal ? 'search' : ''}`}
                                >
                                    {searchModal && isSelectable && (
                                        <Checkbox
                                            checked={findChecked(updatedParents)}
                                            data-dd-action-name="Select individual document from search ToD"
                                            onChange={(evt) => {
                                                handleCheckbox(evt, values, updatedParents);
                                                handleCheckedItems(evt, key, page);
                                            }}
                                            disable={selectedDocs.some(
                                                //check if the collection or the sub familiy is already selected,
                                                // cannot exclude individual documents, so disable checkbox
                                                (selectedDoc) =>
                                                    values.some(
                                                        (value) =>
                                                            value.families.includes(
                                                                selectedDoc.value,
                                                            ) && selectedDoc.collection,
                                                    ),
                                            )}
                                        />
                                    )}
                                    {key}
                                </div>
                                <ul
                                    className={`tod-doc-list ${
                                        nested > 1 ? 'listlevel' + nested : ''
                                    }`}
                                >
                                    {values.map((doc, index) => {
                                        return (
                                            <li
                                                className={`no-bullets ${
                                                    searchModal ? 'search' : ''
                                                }`}
                                                key={`${doc.doc_id}-${doc.doc_filter}/${index}`}
                                                title={
                                                    checkDocumentFamilySelection(doc)
                                                        ? 'This document is part of a selected collection and cannot be excluded individually.'
                                                        : ''
                                                }
                                            >
                                                {searchModal && (
                                                    <Checkbox
                                                        checked={findChecked(updatedParents, doc)}
                                                        onChange={(evt) => {
                                                            handleCheckbox(evt, doc);
                                                        }}
                                                        disable={checkDocumentFamilySelection(doc)}
                                                    />
                                                )}
                                                <span
                                                    ref={doc?.ref}
                                                    dangerouslySetInnerHTML={{
                                                        __html: doc.display?.replace(
                                                            '<a',
                                                            `<a data-doc_id="${doc.doc_id}" data-instance_id="${doc.doc_instance_id}" href="${app.urls.documents}/${doc.doc_id}"`,
                                                        ),
                                                    }}
                                                ></span>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </React.Fragment>
                        );
                    } else {
                        const itemId = title ? `${title}-${key}` : key;
                        return (
                            <React.Fragment key={`nested-tod-section-${itemId}`}>
                                <div
                                    id={`tod-section-header-${formatHtmlId(itemId)}`}
                                    className={`${
                                        nested > 0 ? 'todlevel' + nested : 'tod-list-title'
                                    } ${searchModal ? 'search' : ''}`}
                                >
                                    {searchModal && isSelectable && (
                                        <Checkbox
                                            checked={findChecked(updatedParents)}
                                            onChange={(evt) => {
                                                handleCheckbox(evt, flattenValues(values), [
                                                    ...parents,
                                                    key,
                                                ]);
                                                handleCheckedItems(evt, key, page);
                                            }}
                                        />
                                    )}
                                    {key}
                                </div>
                                <ToDDocList
                                    items={values}
                                    nested={nested + 1}
                                    parents={[...parents, ...(isSelectable ? [key] : [])]} // only add to parents if selectable
                                    title={key}
                                    searchModal={searchModal}
                                    searchLevels={searchLevelsArray}
                                    handleCheckbox={handleCheckbox}
                                    checkedItem={checkedItem}
                                    setCheckedItem={setCheckedItem}
                                    selectedDocs={selectedDocs}
                                    todDocuments={todDocuments}
                                    page={page}
                                    rodDocuments={rodDocuments}
                                />
                            </React.Fragment>
                        );
                    }
                })}
        </>
    );
};

export default ToDDocList;

ToDDocList.defaultProps = {
    nested: 0,
    searchModal: false,
    parents: [],
};

ToDDocList.propTypes = {
    items: PropTypes.object.isRequired,
    nested: PropTypes.number,
    parents: PropTypes.array,
    title: PropTypes.string,
    searchModal: PropTypes.bool,
    searchLevels: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    handleCheckbox: PropTypes.func,
    checkedItem: PropTypes.object,
    setCheckedItem: PropTypes.func,
    selectedDocs: PropTypes.array,
    todDocuments: PropTypes.array,
    page: PropTypes.string,
    rodDocuments: PropTypes.array,
};
