import React from 'react';
import PropTypes from 'prop-types';

import icons from '../../../img/Icons';
import { getFilterValue } from '../utils';

const FiltersPanel = ({ queryParams, filter_types }) => {
    return (
        <>
            <td className="filter-icon">{icons['filter']()}</td>
            {filter_types.map(({ type, label }) => {
                const filterValue = getFilterValue(type, queryParams);
                if (!filterValue) return null;
                if (type === 'state' && !app.user.has_perm('content.view_all_document_states'))
                    return null;
                return (
                    <td key={label} className="filter-item">
                        <div className="filter-item-value">{filterValue}</div>
                    </td>
                );
            })}
        </>
    );
};

export default FiltersPanel;

FiltersPanel.propTypes = {
    queryParams: PropTypes.object.isRequired,
    filter_types: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.string,
            label: PropTypes.string,
        }),
    ),
};
