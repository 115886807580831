import React from 'react';
import PropTypes from 'prop-types';

export const ActionPanelWrapper = ({ title, children, className }) => {
    return (
        <div className={`action-panel-wrapper ${className || ''}`}>
            <div className="search-action-panel">
                {title}
                {children}
            </div>
        </div>
    );
};

ActionPanelWrapper.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
};
